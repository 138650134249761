import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PROOFS } from '../../constants'
import { loginRequest } from '../../store/auth'
import { getIsAuthenticated } from '../../store/auth/selectors'
import { signin, signout } from '../../store/auth/thunks'

export const SignIn = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isAuthenticated = useSelector(getIsAuthenticated)

  if (isAuthenticated) {
    history.push(PROOFS)
  }

  return (
    <Container>
      <Typography variant="h4" component="h2">
        Please login
      </Typography>
      <p>
        {isAuthenticated ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => dispatch(signout())}
          >
            Logout
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              dispatch(loginRequest())
              dispatch(signin())
            }}
          >
            Login
          </Button>
        )}
      </p>
    </Container>
  )
}
