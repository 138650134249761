import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import React, { FunctionComponent, ReactNode } from 'react'
import { IFile, IFilePurpose } from '@kartdavid/corkscrew-types/internal'
import { NICE_DATE_FORMAT, TIME_FORMAT } from '../../constants'
import { formatUtcDate } from '../../utils/date'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: `1px solid ${theme.colors.greyBorder}`,
    },
    large: {
      width: 40,
      height: 40,
    },
    avatar: {
      marginRight: theme.spacing(0),
    },
    createdByName: {
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(0.5),
      },
    },
    fileLink: {
      textDecoration: 'none',
      color: theme.colors.coolBlue,
      marginLeft: theme.spacing(3),
    },
    nameAndDate: {},
    date: {
      color: theme.colors.greyBorder,
    },
    rightSection: {
      marginLeft: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {},
    },
    italic: { fontStyle: 'italic', fontWeight: 700 },
    rightTopSection: {
      marginTop: theme.spacing(1),
    },
    regularFont: {
      fontSize: theme.typography.pxToRem(18),
    },
  })
)

const formatHistoryItemDate = (timestamp?: Date) => {
  // must fit the current language which i dont know at this time so en-US is for example
  if (!timestamp) {
    return
  }
  const date = new Date(timestamp)
  const formatedDate = date.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
  })
  const hours = date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })
  return `${formatedDate} at ${hours}`
}

const renderInternalDate = (timestamp: Date | undefined) => {
  return (
    <Box component="span">
      {formatUtcDate(timestamp, NICE_DATE_FORMAT)}
      {' at '}
      {formatUtcDate(timestamp, TIME_FORMAT)}
    </Box>
  )
}

const renderSubtitle = (subtitle: string) => {
  switch (subtitle) {
    case 'proof_rejected':
      return 'Rejected'
    case 'created':
      return 'Created'
    case 'updated':
      return 'Updated'
    case 'uploaded':
      return 'Proof Created'
    case 'proof_accepted':
      return 'Accepted'
    case 'customer-artwork-added':
      return 'Artwork added'
    case 'request_new_artwork':
      return 'Artwork requested'
    case 'request_user_proof_approval':
      return 'Proof sent to user'
    case 'qa_accepted':
      return 'QA Accepted'
    case 'qa_rejected':
      return 'QA Rejected'
  }

  return subtitle
}

const filePurpose = (purposes: IFilePurpose[]): string => {
  if (purposes.length === 0) {
    return 'File'
  }

  switch (purposes[0]) {
    case IFilePurpose.PurposeCustomerArtwork:
      return 'Artwork'
    case IFilePurpose.PurposeProof:
      return 'Proof file'
  }

  return 'File'
}

interface IMessageItemProps {
  timestamp?: Date
  createdByName: string
  avatarUrl?: string
  subtitle?: string
  children: ReactNode
  usage: 'internal' | 'public'
  files?: IFile[]
}

export const MessageItem: FunctionComponent<IMessageItemProps> = ({
  timestamp,
  createdByName,
  avatarUrl,
  subtitle,
  usage,
  children,
  files,
}: IMessageItemProps) => {
  const classes = useStyles()

  const avatarInitials = createdByName
    .toUpperCase()
    .split(' ')
    .map((s) => s.substr(0, 1))

  const showProofFile = (status: string | undefined) => {
    if (status === 'created') {
      return false
    }
    return files && files.length > 0
  }
  return (
    <Box className={classes.root} mb={3} pb={2}>
      <Grid container direction="row" alignItems="flex-start">
        <Grid item className={classes.avatar}>
          <Avatar
            className={classes.large}
            alt={`${createdByName} avatar`}
            src={avatarUrl}
          >
            {avatarInitials}
          </Avatar>
        </Grid>
        <Grid item className={classes.rightSection}>
          <Grid container spacing={2} direction="column">
            <Grid item className={classes.rightTopSection}>
              <Box display="flex" className={classes.nameAndDate}>
                <Box className={classes.createdByName}>
                  <Typography className={classes.regularFont}>
                    {createdByName}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    className={clsx(classes.date, classes.regularFont)}
                  >
                    {usage === 'public'
                      ? formatHistoryItemDate(timestamp)
                      : renderInternalDate(timestamp)}
                  </Typography>
                </Box>
              </Box>
              <Box mt={1} display="flex">
                {subtitle ? (
                  <Typography
                    className={clsx(classes.italic, classes.regularFont)}
                  >
                    {renderSubtitle(subtitle)}
                  </Typography>
                ) : null}
                <div>
                  {usage === 'public' &&
                    showProofFile(subtitle) &&
                    files &&
                    files.length > 0 && (
                      <Typography className={classes.regularFont}>
                        <Link
                          className={classes.fileLink}
                          href={`${files[0].path}`}
                          target="_blank"
                        >
                          {filePurpose(files[0].purpose)}
                        </Link>
                      </Typography>
                    )}
                </div>
              </Box>
            </Grid>
            <Grid item>{children}</Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
