import React from 'react'
import { useSelector } from 'react-redux'
import { getAuthState } from '../../store/auth/selectors'
import Typography from '@material-ui/core/Typography'

export const Account = () => {
  const { user } = useSelector(getAuthState)

  return (
    <>
      <Typography variant="h4" component="h2">
        Account
      </Typography>
      {user ? (
        <>
          <img src={user.picture} alt="users profile" />
          <h1>{user.name}</h1>
          <p>{user.email}</p>
        </>
      ) : null}
    </>
  )
}
