import React, { FunctionComponent, useMemo, useState } from 'react'

import Chip from '@mui/material/Chip'
import { useEffect } from 'react'
import { apiService } from '../../service/api'
import Box from '@mui/material/Box'
import { useHistory } from 'react-router-dom'
import { makeFilter, printStatuses } from '../../factories/filters-factory'
import { IFilterEditable } from '../../types'

// eslint-disable-next-line
export interface IProps {}

const MaterialFilters: FunctionComponent<IProps> = () => {
  const [materials, setMaterials] = useState<IFilterEditable[]>([])
  const history = useHistory()
  const query = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search]
  )

  useEffect(() => {
    refreshMaterials()

    const interval = setInterval(async () => {
      refreshMaterials()
    }, 30 * 1000)

    return () => clearInterval(interval)
  }, [query.getAll('thingId').join('x')])

  const refreshMaterials = () => {
    return apiService
      .fetchManufacturingFilters({
        ...makeFilter('printing', printStatuses, [], [], false),
        pressIds: query.getAll('thingId'),
      })
      .then(setMaterials)
  }

  return (
    <Box mb={2} mt={1}>
      {materials.map((material) => {
        const materialSku = material.materials[0].name
        const isActive = query.get('materialSku') === materialSku

        return (
          <Chip
            key={materialSku}
            label={material.name}
            sx={{ marginRight: 1 }}
            onDelete={
              isActive
                ? () => {
                    query.delete('materialSku')
                    history.push({ search: query.toString() })
                  }
                : undefined
            }
            onClick={() => {
              if (isActive) {
                query.delete('materialSku')
              } else {
                query.set('materialSku', materialSku)
              }

              history.push({ search: query.toString() })
            }}
            color={isActive ? 'primary' : 'default'}
          />
        )
      })}
    </Box>
  )
}

export default MaterialFilters
