import { IOrderLineItem } from '@kartdavid/corkscrew-types/internal'
import { useEffect, useState } from 'react'
import { apiService } from '../service/api'

export interface BatchSummary {
  estPrintTime: number
  estCutTime: number
  estPrintLengthMM: number
}

const useBatchEstimate = (orderLines: IOrderLineItem[]) => {
  const [stats, setStats] = useState<BatchSummary>({
    estPrintTime: 0,
    estCutTime: 0,
    estPrintLengthMM: 0,
  })

  useEffect(() => {
    Promise.all(
      orderLines.map((ol) => {
        console.log('unreduced', ol.id, ol.designCode, ol.batchId)

        const fromOrder = {
          orderId: ol.id,
          estPrintTime: +ol.estPrintTime,
          estCutTime: 0,
          estPrintLengthMM: +ol.estPrintLengthMM,
        }

        return apiService
          .getBatchEstimate(ol.id)
          .then((batch) => {
            console.log('unreduced', ol.id, batch.stats)
            if (!batch) {
              return fromOrder
            }

            return {
              orderId: ol.id,
              estPrintTime:
                batch.stats?.pressTimeSeconds || fromOrder.estPrintTime,
              estCutTime: batch.stats?.cutTimeSeconds || fromOrder.estCutTime,
              estPrintLengthMM:
                batch.stats?.totalRollLengthMM || ol.estPrintLengthMM,
            }
          })
          .catch(() => {
            return fromOrder
          })
      })
    ).then((unreduced) => {
      setStats(
        unreduced.reduce(
          (acc, cur) => {
            return {
              estPrintTime: acc.estPrintTime + +cur.estPrintTime,
              estCutTime: acc.estCutTime + +cur.estCutTime,
              estPrintLengthMM: acc.estPrintLengthMM + +cur.estPrintLengthMM,
            }
          },
          { estPrintTime: 0, estCutTime: 0, estPrintLengthMM: 0 }
        )
      )
    })
  }, [orderLines])

  return stats
}

export default useBatchEstimate
