import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import PrevButton from '@mui/icons-material/NavigateBefore'
import NextButton from '@mui/icons-material/NavigateNext'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

export interface PaginationProps {
  page: number
  hasMore: boolean
  rowsPerPage: number
  rowsPerPageOptions: Array<number>
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage: number
  ) => void
  onChangeRowsPerPage: (perPage: number) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacer: {
      flex: '1 1 100%',
    },
    caption: {
      flexShrink: 0,
    },
    formControl: { marginLeft: theme.spacing(2), minWidth: 50 },
  })
)

const TablePagination = (props: PaginationProps) => {
  const { page, hasMore } = props
  const classes = useStyles()

  return (
    <Toolbar>
      <div className={classes.spacer}></div>
      <Typography className={classes.caption} component="p" variant="body2">
        Rows per page
      </Typography>
      <FormControl className={classes.formControl}>
        <Select
          labelId="perPage"
          id="perPagePagination"
          value={props.rowsPerPage}
          onChange={(ev) =>
            props.onChangeRowsPerPage(+(ev.target.value as string))
          }
        >
          {props.rowsPerPageOptions.map((opt) => {
            return (
              <MenuItem key={opt} value={opt}>
                {opt}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <IconButton
        disabled={page === 0}
        onClick={(ev) => props.onChangePage(ev, page - 1)}
      >
        <PrevButton />
      </IconButton>
      <IconButton
        disabled={!hasMore}
        onClick={(ev) => props.onChangePage(ev, page + 1)}
      >
        <NextButton />
      </IconButton>
    </Toolbar>
  )
}

export default TablePagination
