import Bugsnag from '@bugsnag/js'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import TextField from '@material-ui/core/TextField'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import React, { Fragment, useEffect, useState, Suspense } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  IInternalMessage,
  IOrderHistoryItem,
  IOrderLineItem,
  IProofCreateRequest,
  IRequestNewArtworkRequest,
  IUploadCustomerArtworkRequest,
} from '@kartdavid/corkscrew-types/internal'
import MessageItem from '@kartdavid/atomic-ui/dist/components/message-item'
import { OrderLineItem } from '../../components/order-line-item'
import { EButtonAction } from '../../components/order-line-item/OrderLineItem'
import { apiService } from '../../service/api'
import { useAppDispatch } from '../../store'
import { getAppState } from '../../store/app/selectors'
import {
  fetchOrderThunk,
  fetchStatuses,
  deleteOrderLineItem,
} from '../../store/app/thunk'
import { setFiltersDrawerClose } from '../../store/ui'
import { getUiState } from '../../store/ui/selectors'
import {
  hasPermission,
  getNicenameFromStatus,
  hasPermissionRole,
  hasExplicitPermission,
} from '../../utils/orders'
import { getAuthState } from '../../store/auth/selectors'
import { EPermissions } from '../../store/auth'
import { RequestNewArtworkModal } from './RequestNewArtworkModal'
const CustomerArtworkModalContent = React.lazy(
  () => import('./CustomerArtworkModalContent')
)
const RejectDesignModalContent = React.lazy(
  () => import('./RejectDesignModalContent')
)
const ProofModalContent = React.lazy(() => import('./ProofModalContent'))
const SplitModalContent = React.lazy(() => import('./SplitModalContent'))
const SimpleModalContent = React.lazy(() => import('./SimpleModalContent'))
const SendProofsModalContent = React.lazy(
  () => import('./SendProofsModalContent')
)
const SendProofsButton = React.lazy(() => import('./SendProofsButton'))
import { useInView } from 'react-intersection-observer'
import CircularProgress from '@material-ui/core/CircularProgress'
import { unwrapResult } from '@reduxjs/toolkit'
import Header from './Header'
import Paper from '@mui/material/Paper'
import AddIcon from '@mui/icons-material/Add'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import { Alert } from '@mui/material'
const OrderHistoryItem = React.lazy(() => import('./OrderHistoryItem'))

const proofItemStyles = makeStyles(() =>
  createStyles({
    // The main flex container for the app's layout. Its min-height
    // is set to `100vh` so it always fill the height of the screen.
    grid: {
      display: 'grid',
    },
    twoCols: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    },
    navLink: {
      display: 'flex',
      alignItems: 'center',
    },
    boldText: {
      fontWeight: 800,
    },
    newMessage: {
      width: '100%',
    },
    minWidth: {
      minWidth: 180,
      display: 'inline-block',
    },
  })
)

type MaxWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false

interface IParams {
  orderNumber: string
}

export const ProofsItem = () => {
  const classes = proofItemStyles()
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { orderNumber } = useParams<IParams>()
  // State
  const [isLoading, setIsLoading] = useState(true)
  const [collapseOpen, setCollapseOpen] = useState(true)
  const [open, setOpen] = useState(false)
  const [lineItem, setLineItem] = useState<IOrderLineItem>()
  // Selectors
  const { user } = useSelector(getAuthState)
  const { order } = useSelector(getAppState)
  const { isFiltersDrawerOpen } = useSelector(getUiState)
  const [internalMessages, setInternalMessages] = useState<IInternalMessage[]>(
    []
  )
  const [internalMessage, setInternalMessage] = useState('')
  const [modalAction, setModalAction] = useState<EButtonAction | null>(null)
  const [modalWidth, setModalWidth] = useState<MaxWidth>('lg')
  const [orderHistory, setOrderHistory] = useState<IOrderHistoryItem[]>([])

  const [historyOnScreenRef, historyOnScreen] = useInView({ triggerOnce: true })

  const hasOrderManagePermission = hasPermissionRole(
    user?.permissions || [],
    EPermissions.OrderManage
  )
  const allAccepted =
    order &&
    order.lineItems.every((lineItem) =>
      ['StatusProofUserReviewAccepted'].includes(lineItem.status)
    )
  const allApprovedOrComplete =
    order &&
    order.lineItems.every((lineItem) =>
      ['StatusProofDesignComplete', 'StatusProofUserReviewAccepted'].includes(
        lineItem.status
      )
    )
  const canSendProofs =
    order &&
    (['StatusProofDesignComplete'].includes(order.statusName) ||
      allApprovedOrComplete) &&
    !allAccepted

  useEffect(() => {
    if (isFiltersDrawerOpen) {
      dispatch(setFiltersDrawerClose())
    }
  }, [])

  useEffect(() => {
    setIsLoading(true)
    dispatch(fetchOrderThunk({ orderNumber }))
      .then(unwrapResult)
      .then(() => {
        setIsLoading(false)
      })
    dispatch(fetchStatuses())
    getInternalMessages(orderNumber)
  }, [orderNumber])

  useEffect(() => {
    if (historyOnScreen) {
      getOrderHistory(orderNumber)
    }
  }, [orderNumber, historyOnScreen])

  useEffect(() => {
    if (!hasOrderManagePermission) {
      return
    }
  }, [orderNumber, hasOrderManagePermission])

  const getOrderHistory = async (orderNumber: string) => {
    try {
      const resp = await apiService.getOrderHistory(orderNumber)

      if (resp) {
        setOrderHistory(resp.sort((a, b) => b.id.localeCompare(a.id)))
      }
    } catch (error) {
      Bugsnag.notify(error)
      enqueueSnackbar(`Could not get internal messages :(`, {
        variant: 'error',
      })
    }
  }

  const getInternalMessages = async (orderNumber: string) => {
    try {
      const resp = await apiService.getInternalMessages(orderNumber)

      if (resp.data) {
        setInternalMessages(resp.data)
      }
    } catch (error) {
      Bugsnag.notify(error)
      enqueueSnackbar(`Could not get internal messages :(`, {
        variant: 'error',
      })
    }
  }

  const handleOnCreateInternalMessage = async () => {
    try {
      const resp = await apiService.createInternalMessage(
        orderNumber,
        internalMessage
      )
      setInternalMessages((prevMessages) => {
        if (resp.data) {
          return [resp.data].concat(prevMessages)
        }

        return prevMessages
      })
      setInternalMessage('')
    } catch (error) {
      Bugsnag.notify(error)
      enqueueSnackbar(`Could not create an internal message`, {
        variant: 'error',
      })
    }
  }

  const handleClick = () => {
    setCollapseOpen(!collapseOpen)
  }

  const handleClickOpen = async (
    lineItem: IOrderLineItem,
    action: EButtonAction
  ) => {
    switch (action) {
      case EButtonAction.StartManufacturing:
        try {
          await apiService.setOrderLineStatus(lineItem.id, 'StatusPrintPending')
          dispatch(fetchOrderThunk({ orderNumber }))

          enqueueSnackbar(
            'Design will enter Manufacturing once all lines are complete',
            {
              variant: 'info',
            }
          )
        } catch (err) {
          enqueueSnackbar('Could not change status. Tell DRT/MRT/BW', {
            variant: 'error',
          })
        }
        return
    }

    if (action === EButtonAction.deleteOrderLineItem) {
      setModalWidth('sm')
    } else {
      setModalWidth('lg')
    }

    setOpen(true)
    setLineItem(lineItem)
    setModalAction(action)
  }

  const handleClose = () => {
    setOpen(false)
    setLineItem(undefined)
  }

  const handleProofSubmit = async (proofReq: IProofCreateRequest) => {
    await apiService.saveProof(proofReq)
    dispatch(fetchOrderThunk({ orderNumber }))

    handleClose()
  }

  const handleSplitSubmit = () => {
    dispatch(fetchOrderThunk({ orderNumber }))

    handleClose()
  }

  const handledeleteOrderLineItem = (orderLineId: string) => {
    dispatch(deleteOrderLineItem({ orderLineId }))
      .then(unwrapResult)
      .then(() => {
        dispatch(fetchOrderThunk({ orderNumber }))
        enqueueSnackbar('Deleted', { variant: 'success' })
      })
      .then(() => {
        handleClose()
      })
      .catch(() => {
        enqueueSnackbar('Error deleting this line item', { variant: 'error' })
      })
  }

  const handleUploadArtworkSubmit = async (
    artworkPayload: IUploadCustomerArtworkRequest
  ) => {
    await apiService.uploadCustomerArtwork(artworkPayload)
    dispatch(fetchOrderThunk({ orderNumber }))

    handleClose()
  }

  const handleRequestNewArtwork = async (
    payload: IRequestNewArtworkRequest
  ) => {
    try {
      await apiService.requestNewArtwork(payload)
      dispatch(fetchOrderThunk({ orderNumber }))
      enqueueSnackbar(`Email sent successfully :)`, {
        variant: 'info',
      })
    } catch (err) {
      Bugsnag.notify(err)
      enqueueSnackbar(`Error sending the email :(`, {
        variant: 'error',
      })
    }

    handleClose()
  }

  const handleOpenSendProofs = () => {
    setModalAction(EButtonAction.SendProofs)
    setOpen(true)
    setModalWidth('sm')
  }

  if (isLoading) {
    return <Container>Loading...</Container>
  }

  if (!order) {
    return <Container>No order found</Container>
  }

  return (
    <Container>
      <Helmet>
        <title>
          {order.orderNumber} - {getNicenameFromStatus(order.statusName)} -
          Corkscrew
        </title>
      </Helmet>
      <Header order={order} />
      <Box my={4}>
        {order.customerComments.length > 0 ? (
          <Alert severity="info">
            <b>Customer Comments: </b>
            {order.customerComments}
          </Alert>
        ) : (
          <Divider />
        )}
      </Box>
      <Box>
        <Box sx={{ display: 'flex' }}>
          <Typography
            variant="h5"
            component="h3"
            onClick={handleClick}
            sx={{ display: 'flex-inline' }}
          >
            Design list
          </Typography>
          <Box flexGrow={1}></Box>
          <Box>
            <Button
              variant="contained"
              color="secondary"
              endIcon={<AddPhotoAlternateIcon />}
              sx={{ marginRight: 2 }}
              disabled={
                ![
                  'StatusNew',
                  'StatusProofPending',
                  'StatusProofDesignInProgress',
                  'StatusProofUserReviewRejected',
                  'StatusProofUserArtworkPending',
                ].includes(order.statusName)
              }
              onClick={() => {
                apiService
                  .addLineItem({
                    orderId: order.id,
                    lines: [
                      {
                        parentLineId: '',
                        productSku: 'STICKER-CUSTOM-DIE',
                        materialSku: 'vinyl-white',
                        widthMM: 20,
                        heightMM: 20,
                        quantity: 1,
                      },
                    ],
                  })
                  .then(() => {
                    enqueueSnackbar('Adding design...', { variant: 'info' })
                    dispatch(fetchOrderThunk({ orderNumber }))
                      .then(unwrapResult)
                      .then(() => {
                        enqueueSnackbar('Design Added', { variant: 'success' })
                      })
                  })
                  .catch((err) => {
                    console.error(err)
                    enqueueSnackbar('Could not add design', {
                      variant: 'error',
                    })
                  })
              }}
            >
              Add Design
            </Button>
            <Button
              variant="contained"
              color="secondary"
              endIcon={<AddIcon />}
              disabled
            >
              Add Product
            </Button>
          </Box>
        </Box>
        {canSendProofs ? (
          <Suspense fallback={<div>Loading</div>}>
            <SendProofsButton onClick={handleOpenSendProofs} />
          </Suspense>
        ) : null}
        <List component="div" disablePadding>
          {order?.lineItems.length === 0 ? (
            <Typography variant="body2">This order has no designs</Typography>
          ) : null}

          {order?.lineItems.map((lineItem) => {
            return (
              <Paper key={lineItem.id} sx={{ padding: 2, marginTop: 2 }}>
                <OrderLineItem
                  id={lineItem.id}
                  orderNumber={orderNumber}
                  orderLineNumber={lineItem.lineNumber}
                  status={lineItem.status}
                  imgUrl={lineItem.activeProofImageUrl}
                  activeProofId={lineItem.activeProofId}
                  comment="-"
                  modifiers={[
                    {
                      title: 'Material',
                      value: lineItem.materialSku,
                    },
                    {
                      title: 'Size',
                      value: `${lineItem.widthMM / 10} x ${
                        lineItem.heightMM / 10
                      } cm (W x H)`,
                    },
                    {
                      title: 'Quantity',
                      value: lineItem.quantity,
                    },
                  ]}
                  productName={lineItem.productName}
                  onClick={(action) => handleClickOpen(lineItem, action)}
                  historyUrl={lineItem?.meta?.links.history}
                  options={[
                    {
                      name: 'Upload Customer Artwork',
                      action: EButtonAction.UploadCustomerArtwork,
                      visible:
                        lineItem.isProofingAvailable &&
                        ['StatusProofUserArtworkPending'].includes(
                          lineItem.status
                        ),
                    },
                    {
                      name: 'Create Proof',
                      action: EButtonAction.CreateProof,
                      visible:
                        lineItem.isProofingAvailable &&
                        [
                          'StatusProofPending',
                          'StatusProofDesignInProgress',
                          'StatusProofUserReviewRejected',
                        ].includes(lineItem.status),
                    },
                    {
                      name: 'Create Draft Proof',
                      action: EButtonAction.CreateDraftProof,
                      visible:
                        lineItem.isProofingAvailable &&
                        [
                          'StatusProofUserArtworkPending',
                          'StatusProofUserReviewRejected',
                          'StatusProofPending',
                          'StatusProofDesignInProgress',
                        ].includes(lineItem.status),
                    },
                    {
                      name: 'Reupload Proof',
                      action: EButtonAction.CreateProof,
                      visible:
                        lineItem.isProofingAvailable &&
                        order.statusName == 'StatusProofDesignInProgress' &&
                        ['StatusProofDesignInProgress'].includes(
                          lineItem.status
                        ),
                    },
                    {
                      name: 'Split Design',
                      action: EButtonAction.SplitOrderLine,
                      visible:
                        lineItem.isProofingAvailable &&
                        [
                          'StatusProofPending',
                          'StatusProofDesignInProgress',
                          'StatusProofUserReviewRejected',
                          'StatusProofUserArtworkPending',
                        ].includes(lineItem.status),
                    },
                    {
                      name: 'Delete Design',
                      action: EButtonAction.deleteOrderLineItem,
                      visible:
                        hasExplicitPermission(
                          user?.permissions || [],
                          EPermissions.OrderDelete
                        ) &&
                        lineItem.isProofingAvailable &&
                        [
                          'StatusNew',
                          'StatusProofPending',
                          'StatusProofDesignInProgress',
                          'StatusProofUserReviewRejected',
                          'StatusProofUserArtworkPending',
                        ].includes(lineItem.status),
                    },
                    {
                      name: 'Request New Artwork',
                      action: EButtonAction.RequestNewArtwork,
                      visible: [
                        'StatusProofPending',
                        'StatusProofUserArtworkPending',
                      ].includes(lineItem.status),
                    },
                    {
                      name: 'Send to Manufacturing',
                      action: EButtonAction.StartManufacturing,
                      visible:
                        lineItem.isProofingAvailable &&
                        [
                          'StatusProofUserReviewAccepted',
                          'StatusProofInternalReviewAccepted',
                          'StatusProofMakePrintReady',
                        ].includes(lineItem.status),
                    },
                    {
                      name: 'Send back to Design',
                      action: EButtonAction.RejectDesign,
                      visible:
                        lineItem.status.startsWith('StatusPrint') &&
                        hasPermission(
                          user?.permissions || [],
                          'StatusPrintPrinting'
                        ),
                    },
                  ]}
                />
              </Paper>
            )
          })}
        </List>
        {canSendProofs ? (
          <Suspense fallback={<div>Loading</div>}>
            <SendProofsButton onClick={handleOpenSendProofs} />
          </Suspense>
        ) : null}
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth={true}
          maxWidth={modalWidth}
          scroll={'paper'}
        >
          <Suspense fallback={<div>Loading</div>}>
            {lineItem &&
            modalAction &&
            [
              EButtonAction.CreateProof,
              EButtonAction.CreateDraftProof,
            ].includes(modalAction) ? (
              <ProofModalContent
                designCode={lineItem.designCode}
                storeId={order.storeId}
                lineItem={lineItem}
                onSubmit={handleProofSubmit}
                onClose={() => setOpen(false)}
                draftMode={modalAction === EButtonAction.CreateDraftProof}
                message={modalAction === EButtonAction.CreateProof}
              />
            ) : null}
            {lineItem && modalAction == EButtonAction.UploadCustomerArtwork ? (
              <CustomerArtworkModalContent
                designCode={lineItem.designCode}
                lineItem={lineItem}
                onSubmit={handleUploadArtworkSubmit}
                onClose={() => setOpen(false)}
              />
            ) : null}
            {lineItem && modalAction == EButtonAction.SplitOrderLine ? (
              <SplitModalContent
                designCode={lineItem.designCode}
                storeId={order.storeId}
                lineItem={lineItem}
                onSubmit={handleSplitSubmit}
                onClose={() => setOpen(false)}
              />
            ) : null}
            {lineItem && modalAction == EButtonAction.deleteOrderLineItem ? (
              <SimpleModalContent
                title={`Delete Order Line Item - ${lineItem.designCode}`}
                primaryButtonText="Delete"
                onSubmit={() => handledeleteOrderLineItem(lineItem.id)}
                onClose={() => setOpen(false)}
              >
                Are you absolutely sure you want to delete this line item? All
                associated artwork will be deleted
              </SimpleModalContent>
            ) : null}
            {lineItem && modalAction == EButtonAction.RequestNewArtwork ? (
              <RequestNewArtworkModal
                orderLanguageCode={order.languageCode}
                designCode={lineItem.designCode}
                orderLineId={lineItem.id}
                onClose={() => setOpen(false)}
                onSubmit={handleRequestNewArtwork}
              />
            ) : null}
            {modalAction == EButtonAction.SendProofs ? (
              <SendProofsModalContent
                orderNumber={orderNumber}
                onClose={() => setOpen(false)}
                onSent={() => {
                  setOpen(false)
                  dispatch(fetchOrderThunk({ orderNumber }))
                }}
              />
            ) : null}
            {lineItem && modalAction === EButtonAction.RejectDesign ? (
              <RejectDesignModalContent
                designCode={lineItem.designCode}
                orderLineId={lineItem.id}
                onClose={() => setOpen(false)}
                onSubmit={(payload) => {
                  console.log('x', payload)
                }}
              />
            ) : null}
          </Suspense>
        </Dialog>
      </Box>
      <Paper sx={{ padding: 2, marginTop: 2 }}>
        <Box>
          <Typography variant="h4">Internal messages</Typography>
          <p>
            These messages will only be seen inside KD/SI. They will <b>not</b>{' '}
            be sent to a customer
          </p>
          <form
            noValidate
            autoComplete="off"
            onSubmit={(evt) => {
              evt.preventDefault()
              handleOnCreateInternalMessage()
            }}
          >
            <TextField
              className={classes.newMessage}
              required
              id="send-message"
              label="Message"
              variant="outlined"
              multiline
              rows={internalMessage.split('\n').length}
              value={internalMessage}
              onChange={(evt) => setInternalMessage(evt.target.value)}
            />
            <div>
              <br />
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={internalMessage.length === 0}
              >
                Send
              </Button>
            </div>
          </form>
          <br />
          {internalMessages.map((msg) => {
            return (
              <MessageItem
                key={msg.id}
                timestamp={msg.createdAt}
                createdByName={msg.createdByUserName}
                avatarUrl={msg.createdByAvatarUrl}
                usage="internal"
              >
                {msg.message.split('\n').map((item, key) => {
                  return (
                    <Fragment key={key}>
                      {item}
                      <br />
                    </Fragment>
                  )
                })}
              </MessageItem>
            )
          })}
        </Box>
      </Paper>
      <Paper sx={{ padding: 2, marginTop: 2 }}>
        <Typography variant="h4" style={{ marginBottom: 8 }}>
          Order History
        </Typography>
        <div ref={historyOnScreenRef}>
          <Suspense fallback={<CircularProgress />}>
            {orderHistory.map((historyItem) => {
              return (
                <OrderHistoryItem
                  key={historyItem.id}
                  historyItem={historyItem}
                />
              )
            })}
          </Suspense>
        </div>
      </Paper>
    </Container>
  )
}
