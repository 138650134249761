import { RootState } from '..'

export const getIsLoading = (state: RootState): boolean => {
  return !!state.app.isLoading
}

export const getAppState = (state: RootState) => {
  return state.app
}

export const getRouterState = (state: RootState) => {
  return state.router
}
