import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { auth, setLogout } from '.'
import { Thunk } from '..'
import { IUserGetResponse } from '@kartdavid/corkscrew-types/internal'
import { config } from '../../config'

export const signin = (): Thunk => async (dispatch) => {
  try {
    auth.authorize({ prompt: 'login' })
  } catch (err) {
    dispatch(setLogout())
  }
}

export const signout = (): Thunk => async (dispatch) => {
  try {
    dispatch(setLogout())
  } catch (err) {
    console.error(err)
  }
}

export const fetchUser = createAsyncThunk(
  'auth/fetchUser',
  async (accessToken: string) => {
    const resp = await axios.get<IUserGetResponse>(
      `${config.REACT_APP_API_URL}/v1/user/me`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )

    return resp.data.data
  }
)

// export const fetchFiltersThunk = createAsyncThunk(
//   'app/fetchFiltersThunk',
//   async (orderNumber: string, { getState }) => {
//     const state: any = getState()

//     const resp = await axios.get<any>(
//       `${StaticAppConfig.REACT_APP_API_URL}/v1/users/me/data/filters`,
//       {
//         headers: {
//           Authorization: `Bearer ${state.auth.accessToken}`,
//         },
//       }
//     )

//     return resp
//   }
// )

// export const saveFilterThunk = createAsyncThunk(
//   'app/saveFilterThunk',
//   async (newFilter: string, { getState }) => {
//     const state: any = getState()

//     const resp = await axios.put<any>(
//       `${StaticAppConfig.REACT_APP_API_URL}/v1/users/me/data/filters`,
//       {
//         proofs: state.app.filters.concat(newFilter),
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${state.auth.accessToken}`,
//         },
//       }
//     )

//     return resp
//   }
// )
