import { createStyles, makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import clsx from 'clsx'
import React from 'react'
import { formatDuration } from '../../utils/date'
import { formatLength } from '../../utils/dimensions'

const useStyles = makeStyles(() =>
  createStyles({
    timeLength: {
      fontFamily: 'Courier, Monospace',
      fontSize: 17,
    },
    total: {
      fontWeight: 'bold',
    },
  })
)

interface ITotalRowProps {
  pressTime: number
  cutTime: number
  runLengthMM: number
}

const TotalRow: React.FC<ITotalRowProps> = (props: ITotalRowProps) => {
  const classes = useStyles()

  return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell
        align="left"
        className={clsx(classes.timeLength, classes.total)}
      >
        {formatDuration(props.pressTime)}
      </TableCell>
      <TableCell align="left">{formatDuration(props.cutTime)}</TableCell>
      <TableCell
        align="left"
        className={clsx(classes.timeLength, classes.total)}
      >
        {formatLength(props.runLengthMM)}
      </TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
    </TableRow>
  )
}

export default TotalRow
