import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core'
import { IOrderLineItemHistory } from '@kartdavid/corkscrew-types/internal'
import FileLink from '../../FileLink'

const useStyles = makeStyles((theme: Theme) => ({
  instructions: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '250px',
    },
  },
  updatedTypo: {},
  greyedTypo: {
    color: theme.colors.grey,
    fontStyle: 'italic',
  },
  regularFont: {
    fontSize: theme.typography.pxToRem(18),
  },
}))

const nl2br = (s: string) => s.replaceAll('\n', '<br />')

interface Nl2brProps {
  s: string
}

const Nl2br: React.FC<Nl2brProps> = (props: Nl2brProps) => {
  return <div dangerouslySetInnerHTML={{ __html: nl2br(props.s) }}></div>
}

interface IProps {
  historyItem: IOrderLineItemHistory
  orderNumber: string
  lineItemNumber: string
  apiUrl: string
  usage: 'internal' | 'public'

  showProductInfo: boolean
}

interface IProductInfo {
  suffix?: string
  historyItem: IOrderLineItemHistory

  showProduct?: boolean
  showMaterial?: boolean
  showSize?: boolean
  showShape?: boolean
  showQuantity?: boolean
}

const ProductInfo: React.FC<IProductInfo> = ({
  historyItem,
  showProduct,
  showMaterial,
  showSize,
  showQuantity,
  showShape = true,
  suffix = '',
}: IProductInfo) => {
  const classes = useStyles()

  return (
    <div className={classes.updatedTypo}>
      {showProduct ? (
        <Typography className={classes.regularFont}>
          Product{suffix}: {historyItem.productName}
        </Typography>
      ) : null}

      {showMaterial ? (
        <Typography className={classes.regularFont}>
          Material{suffix}: {historyItem.materialSku}
        </Typography>
      ) : null}

      {showSize ? (
        <Typography className={classes.regularFont}>
          Size{suffix}: {historyItem.widthMM / 10} x {historyItem.heightMM / 10}{' '}
          cm
        </Typography>
      ) : null}

      {showQuantity ? (
        <Typography className={classes.regularFont}>
          Quantity{suffix}: {historyItem.quantity}
        </Typography>
      ) : null}

      {showShape ? (
        <Typography className={classes.regularFont}>
          Shape{suffix}: {(historyItem as unknown as any).shape}
        </Typography>
      ) : null}
    </div>
  )
}

const DetailedMessageItem: React.FunctionComponent<IProps> = ({
  historyItem,
  orderNumber,
  lineItemNumber,
  apiUrl,
  usage,
  showProductInfo = true,
}: IProps) => {
  const classes = useStyles()

  const suffix = historyItem.changeType === 'created' ? '' : ' set to'
  const showMaterial =
    usage === 'internal' && historyItem.changeType !== 'proof_rejected'
  const showProduct = showMaterial
  const showQuantity = showMaterial
  const showSize = showMaterial

  return (
    <Box>
      <div>
        <Box>
          {historyItem.files &&
            historyItem.files.map((file) => (
              <FileLink
                key={file.id}
                orderNumber={orderNumber}
                lineItemNumber={lineItemNumber}
                file={file}
                downloadAs={file.purpose[0]}
                apiUrl={apiUrl}
                usage={usage}
              />
            ))}
        </Box>
        {showProductInfo ? (
          <ProductInfo
            suffix={suffix}
            historyItem={historyItem}
            showMaterial={showMaterial}
            showProduct={showProduct}
            showQuantity={showQuantity}
            showSize={showSize}
          />
        ) : null}
        {historyItem.instructions.length > 0 ? (
          <div className={classes.instructions}>
            <Typography className={classes.regularFont}>
              <Nl2br s={historyItem.instructions}></Nl2br>
            </Typography>
          </div>
        ) : null}
      </div>
    </Box>
  )
}

export default DetailedMessageItem
