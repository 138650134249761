import { createStyles, makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import {
  IBatchProductEstimateResponse,
  IMaterial,
  IOrderLineItem,
} from '@kartdavid/corkscrew-types/internal'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { getNicenameFromStatus } from '../../utils/orders'
import { formatDuration } from '../../utils/date'
import ShipDeadline from './ShipDeadline'
import { Theme } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import { NavLink } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import BatchInfo from './BatchInfo'
import { apiService } from '../../service/api'
import useBatchStatus from '../../hooks/useBatchStatus'
import { formatLength } from '../../utils/dimensions'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    timeLength: {
      fontFamily: 'Courier, Monospace',
      fontSize: 17,
    },
    total: {
      fontWeight: 'bold',
    },
  })
)

interface IManufacturingRowProps {
  checked?: boolean
  row: IOrderLineItem
  materials: IMaterial[]
  onCheck?: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void
}

const ManufacturingRow: React.FC<IManufacturingRowProps> = (
  props: IManufacturingRowProps
) => {
  const classes = useStyles()
  const [proofEstimation, setProofEstimation] = useState<
    IBatchProductEstimateResponse | undefined
  >()

  const { materials, onCheck } = props

  const [row, setRow] = useState(props.row)

  const { batchInfo, batchLoading } = useBatchStatus(row.batchId)

  useEffect(() => {
    setRow(props.row)
  }, [props.row])

  useEffect(() => {
    apiService.getBatchEstimate(row.id).then(setProofEstimation)
  }, [row.id])

  useEffect(() => {
    apiService.fetchJob(row.designCode).then((job) => {
      if (job.data.data) {
        setRow(job.data.data)
      }
    })
  }, [batchInfo?.status, row.designCode])

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={props.checked}
      tabIndex={-1}
      key={row.id}
      selected={props.checked}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={props.checked}
          disabled={row.materialSku.startsWith('transfer')}
          inputProps={{
            'aria-labelledby': `checkbox-${row.id}`,
          }}
          onChange={onCheck ? (event) => onCheck(event, row.id) : undefined}
        />
      </TableCell>
      <TableCell component="th" id={`checkbox-${row.id}`} scope="row">
        {getNicenameFromStatus(row.status)}
      </TableCell>
      <TableCell align="left">
        <BatchInfo loading={batchLoading} batchInfo={batchInfo} />
      </TableCell>
      <TableCell align="left">{row.designCode}</TableCell>
      <TableCell align="left">
        {materials.find((m) => m.sku === row.materialSku)?.name ||
          row.materialSku}
      </TableCell>
      <TableCell align="left">{row.laminateSku}</TableCell>
      <TableCell align="left">
        <ShipDeadline
          deadline={(row.deadlineShipDate ?? ('-' as unknown)) as string}
        />
      </TableCell>
      <TableCell align="left" className={classes.timeLength}>
        {proofEstimation && proofEstimation.stats
          ? 't' + formatDuration(proofEstimation.stats.pressTimeSeconds)
          : formatDuration(row.estPrintTime)}
      </TableCell>
      <TableCell align="left" className={classes.timeLength}>
        {proofEstimation && proofEstimation.stats
          ? 't' + formatDuration(proofEstimation.stats.cutTimeSeconds || 0)
          : '-'}
      </TableCell>
      <TableCell align="left" className={classes.timeLength}>
        {proofEstimation && proofEstimation.stats && 't'}
        {formatLength(
          proofEstimation && proofEstimation.stats
            ? proofEstimation.stats.totalRollLengthMM
            : row.estPrintLengthMM
        )}
      </TableCell>
      <TableCell align="left">{row.productName}</TableCell>
      {/* <TableCell align="left">
                {row.designer !== '-' ? (
                  row.designer
                ) : (
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => onAssignToMe(row.orderNumber)}
                  >
                    Assign to me
                  </Link>
                )}
              </TableCell> */}
      <TableCell align="left">
        <NavLink to={`/manufacturing/${row.designCode}`}>
          <ChevronRightIcon />
        </NavLink>
      </TableCell>
    </TableRow>
  )
}

export default ManufacturingRow
