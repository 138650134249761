import { CALLBACK } from './constants'

const curUrl = new URL(window.location.toString())
curUrl.pathname = CALLBACK

export const config = {
  REACT_APP_RELEASE_STAGE: process.env.REACT_APP_RELEASE_STAGE || '',
  REACT_APP_API_URL: process.env.REACT_APP_API_URL || '',
  REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || '',
  REACT_APP_AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  REACT_APP_AUTH0_CALLBACK: curUrl.toString(),
  REACT_APP_AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE || '',
  REACT_APP_GRAPHQL_URL: process.env.REACT_APP_GRAPHQL_URL || '',
}
