import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { formatDuration } from '../utils/date'
import {
  IBatch,
  IBatchProductEstimateResponse,
} from '@kartdavid/corkscrew-types/internal'
import { config } from '../config'

export interface IBatchTable {
  batchEstimate: IBatchProductEstimateResponse | IBatch
  links: boolean
  runLength?: boolean
}

export const BatchTable = ({
  batchEstimate,
  links,
  runLength,
}: IBatchTable) => {
  if (!batchEstimate.stats) {
    return null
  }

  const batchEstimateLines = batchEstimate
    ? [
        {
          title: 'Total Time',
          type: 'string',
          value: formatDuration(
            batchEstimate.stats.pressTimeSeconds +
              batchEstimate.stats.cutTimeSeconds
          ),
        },
        {
          id: 'runLength',
          title: 'Run Length',
          type: 'string',
          value: batchEstimate.stats.runLength,
        },
        {
          id: 'totalRollLength',
          title: 'Total Roll Length',
          type: 'string',
          value:
            (batchEstimate.stats.totalRollLengthMM / 1000).toFixed(2) + 'm',
        },
        {
          title: 'Press Time',
          type: 'string',
          value: formatDuration(batchEstimate.stats.pressTimeSeconds),
        },
        {
          type: 'string',
          title: 'Cut Time',
          value: formatDuration(batchEstimate.stats.cutTimeSeconds),
        },
        {
          title: 'Waste %',
          type: 'string',
          value: (batchEstimate.stats.wastePct * 100).toFixed(0) + '%',
        },
        {
          title: 'Total Cost',
          type: 'string',
          value: '£' + (batchEstimate.stats.totalCost / 100).toFixed(2),
        },
        {
          title: 'Report',
          type: 'link',
          value:
            batchEstimate.reportPdfUrl !== '' ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={config.REACT_APP_API_URL + batchEstimate.reportPdfUrl}
              >
                Download
              </a>
            ) : null,
        },
      ].filter((e) => {
        if (!links && e.type === 'link') {
          return false
        } else if (e.id && e.id === 'runLength' && runLength === false) {
          return false
        }

        return true
      })
    : []

  return (
    <Table aria-label="simple table" style={{ fontSize: '1.125rem' }}>
      <TableBody>
        {batchEstimateLines.map((line) => {
          return (
            <TableRow key={line.title}>
              <TableCell>
                <strong>{line.title}</strong>
              </TableCell>
              <TableCell>
                <code>{line.value}</code>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
