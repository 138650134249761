import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import HelpIcon from '@mui/icons-material/Help'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import React, { Fragment, Suspense, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, Link as RouterLink } from 'react-router-dom'

import { EButtonAction } from '../../components/order-line-item/OrderLineItem'
import { DATE_FORMAT, ROUTE_CUSTOMER_DETAILS } from '../../constants'
import { getAppState } from '../../store/app/selectors'

import { formatUtcDate } from '../../utils/date'
import {
  hasPermission,
  getNicenameFromStatus,
  hasPermissionRole,
} from '../../utils/orders'
import { EPermissions } from '../../store/auth'
import { DateTime } from 'luxon'
const FacebookIcon = React.lazy(() => import('@mui/icons-material/Facebook'))
const GTranslateIcon = React.lazy(
  () => import('@mui/icons-material/GTranslateOutlined')
)
import Flag from 'react-flagkit'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Switch from '@material-ui/core/Switch'
import { useAppDispatch } from '../../store'
import {
  assignOrderToUserId,
  setOrderStatusThunk,
  updateOrder,
} from '../../store/app/thunk'
import { closeSnack } from '../../store/app'
import { getAuthState } from '../../store/auth/selectors'
import { IOrder } from '@kartdavid/corkscrew-types/internal'
import Dialog from '@mui/material/Dialog'
import { apiService } from '../../service/api'
const GetProofLink = React.lazy(() => import('./GetProofLink'))

const getCountryCode = (languageCode?: string) => {
  if (!languageCode) {
    return null
  }

  const parts = languageCode.split('-')

  if (parts.length === 0) {
    return null
  } else if (parts.length === 1) {
    return parts[0].toUpperCase()
  }

  return parts[1].toUpperCase()
}

const useStyles = makeStyles(() =>
  createStyles({
    // The main flex container for the app's layout. Its min-height
    // is set to `100vh` so it always fill the height of the screen.
    grid: {
      display: 'grid',
    },
    twoCols: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    },
    navLink: {
      display: 'flex',
      alignItems: 'center',
    },
    boldText: {
      fontWeight: 800,
    },
    newMessage: {
      width: '100%',
    },
    minWidth: {
      minWidth: 180,
      display: 'inline-block',
    },
  })
)

export type HeaderProps = {
  order: IOrder
}

const Header = (props: HeaderProps) => {
  const { order } = props
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { designers, statuses } = useSelector(getAppState)
  const { user } = useSelector(getAuthState)
  const history = useHistory()
  const countryCode = getCountryCode(order.languageCode)
  const [modalAction, setModalAction] = useState<EButtonAction | null>(null)
  const [billingAddress, setBillingAddress] = useState<any>(null)
  const [shippingAddress, setShippingAddress] = useState<any>(null)

  const handleDesignerChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    dispatch(
      assignOrderToUserId({
        orderNumber: order.orderNumber,
        userId: event.target.value as string,
      })
    )
  }

  const handleLoadShippingAddress = () => {
    if (!order.shippingAddressId) {
      return
    }

    apiService
      .fetchAddress(order.id, order.shippingAddressId)
      .then((resp) => setShippingAddress(resp.data))
  }

  const handleLoadBillingAddress = () => {
    if (!order.billingAddressId) {
      return
    }

    apiService
      .fetchAddress(order.id, order.billingAddressId)
      .then((resp) => setBillingAddress(resp.data))
  }

  useEffect(() => {
    handleLoadBillingAddress()
    handleLoadShippingAddress()
  }, [order.id, order.shippingAddressId, order.billingAddressId])

  console.log(billingAddress, shippingAddress)

  const handleStatusChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    enqueueSnackbar(
      `Warning! Updating the status here will change all line items below to have the same status. `,
      {
        variant: 'warning',
        action: function onClose(key) {
          return (
            <Button
              onClick={() => {
                dispatch(closeSnack(key))
                dispatch(
                  setOrderStatusThunk({
                    orderNumber: order.orderNumber,
                    statusName: event.target.value as string,
                  })
                )
              }}
            >
              Would you like to continue?
            </Button>
          )
        },
      }
    )
  }

  const handleSetShippingDeadline = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = event?.target as HTMLInputElement

    if (!target.valueAsDate) {
      return
    }

    dispatch(
      updateOrder({
        orderNumber: order.orderNumber,
        deadlineShipDate: DateTime.fromJSDate(target.valueAsDate).toRFC2822(),
      })
    )
  }

  const onClose = () => setModalAction(null)

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={modalAction !== null}
        scroll={'paper'}
      >
        <Suspense fallback={<div>Loading</div>}>
          {modalAction == EButtonAction.GetProofLink ? (
            <GetProofLink
              customerId={order.customerId}
              orderId={order.id}
              onClose={onClose}
            />
          ) : null}
        </Suspense>
      </Dialog>
      <Box mb={2}>
        <Button
          className={classes.navLink}
          onClick={() => {
            history.goBack()
          }}
        >
          <ChevronLeftIcon />
          Go back
        </Button>
      </Box>
      <Typography variant="h4" component="h2">
        Order: {order.orderNumber}{' '}
        <Suspense fallback={<div></div>}>
          {countryCode && (
            <Flag
              country={countryCode}
              title={`Order Language: ${countryCode} (${order.languageCode})`}
            />
          )}
          {order.utmSource === 'facebook' ? (
            <FacebookIcon titleAccess={`Order acquired from Facebook`} />
          ) : null}
          {order.utmSource === 'google' ? (
            <GTranslateIcon titleAccess={`Order acquired from Google`} />
          ) : null}
        </Suspense>
      </Typography>

      <Grid container style={{ marginTop: 10 }}>
        <Grid item xs={12} md={8}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined">
                <InputLabel id="orderStatusLabel">Status</InputLabel>
                <Select
                  labelId="orderStatusLabel"
                  variant="outlined"
                  value={order.statusName}
                  placeholder="Status name"
                  defaultValue={order.statusName}
                  onChange={handleStatusChange}
                  label="Status"
                >
                  {statuses
                    .filter(
                      (status) =>
                        user?.permissions &&
                        hasPermission(user.permissions, status.id)
                    )
                    .map((status) => {
                      return (
                        <MenuItem
                          key={status.id}
                          value={status.id}
                          disabled={status.disabled}
                        >
                          {getNicenameFromStatus(status.name)}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
              <IconButton href="/help/status">
                <HelpIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined">
                <InputLabel id="designerLabel">Assigned To</InputLabel>
                <Select
                  variant="outlined"
                  value={order.assignedToId}
                  placeholder="Designer name"
                  defaultValue={order.assignedToId}
                  onChange={handleDesignerChange}
                  label="Assigned To"
                  style={{ minWidth: 200 }}
                >
                  {designers.map((designer) => {
                    return (
                      <MenuItem key={designer.id} value={designer.id}>
                        {designer.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography component="span" className={classes.boldText}>
                Order number
              </Typography>
              : <Typography component="span">{order.orderNumber}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography component="span" className={classes.boldText}>
                Customer
              </Typography>
              :{' '}
              <Link
                component={RouterLink}
                to={ROUTE_CUSTOMER_DETAILS.replace(':id', order.customerId)}
              >
                {order.customerName || '- Edit -'}
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                component="span"
                className={clsx(classes.boldText, classes.minWidth)}
              >
                Advertised Ship Date:
              </Typography>
              <TextField
                disabled
                type="date"
                defaultValue={formatUtcDate(
                  order.advertisedShipDate,
                  DATE_FORMAT
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                component="span"
                className={clsx(classes.boldText, classes.minWidth)}
              >
                Shipping deadline:
              </Typography>
              <TextField
                disabled={
                  !(
                    hasPermissionRole(
                      user?.permissions || [],
                      EPermissions.OrderManage
                    ) ||
                    hasPermissionRole(
                      user?.permissions || [],
                      EPermissions.ProofManage
                    )
                  )
                }
                type="date"
                value={formatUtcDate(order.deadlineShipDate, DATE_FORMAT)}
                inputProps={{
                  min: DateTime.now().toSQLDate(),
                  max: DateTime.now().plus({ days: 14 }).toSQLDate(),
                }}
                onChange={handleSetShippingDeadline}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} md={6}>
            {hasPermissionRole(
              user?.permissions || [],
              EPermissions.OrderManage
            ) && order.statusName.startsWith('StatusProof') ? (
              <Box>
                <Button
                  onClick={() => {
                    setModalAction(EButtonAction.GetProofLink)
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  Get Proof Link
                </Button>
              </Box>
            ) : null}
          </Grid>
        </Grid>
        <Box>
          <Box style={{ display: 'none' }}>
            <Typography
              component="span"
              className={clsx(classes.boldText, classes.minWidth)}
            >
              Whitelabel:
            </Typography>
            <Switch
              checked={false /*order.whitelabel*/}
              disabled={
                true ||
                !(
                  hasPermissionRole(
                    user?.permissions || [],
                    EPermissions.OrderManage
                  ) ||
                  hasPermissionRole(
                    user?.permissions || [],
                    EPermissions.ProofManage
                  )
                )
              }
              // onChange={(ev) => {
              //   dispatch(
              //     updateOrder({
              //       orderNumber: orderNumber,
              //       whitelabel: ev.target.checked,
              //     })
              //   )
              // }}
            />
          </Box>
        </Box>
      </Grid>
    </>
  )
}

export default Header
