import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import cuid from 'cuid'

import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'
import {
  IOrder,
  IOrderLineItem,
  IStatusWithInfo,
  IUser,
} from '@kartdavid/corkscrew-types/internal'
import { IFilterEditable, IProofsProps } from '../../types'
import {
  deleteFilterThunk,
  fetchJobThunk,
  fetchManufacturingOrders,
  fetchOrders,
  fetchOrderThunk,
  setFiltersThunk,
  setOrderStatusThunk,
} from './thunk'

export interface INotifcation {
  key: SnackbarKey
  message: SnackbarMessage
  options: OptionsObject
  dismissed?: boolean
}

export interface IInitialAppState {
  isLoading: boolean
  isFiltersOpen: boolean
  orders: IProofsProps[]
  manufacturingOrders: IOrderLineItem[]
  statuses: IStatusWithInfo[]
  designers: IUser[]
  filters: IFilterEditable[]
  notifications: INotifcation[]
  isLoadingfilters: boolean
  isLoadingOrders: boolean
  hasMoreOrders: boolean
  order: IOrder | null
  lineItem: IOrderLineItem | null
}

const initialState: IInitialAppState = {
  isLoading: false,
  isFiltersOpen: false,
  orders: [],
  manufacturingOrders: [],
  statuses: [],
  designers: [],
  filters: [],
  notifications: [],
  isLoadingfilters: false,
  isLoadingOrders: false,
  hasMoreOrders: false,
  order: null,
  lineItem: null,
}

const appReducer = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      const { payload } = action
      state.isLoading = payload
    },
    setStatuses: (state, action: PayloadAction<IStatusWithInfo[]>) => {
      const { payload } = action
      state.statuses = payload
    },
    setUsers: (state, action: PayloadAction<any[]>) => {
      const { payload } = action
      state.designers = [
        {
          id: 'null',
          name: 'Unassigned',
          username: '',
          nickname: '',
          firstName: '',
          lastName: '',
          avatarUrl: '',
          isChecked: false,
          externalId: '',
          permissions: [''],
        },
      ].concat(payload)
    },
    setFiltersToggle: (state, action) => {
      const { payload } = action
      state.isFiltersOpen = payload
    },
    assignOrderToMe: (state, action) => {
      state.orders = state.orders.map((order) => {
        if (order.orderNumber === action.payload.orderNumber) {
          return {
            ...order,
            designer: action.payload.userName,
          }
        }

        return order
      })
    },
    setSnack: (state, action: PayloadAction<any>) => {
      state.notifications = [
        ...state.notifications,
        {
          ...action.payload,
          key: cuid(),
        },
      ]
    },
    closeSnack: (state, action: PayloadAction<any>) => {
      state.notifications = state.notifications.map((notification) =>
        action.payload.dismissAll || notification.key === action.payload
          ? { ...notification, dismissed: true }
          : { ...notification }
      )
    },
    removeSnack: (state, action: PayloadAction<any>) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.key !== action.payload
      )
    },
    setFilter: (state, action: PayloadAction<IFilterEditable>) => {
      state.filters = state.filters.concat([action.payload])
    },
    setUpdateOrder: (state, action: PayloadAction<any>) => {
      // { key: keyof IOrder }
      state.order = {
        ...state.order,
        ...action.payload,
      }
    },
    setHasMoreOrders: (state, action) => {
      state.hasMoreOrders = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setFiltersThunk.pending, (state) => {
      state.isLoadingfilters = true
    })
    builder.addCase(
      setFiltersThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.filters = action.payload
        state.isLoadingfilters = false
      }
    )
    builder.addCase(deleteFilterThunk.fulfilled, (state, action) => {
      state.filters = state.filters.filter(
        (filter) => filter.id !== action.meta.arg.filterId
      )
    })
    builder.addCase(fetchOrders.pending, (state) => {
      state.isLoadingOrders = true
    })
    builder.addCase(
      fetchOrders.fulfilled,
      (state, action: PayloadAction</*IProofsProps[]*/ any>) => {
        state.orders = action.payload
        state.isLoadingOrders = false
      }
    )
    builder.addCase(fetchOrders.rejected, (state) => {
      state.isLoadingOrders = false
      state.orders = []
    })
    builder.addCase(fetchManufacturingOrders.pending, (state) => {
      state.isLoadingOrders = true
    })
    builder.addCase(
      fetchManufacturingOrders.fulfilled,
      (state, action: PayloadAction</* IOrderLineItem[]*/ any>) => {
        state.manufacturingOrders = action.payload
        state.isLoadingOrders = false
      }
    )
    builder.addCase(fetchManufacturingOrders.rejected, (state) => {
      state.isLoadingOrders = false
      state.orders = []
    })
    builder.addCase(
      fetchOrderThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        if (action.payload) {
          state.order = action.payload
        }
      }
    )
    builder.addCase(
      fetchJobThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        if (action.payload) {
          state.lineItem = action.payload
        }
      }
    )
    builder.addCase(
      setOrderStatusThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        const updatedLineItems =
          state.order?.lineItems.map((lineItem) => ({
            ...lineItem,
            status: action.payload.statusName,
          })) || []

        state.order = Object.assign({}, state.order, {
          statusName: action.payload.statusName,
          lineItems: updatedLineItems,
        })
      }
    )
  },
})

export default appReducer.reducer

// Actions
export const {
  setSnack,
  setUsers,
  setFilter,
  closeSnack,
  removeSnack,
  setStatuses,
  setIsLoading,
  assignOrderToMe,
  setFiltersToggle,
  // Update order
  setUpdateOrder,
  setHasMoreOrders,
} = appReducer.actions
