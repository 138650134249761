import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import React, {
  FunctionComponent,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { IMaterial, IOrderLineItem } from '@kartdavid/corkscrew-types/internal'
import { apiService } from '../../service/api'
import { useHistory, useLocation } from 'react-router'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fab from '@material-ui/core/Fab'
import PrintIcon from '@mui/icons-material/Print'
import uniq from 'lodash/uniq'
import Dialog from '@mui/material/Dialog'
import useBatchEstimate from '../../hooks/useBatchEstimate'
import { formatLength } from '../../utils/dimensions'
import PressFilters from './PressFilters'
import Chip from '@mui/material/Chip'
import MaterialFilters from './MaterialFilters'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import {
  cutterFilters,
  printStatuses,
  qaFilters,
} from '../../factories/filters-factory'
import ManufacturingRow from './ManufacturingRow'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import EnhancedTableToolbar from './TableToolbar'
import TotalRow from './TotalRow'
import EnhancedTableHead from './TableHead'
import QARow from './QARow'
import { qaHeadCells } from '@kartdavid/atomic-ui/dist/components/orders-table/tableDefaults'

// const ManufacturingRow = React.lazy(() => import('./ManufacturingRow'))
// const QARow = React.lazy(() => import('./QARow'))
// const TotalRow = React.lazy(() => import('./TotalRow'))
const SendToRIPModal = React.lazy(() => import('./SendToRIPModal'))

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },

    fab: {
      margin: theme.spacing(1),
      position: 'fixed',
      bottom: theme.spacing(4),
      right: theme.spacing(4),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  })
)

export const Manufacturing: FunctionComponent = () => {
  const classes = useStyles()

  const history = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  console.log('params', params.toString())
  const page = parseInt(params.get('page') || '0')
  const rowsPerPage = parseInt(params.get('perPage') || '50')
  const [materials, setMaterials] = useState<IMaterial[]>([])
  const [orderLines, setOrderLines] = useState<IOrderLineItem[]>([])
  // FILTERS
  const [checkedOrderLines, setCheckedOrderLines] = useState<IOrderLineItem[]>(
    []
  )
  const [hasMore, setHasMore] = useState(false)
  const [showPrintDialog, setShowPrintDialog] = useState(false)
  const [lastRefereshed, setLastRefreshed] = useState<Date>(new Date(0))
  // const abortController = useRef(new AbortController())

  const tooManyDifferentMaterialsError = useMemo(() => {
    return (
      uniq(
        checkedOrderLines.map((order) =>
          [order.materialSku, order.laminateSku].join('|')
        )
      ).length > 1
    )
  }, [checkedOrderLines])

  // const handleChangePage = (event: unknown, newPage: number) => {
  //   params.set('page', newPage.toString())
  //   history.push({
  //     search: params.toString(),
  //   })
  // }

  // const handleChangeRowsPerPage = (perPage: number) => {
  //   params.set('perPage', `${perPage}`)
  //   history.push({
  //     search: params.toString(),
  //   })
  // }

  useEffect(() => {
    apiService.fetchMaterials().then((resp) => {
      setMaterials(resp.data.data)
    })
  }, [])

  useEffect(() => {
    refreshOrders()

    const interval = setInterval(async () => {
      if (new Date().getTime() - lastRefereshed.getTime() > 30 * 1000) {
        refreshOrders(false)
      }
    }, 30 * 1000)
    return () => clearInterval(interval)
  }, [page, rowsPerPage, params.toString()])

  const refreshOrders = (withClear = true) => {
    if (withClear) {
      setOrderLines([])
      setCheckedOrderLines([])
    }

    // Zund
    const isCutter = params.get('thingId') === 'th-cl1c1ee2h000009jrhjfygwfe'
    const isQA = params.get('thingId') === 'quality'
    const printFilter = {
      id: 'any',
      name: 'any',
      status: printStatuses.map((s) => {
        return { id: s, name: s }
      }),
      designers: [],
      materials: params.getAll('materialSku').map((materialSku) => {
        return {
          id: materialSku,
          name: materialSku,
        }
      }),
      pressIds: params.getAll('thingId'),
    }
    const filter = isCutter
      ? cutterFilters[0]
      : isQA
      ? qaFilters[0]
      : printFilter

    // console.log("TIME X", lastRefereshed.getTime(), abortController)

    // if (abortController.current && lastRefereshed.getTime() > 0) {
    //   abortController.current.abort()
    // }

    apiService
      .fetchManufacturingOrders(
        rowsPerPage,
        page,
        filter
        // abortController.current.signal
      )
      .then((resp) => {
        setOrderLines(resp.data.data)
        setHasMore(resp.data.meta?.pagination?.hasMore || false)
        setLastRefreshed(new Date())
      })
  }

  const stats = useBatchEstimate(checkedOrderLines)
  const checkedStats = useBatchEstimate(checkedOrderLines)
  const checkedOrderLineIds = useMemo(
    () => checkedOrderLines.map((ol) => ol.id),
    [checkedOrderLines]
  )

  const isQA = params.get('thingId') === 'quality'

  return (
    <Suspense fallback={<CircularProgress />}>
      <Container maxWidth={false}>
        <Box>
          <Box display="flex">
            <PressFilters />
            <Box flexGrow={1} />
            <Chip
              color={
                params.get('thingId') === 'quality' ? 'primary' : 'default'
              }
              onDelete={
                params.get('thingId') === 'quality'
                  ? () => {
                      const newParams = new URLSearchParams(params)
                      newParams.delete('thingId')

                      history.push({
                        search: newParams.toString(),
                      })
                    }
                  : undefined
              }
              label="Quality Control"
              onClick={() => {
                const newParams = new URLSearchParams(params)
                newParams.set('thingId', 'quality')

                history.push({
                  search: newParams.toString(),
                })
              }}
            />
          </Box>
          <MaterialFilters />
          <Divider />
          <Paper sx={{ width: '100%', mb: 2 }}>
            <EnhancedTableToolbar numSelected={checkedOrderLines.length} />

            <TableContainer>
              <Table>
                <EnhancedTableHead
                  numSelected={checkedOrderLines.length}
                  onSelectAllClick={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ) => {
                    if (event.target.checked) {
                      setCheckedOrderLines(
                        orderLines.filter(
                          (ol) => !ol.materialSku.startsWith('transfer')
                        )
                      )
                      return
                    }
                    setCheckedOrderLines([])
                  }}
                  rowCount={orderLines.length}
                  headCells={isQA ? qaHeadCells : undefined}
                />
                <TableBody>
                  {orderLines.map((ol) => {
                    if (isQA) {
                      return (
                        <QARow
                          key={ol.id}
                          row={ol}
                          materials={materials}
                          onClick={() => {
                            console.log('clicked')
                          }}
                        />
                      )
                    }

                    return (
                      <ManufacturingRow
                        checked={checkedOrderLineIds.includes(ol.id)}
                        key={ol.id}
                        row={ol}
                        materials={materials}
                        onCheck={() => {
                          setCheckedOrderLines((prev) => {
                            if (prev.map((item) => item.id).includes(ol.id)) {
                              return prev.filter((item) => item.id !== ol.id)
                            }

                            return prev.concat(ol)
                          })
                        }}
                      />
                    )
                  })}
                  {checkedOrderLines.length > 0 ? (
                    <TotalRow
                      pressTime={stats.estPrintTime}
                      cutTime={stats.estCutTime}
                      runLengthMM={stats.estPrintLengthMM}
                    />
                  ) : null}
                </TableBody>
              </Table>

              <TablePagination
                rowsPerPageOptions={uniq([rowsPerPage, 50, 100, 250]).sort(
                  (a, b) => a - b
                )}
                component="div"
                count={
                  page === 0 && !hasMore
                    ? orderLines.length
                    : (page + 1) * rowsPerPage + (hasMore ? 1 : 0)
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event: unknown, newPage) => {
                  const newParams = new URLSearchParams(params.toString())
                  newParams.set('page', newPage.toString())
                  history.push({
                    search: newParams.toString(),
                  })
                }}
                onRowsPerPageChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ) => {
                  const newParams = new URLSearchParams(params.toString())
                  newParams.set('page', '0')
                  newParams.set('perPage', event.target.value)
                  history.replace({
                    search: newParams.toString(),
                  })
                }}
              />
            </TableContainer>
          </Paper>{' '}
        </Box>
        {checkedOrderLines.length > 0 && checkedOrderLines.length <= 50 ? (
          <Fab
            variant="extended"
            color="primary"
            aria-label="add"
            className={classes.fab}
            onClick={() => {
              setShowPrintDialog(true)
            }}
            disabled={tooManyDifferentMaterialsError}
            title={
              tooManyDifferentMaterialsError
                ? 'You can only RIP one material at a time'
                : 'Open RIP settings'
            }
          >
            <PrintIcon className={classes.extendedIcon} />
            Send to RIP ({formatLength(checkedStats.estPrintLengthMM)})
          </Fab>
        ) : null}
        <Dialog
          onClose={() => setShowPrintDialog(false)}
          aria-labelledby="customized-dialog-title"
          open={showPrintDialog}
          maxWidth={'sm'}
          scroll={'paper'}
        >
          <Suspense fallback={<CircularProgress />}>
            <SendToRIPModal
              orderLines={checkedOrderLines}
              onClose={() => {
                setShowPrintDialog(false)
                setCheckedOrderLines([])
                refreshOrders()
              }}
              tooManyDifferentMaterialsError={tooManyDifferentMaterialsError}
              totalLengthMM={checkedStats.estPrintLengthMM}
            />
          </Suspense>
        </Dialog>
      </Container>
    </Suspense>
  )
}
