import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from './TablePagination'

import { ROWS_PER_PAGE_OPTIONS } from '../../constants'
import { HeadCell, IProofsProps } from '../../types'
import { getComparator, SortOrder, stableSort } from './'
import EnhancedTableHead from './EnhancedTableHead'
import { defaultHeadCells } from './tableDefaults'

// const useToolbarStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       paddingLeft: theme.spacing(2),
//       paddingRight: theme.spacing(1),
//     },
//     highlight:
//       theme.palette.type === 'light'
//         ? {
//             color: theme.palette.secondary.main,
//             backgroundColor: lighten(theme.palette.secondary.light, 0.85),
//           }
//         : {
//             color: theme.palette.text.primary,
//             backgroundColor: theme.palette.secondary.dark,
//           },
//     title: {
//       flex: '1 1 100%',
//     },
//   })
// )

// interface EnhancedTableToolbarProps {
//   numSelected: number
//   name: string
// }

// const EnhancedTableToolbar = ({
//   name,
//   numSelected,
// }: EnhancedTableToolbarProps) => {
//   const classes = useToolbarStyles()

//   return (
//     <Toolbar>
//       {numSelected > 0 ? (
//         <Typography
//           className={classes.title}
//           color="inherit"
//           variant="subtitle1"
//           component="div"
//         >
//           {numSelected} selected
//         </Typography>
//       ) : (
//         <Typography
//           className={classes.title}
//           variant="h6"
//           id="tableTitle"
//           component="div"
//         >
//           {name}
//         </Typography>
//       )}
//       {/* {numSelected > 0 ? (
//         <Tooltip title="Delete">
//           <IconButton aria-label="delete">Icon</IconButton>
//         </Tooltip>
//       ) : (
//         <Tooltip title="Filter list">
//           <IconButton aria-label="filter list">Icon</IconButton>
//         </Tooltip>
//       )} */}
//     </Toolbar>
//   )
// }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
  })
)

interface ITableProps<T> {
  name: string
  rows: Array<T>
  hasMore: boolean
  headCells?: HeadCell[]
  isLoading: boolean
  onAssignToMe: (orderNumber: string) => void
  renderRow: (
    row: T,
    isSelected: boolean,
    handleClick: (event: React.MouseEvent<unknown>, id: string) => void,
    onAssignToMe: (orderNumber: string) => void
  ) => React.ReactChild
}

export const OrderTable: React.FunctionComponent<ITableProps<IProofsProps>> = ({
  rows = [],
  headCells = defaultHeadCells,
  isLoading,
  onAssignToMe,
  renderRow,
  hasMore,
}: ITableProps<IProofsProps>) => {
  const classes = useStyles()
  const history = useHistory()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const page = parseInt(params.get('page') || '0')
  const rowsPerPage = parseInt(params.get('perPage') || '10')

  const [order, setOrder] = React.useState<SortOrder>('asc')
  const [orderBy, setOrderBy] = React.useState<string>('priority')
  const [selected, setSelected] = React.useState<string[]>([])
  const [sorted, setSorted] = React.useState<IProofsProps[]>([])

  useEffect(() => {
    setSorted(stableSort<IProofsProps>(rows, getComparator(order, orderBy)))
  }, [rows, order, orderBy])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: IProofsProps) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    params.set('page', newPage.toString())
    history.push({
      search: `?${params.toString()}`,
    })
  }

  const handleChangeRowsPerPage = (perPage: number) => {
    params.set('perPage', `${perPage}`)
    history.push({
      search: `?${params.toString()}`,
    })
  }

  const isSelected = (id: string) => selected.indexOf(id) !== -1

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {/* <EnhancedTableToolbar name={name} numSelected={selected.length} /> */}
        <TableContainer>
          {isLoading ? <LinearProgress /> : <div style={{ height: 4 }}></div>}
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
            style={{ position: 'relative' }}
          >
            <EnhancedTableHead
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {sorted.map((row) => {
                const isItemSelected = isSelected(row.id)

                return renderRow(row, isItemSelected, handleClick, onAssignToMe)
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          hasMore={hasMore}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}
