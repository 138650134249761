import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import FormControl from '@mui/material/FormControl'
import * as yup from 'yup'
import FormHelperText from '@mui/material/FormHelperText'
import { Link, useHistory, useParams } from 'react-router-dom'
import { apiService } from '../service/api'
import { useSnackbar } from 'notistack'
import { pick } from 'lodash'
import { ICustomer } from '@kartdavid/corkscrew-types/internal'
import { PROOFS_DETAIL, ROUTE_CUSTOMER_DETAILS } from '../constants'
import { useAppApolloClient } from '../hooks/useApolloClient'
import { Order, OrderType } from '@kartdavid/corkscrew-types/corkscrew'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
// import AddBoxIcon from '@mui/icons-material/AddBox'
import { hasPermissionRole } from '../utils/orders'
import { EPermissions } from '../store/auth'
import { useSelector } from 'react-redux'
import { getAuthState } from '../store/auth/selectors'
import SplitButton from '../components/SplitButton'

const schema = yup.object({
  firstName: yup.string().required().min(1),
  lastName: yup.string().required().min(1),
  companyName: yup.string().optional(),
  email: yup.string().email().required(),
  phone: yup.string().optional(),
})
type IFormData = yup.InferType<typeof schema>

const CustomerDetails = () => {
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const isAddMode = params.id === 'add'
  const [customer, setCustomer] = useState<ICustomer | null>(null)
  const [orders, setOrders] = useState<Order[]>([])
  const [formData, setFormData] = useState<IFormData>({
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    phone: '',
  })
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (isAddMode) {
      setCustomer({
        id: '',
        customerNumber: '',
        languageCode: '',
        firstName: '',
        lastName: '',
        companyName: '',
        email: '',
        phone: '',
        storeHash: '',
      })

      return
    }

    apiService
      .getCustomer(params.id)
      .then((c) => {
        if (!c) {
          enqueueSnackbar('could not get customer', { variant: 'error' })
          return
        }

        setCustomer(c)
        setFormData(
          pick(c, ['firstName', 'lastName', 'companyName', 'email', 'phone'])
        )
      })
      .catch(() => {
        enqueueSnackbar('could not get customer', { variant: 'error' })
      })
  }, [params.id, isAddMode])

  const errors = useMemo(() => {
    try {
      schema.validateSync(formData, { abortEarly: false })

      return []
    } catch (err) {
      return (err as yup.ValidationError).inner as yup.ValidationError[]
    }
  }, [formData])
  const isValid = errors.length === 0
  const hasError = (fieldName: string): boolean => {
    return !isValid && errors.find((e) => e.path === fieldName) ? true : false
  }

  const { user } = useSelector(getAuthState)
  const cl = useAppApolloClient()

  useEffect(() => {
    if (!customer) {
      return
    }

    apiService
      .listCustomerOrders(cl, customer.id)
      .then((newOrders) =>
        setOrders(newOrders.slice().sort((a, b) => b.id.localeCompare(a.id)))
      )
  }, [cl, customer?.id])

  return (
    <Container>
      <Paper sx={{ padding: 1 }}>
        <Typography variant="h5">
          Customer Details ({customer?.customerNumber || ''})
        </Typography>
        <form
          onSubmit={(ev) => {
            if (
              !hasPermissionRole(
                user?.permissions || [],
                EPermissions.OrderManage
              )
            ) {
              enqueueSnackbar(
                "You don't have permission to update this customer",
                { variant: 'error' }
              )
            }

            ev.preventDefault()

            if (!isValid) {
              enqueueSnackbar('form is invalid', { variant: 'warning' })
              return
            }

            if (isAddMode) {
              return apiService
                .createCustomer({
                  firstName: formData.firstName,
                  lastName: formData.lastName,
                  companyName: formData.companyName || '',
                  email: formData.email,
                  phone: formData.phone || '',
                  languageCode: 'en-us',
                })
                .then((resp) => {
                  if (!resp) {
                    return
                  }
                  history.replace(
                    ROUTE_CUSTOMER_DETAILS.replace(':id', resp.id)
                  )
                  enqueueSnackbar('updated', {
                    variant: 'success',
                  })
                })
                .catch((err) => {
                  enqueueSnackbar(err.response.data.message, {
                    variant: 'error',
                  })
                })
            }

            if (!customer || !customer.id || !customer.languageCode) {
              return
            }

            apiService
              .updateCustomer({
                ...formData,
                id: customer.id || '',
                languageCode: customer.languageCode || '',
                companyName: formData.companyName || '',
                phone: formData.phone || '',
              })
              .then((newCust) => {
                if (!newCust) {
                  enqueueSnackbar('could not update customer', {
                    variant: 'error',
                  })
                  return
                }

                setFormData(
                  pick(newCust, [
                    'firstName',
                    'lastName',
                    'companyName',
                    'email',
                    'phone',
                  ])
                )
                enqueueSnackbar('updated', {
                  variant: 'success',
                })
              })
              .catch(() => {
                enqueueSnackbar('could not update customer', {
                  variant: 'error',
                })
              })
          }}
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined">
                  <TextField
                    value={formData.firstName}
                    disabled={!customer}
                    error={hasError('firstName')}
                    variant="outlined"
                    onChange={(event) => {
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        firstName: event.target.value,
                      }))
                    }}
                    label="First name"
                  />
                </FormControl>
                {hasError('firstName') ? (
                  <FormHelperText error>
                    {errors.find((e) => e.path === 'firstName')?.message || ''}
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined">
                  <TextField
                    value={formData.lastName}
                    disabled={!customer}
                    error={hasError('lastName')}
                    variant="outlined"
                    onChange={(event) => {
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        lastName: event.target.value,
                      }))
                    }}
                    label="Last Name"
                  />
                </FormControl>
                {hasError('lastName') ? (
                  <FormHelperText error>
                    {errors.find((e) => e.path === 'lastName')?.message || ''}
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined">
                  <TextField
                    value={formData.companyName}
                    disabled={!customer}
                    error={hasError('companyName')}
                    variant="outlined"
                    onChange={(event) => {
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        companyName: event.target.value,
                      }))
                    }}
                    label="Company Name"
                  />
                </FormControl>
                {hasError('companyName') ? (
                  <FormHelperText error>
                    {errors.find((e) => e.path === 'companyName')?.message ||
                      ''}
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined">
                  <TextField
                    value={formData.email}
                    disabled={!customer}
                    error={hasError('email')}
                    variant="outlined"
                    onChange={(event) => {
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        email: event.target.value,
                      }))
                    }}
                    label="Email"
                  />
                </FormControl>
                {hasError('email') ? (
                  <FormHelperText error>
                    {errors.find((e) => e.path === 'email')?.message || ''}
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined">
                  <TextField
                    value={formData.phone}
                    disabled={!customer}
                    error={hasError('phone')}
                    variant="outlined"
                    onChange={(event) => {
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        phone: event.target.value,
                      }))
                    }}
                    label="Phone"
                  />
                </FormControl>
                {hasError('phone') ? (
                  <FormHelperText error>
                    {errors.find((e) => e.path === 'phone')?.message || ''}
                  </FormHelperText>
                ) : null}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={() => history.go(-1)}>
              Cancel / Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!isValid}
              type="submit"
            >
              {isAddMode ? 'Add' : 'Save'}
            </Button>
          </DialogActions>
        </form>
      </Paper>
      <Paper sx={{ marginTop: 2, padding: 2 }}>
        <Box sx={{ display: 'flex' }}>
          <Typography variant="h5">Orders</Typography>
          {hasPermissionRole(
            user?.permissions || [],
            EPermissions.OrderManage
          ) ? (
            <Fragment>
              <Box flexGrow={1}></Box>
              {/* <Button
                variant="contained"
                color="secondary"
                endIcon={<AddBoxIcon />}
                sx={{ marginRight: 2 }}
              >
                Add Order
              </Button> */}
              <SplitButton
                onClick={(orderType) => {
                  if (!customer) {
                    return
                  }

                  apiService
                    .allocateOrder(cl, {
                      customerId: customer.id,
                      storeHash: customer.storeHash,
                      type: orderType as OrderType,
                    })
                    .then((newOrder) => {
                      if (!newOrder) {
                        return
                      }

                      enqueueSnackbar('Added order ' + newOrder.orderNumber, {
                        variant: 'success',
                      })
                      history.push(
                        PROOFS_DETAIL.replace(
                          ':orderNumber',
                          newOrder.orderNumber
                        )
                      )
                    })
                    .catch((err) => {
                      console.error(err)
                      enqueueSnackbar('Error adding order', {
                        variant: 'error',
                      })
                    })
                }}
                options={[
                  { id: OrderType.Web, name: 'Add Order' },
                  { id: OrderType.Marketing, name: 'Add Marketing Order' },
                  { id: OrderType.Reprint, name: 'Add Reprint Order' },
                ]}
              />
            </Fragment>
          ) : null}
        </Box>
        <TableContainer>
          <Table>
            {/* <EnhancedTableHead
                  numSelected={0}
                  onSelectAllClick={() => {
                    console.log('loaded')
                  }}
                  rowCount={orderLines.length}
                  headCells={undefined}
                /> */}
            <TableBody>
              {orders.map((order) => {
                return (
                  <TableRow key={order.id}>
                    <TableCell>{order.orderNumber}</TableCell>
                    <TableCell>
                      <Button
                        component={Link}
                        to={PROOFS_DETAIL.replace(
                          ':orderNumber',
                          order.orderNumber
                        )}
                      >
                        View
                      </Button>
                    </TableCell>
                    {/* <TableCell>{customer.lastName}</TableCell>
                        <TableCell>{customer.companyName}</TableCell>
                        <TableCell>{customer.email}</TableCell>
                        <TableCell>{customer.phone}</TableCell>
                        <TableCell>
                          <Button
                            component={Link}
                            to={ROUTE_CUSTOMER_DETAILS.replace(
                              ':id',
                              customer.id
                            )}
                          >
                            Edit
                          </Button>
                        </TableCell> */}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>

          {/* <TablePagination
            rowsPerPageOptions={uniq([rowsPerPage, 50, 100, 250]).sort(
              (a, b) => a - b
            )}
            component="div"
            count={
              page === 0 && !hasMore
                ? orderLines.length
                : (page + 1) * rowsPerPage + (hasMore ? 1 : 0)
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event: unknown, newPage) => {
              const newParams = new URLSearchParams(params.toString())
              newParams.set('page', newPage.toString())
              history.push({
                search: newParams.toString(),
              })
            }}
            onRowsPerPageChange={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              const newParams = new URLSearchParams(params.toString())
              newParams.set('page', '0')
              newParams.set('perPage', event.target.value)
              history.replace({
                search: newParams.toString(),
              })
            }}
          /> */}
        </TableContainer>
      </Paper>
    </Container>
  )
}

export default CustomerDetails
