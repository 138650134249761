import { DateTime } from 'luxon'
import React from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'

interface IShipDeadlineProps {
  deadline: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    past: {
      color: theme.colors.redDark,
      fontWeight: 'bold',
    },
    today: {
      color: theme.colors.coolBlueDark,
      fontWeight: 'bold',
    },
    future: {
      color: theme.colors.greenDark,
    },
  })
)

const ShipDeadline = (props: IShipDeadlineProps) => {
  const classes = useStyles()
  const parsed = DateTime.fromISO(props.deadline)
  const now = DateTime.utc()

  const isPast = parsed.toSeconds() <= now.toSeconds()

  return (
    <span
      className={clsx({
        [classes.past]: isPast,
        [classes.today]: !isPast && parsed.toSQLDate() === now.toSQLDate(),
        [classes.future]: parsed.toSeconds() >= now.toSeconds(),
      })}
    >
      {parsed.toISODate()}{' '}
    </span>
  )
}

export default ShipDeadline
