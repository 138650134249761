import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import SaveIcon from '@mui/icons-material/Save'

import isEqual from 'lodash/isEqual'
import React, { FunctionComponent, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAppState } from '../../store/app/selectors'
import { IFilterEditable } from '../../types'
import cuid from 'cuid'

const drawerWidth = 340

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
    title: {
      flexGrow: 1,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: -1,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
    },
    content: {
      flexGrow: 1,
      // padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    button: {
      marginTop: theme.spacing(1),
      width: '100%',
    },
  })
)

interface IFiltersDrawerProps {
  onDrawerClose: () => void
  onSaveFilter: (filter: IFilterEditable) => void
}

export const FiltersDrawer: FunctionComponent<IFiltersDrawerProps> = ({
  onDrawerClose,
  onSaveFilter,
}: IFiltersDrawerProps) => {
  const classes = useStyles()
  const theme = useTheme()

  const { statuses, designers, filters } = useSelector(getAppState)

  // State
  const [collapseOpen, setCollapseOpen] = React.useState(true)
  const [designerOpen, setDesignerOpen] = React.useState(false)

  const defaultFormData: IFilterEditable = {
    id: cuid(),
    name: '',
    status: [],
    designers: [],
    materials: [],
    pressIds: [],
    isEditable: true,
  }

  const [formData, setFormData] = useState<IFilterEditable>(defaultFormData)

  const handleOnSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault()

    const newFilters = {
      status: formData.status,
      designers: formData.designers,
    }

    const hasDuplicated = filters.reduce((acc, filter) => {
      const savedFiltersWithoutName = {
        status: filter.status,
        designers: filter.designers,
      }
      if (isEqual(newFilters, savedFiltersWithoutName)) {
        acc = true
        alert(`Duplicate filter found in filter: ${filter.name}`)
      }

      return acc
    }, false)

    if (hasDuplicated) {
      return
    }

    onSaveFilter(formData)
    setFormData(defaultFormData)
  }

  const toggleDrawerVisibility = () => {
    if (designerOpen) {
      setDesignerOpen(!designerOpen)
    }
    setCollapseOpen(!collapseOpen)
  }

  const handleDesignerClick = () => {
    if (collapseOpen) {
      setCollapseOpen(!collapseOpen)
    }
    setDesignerOpen(!designerOpen)
  }

  const selectedStatusCount = formData.status.length
  const selectedDesignerCount = formData.designers.length

  const hasSetFilterName = formData.name.length > 0
  const isEnabled =
    hasSetFilterName && (selectedStatusCount > 0 || selectedDesignerCount > 0)

  return (
    <>
      <div className={classes.drawerHeader}>
        <IconButton onClick={onDrawerClose}>
          {theme.direction === 'rtl' ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <form onSubmit={handleOnSubmit} autoComplete="off">
        <ListItem button onClick={toggleDrawerVisibility}>
          <ListItemText
            primary={`Status (${selectedStatusCount}/${statuses.length})`}
          />
          {collapseOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {statuses.length === 0 ? (
              <ListItem button disabled>
                <ListItemText
                  primary="No statuses found"
                  style={{ wordBreak: 'break-all' }}
                />
              </ListItem>
            ) : null}
            {statuses.map((status) => {
              const filterId = `checkbox-list-status-label-${status.id}`

              const foundStatus = formData.status.find(
                (s) => s.id === status.id
              )

              return (
                <ListItem button key={status.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        tabIndex={-1}
                        checked={!!foundStatus}
                        inputProps={{ 'aria-labelledby': filterId }}
                        onChange={() => {
                          setFormData((prevFormData) => {
                            if (foundStatus) {
                              return {
                                ...prevFormData,
                                status: prevFormData.status.filter(
                                  (s) => s.id !== status.id
                                ),
                              }
                            }

                            prevFormData.status.push({
                              ...status,
                              id: status.id.toString(),
                            })

                            return {
                              ...prevFormData,
                            }
                          })
                        }}
                      />
                    }
                    label={status.name}
                    style={{ wordBreak: 'break-all' }}
                  />
                </ListItem>
              )
            })}
          </List>
        </Collapse>
        <Divider />
        <ListItem button onClick={handleDesignerClick}>
          <ListItemText
            primary={`Designers (${selectedDesignerCount}/${designers.length})`}
          />
          {designerOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={designerOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {designers.length === 0 ? (
              <ListItem button disabled>
                <ListItemText
                  primary="No designers found"
                  style={{ wordBreak: 'break-all' }}
                />
              </ListItem>
            ) : null}
            {designers.map((designer) => {
              const filterId = `checkbox-list-designer-label-${designer.id}`
              const foundDesigner = formData.designers.find(
                (s) => s.id === designer.id
              )
              return (
                <ListItem button key={designer.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        tabIndex={-1}
                        checked={!!foundDesigner}
                        inputProps={{ 'aria-labelledby': filterId }}
                        onChange={() => {
                          setFormData((prevFormData) => {
                            if (foundDesigner) {
                              return {
                                ...prevFormData,
                                status: prevFormData.designers.filter(
                                  (s) => s.id !== designer.id
                                ),
                              }
                            }

                            prevFormData.designers.push(designer)

                            return {
                              ...prevFormData,
                            }
                          })
                        }}
                      />
                    }
                    label={designer.name}
                    style={{ wordBreak: 'break-all' }}
                  />
                </ListItem>
              )
            })}
          </List>
        </Collapse>
        <Divider />
        <Box p={2}>
          <TextField
            onChange={(event) =>
              setFormData((prevState) => ({
                ...prevState,
                name: event.target.value,
              }))
            }
            label="Filter name"
            variant="outlined"
            name="name"
            style={{ width: '100%' }}
            value={formData.name}
          />

          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<SaveIcon />}
            disabled={!isEnabled}
          >
            Save
          </Button>
        </Box>
      </form>
    </>
  )
}
