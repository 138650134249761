import { EBatchStatus, IBatch } from '@kartdavid/corkscrew-types/internal'
import { useEffect, useState } from 'react'
import { apiService } from '../service/api'

const useBatchStatus = (batchId?: string | null) => {
  const [batchInfo, setBatchInfo] = useState<IBatch | undefined>()
  const [batchLoading, setLoading] = useState(true)

  useEffect(() => {
    console.log('useBatchStatus', batchId)

    if (!batchId || batchId === '') {
      setBatchInfo(undefined)
      return
    }

    apiService
      .getBatchInfo(batchId)
      .then(setBatchInfo)
      .finally(() => {
        setLoading(false)
      })
  }, [batchId])

  useEffect(() => {
    const cancel = setInterval(() => {
      if (!batchId || batchId === '') {
        clearInterval(cancel)
        return
      }

      apiService.getBatchInfo(batchId).then((batchInfo) => {
        setBatchInfo(batchInfo)

        if (
          batchInfo?.status === EBatchStatus.BatchStatusCompleted ||
          batchInfo?.status === EBatchStatus.BatchStatusError
        ) {
          clearInterval(cancel)
          return
        }
      })
    }, 1000)

    return () => {
      clearInterval(cancel)
    }
  }, [batchId])

  return { batchInfo, batchLoading }
}

export default useBatchStatus
