import {
  MANUFACTURING,
  ORDERS,
  PROOFS,
  SHIPPING,
  STEP_MANUFACTURING,
  STEP_ORDER,
  STEP_PROOF,
  STEP_SHIPPING,
} from '../constants'
import { IStep } from '../types'

export function getProperty<T, K extends keyof T>(obj: T, key: K) {
  return obj[key]
}

export const currentStepFromPathname = (pathname: string): IStep => {
  switch (pathname) {
    case PROOFS:
      return STEP_PROOF
    case ORDERS:
      return STEP_ORDER
    case SHIPPING:
      return STEP_SHIPPING
    case MANUFACTURING:
      return STEP_MANUFACTURING
    default:
      return STEP_PROOF
  }
}
