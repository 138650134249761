import React, {
  Fragment,
  FunctionComponent,
  Suspense,
  useEffect,
  useRef,
  useState,
} from 'react'

import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  createStyles,
  Grid,
  Grow,
  Link,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
  Typography,
} from '@material-ui/core'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CancelIcon from '@mui/icons-material/Cancel'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { IOrderLineItemHistory } from '@kartdavid/corkscrew-types/internal'
import { apiService } from '../../service/api'
import { getNicenameFromStatus } from '../../utils/orders'
import HistoryItem from '../HistoryItem'
import HistoryItemProofInfo from './HistoryItemProofInfo'
import { config } from '../../config'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridGap: theme.spacing(2),
      gridTemplateColumns: `repeat(auto-fit, minmax(calc((100%) / 5), 1fr))`,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    boldText: {
      fontWeight: 600,
    },
    imgWrapper: {
      width: 200,
      backgroundColor: theme.palette.grey[300],
    },
  })
)

export enum EButtonAction {
  UploadCustomerArtwork,
  CreateProof,
  CreateDraftProof,
  SplitOrderLine,
  StartManufacturing,
  StartPrinting,
  MarkPrinted,
  StartCutting,
  MarkCut,
  ReadyToShip,
  RequestNewArtwork,
  SendProofs,
  SendToQA,
  Reprint,
  RejectDesign,
  deleteOrderLineItem,
  GetProofLink,

  // Order Actions
  AddDesign,
}

interface IOption {
  name: string
  action: EButtonAction
  visible: boolean
}

interface IProps {
  orderNumber: string
  orderLineNumber: string
  id: string
  status: string
  imgUrl: string
  comment: string
  modifiers: any[]
  productName: string
  onClick: (action: EButtonAction) => void
  historyUrl?: string
  historyOpenByDefault?: boolean
  options: IOption[]
  activeProofId?: null | string
}

export const OrderLineItem: FunctionComponent<IProps> = ({
  orderNumber,
  orderLineNumber,
  status,
  imgUrl,
  comment,
  modifiers,
  productName,
  onClick,
  historyUrl,
  options,
  historyOpenByDefault,
}: IProps) => {
  const classes = useStyles()
  const [isHistoryOpen, toggleHistory] = useState(historyOpenByDefault)
  const [history, setHistory] = useState<IOrderLineItemHistory[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isProofImageLoaded, setProofImageLoaded] = useState(false)
  const isImagePresent = imgUrl && imgUrl.length > 0
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  useEffect(() => {
    if (isHistoryOpen && historyUrl) {
      fetchHistory(historyUrl)
    }
  }, [isHistoryOpen, historyUrl, status])

  const fetchHistory = async (historyUrl: string) => {
    setIsLoading(true)

    try {
      const historyLines = await apiService.getOrderLineHistory(historyUrl)
      setHistory(historyLines)
    } catch (error) {
      setHistory([])
      console.error(error)
    }

    setIsLoading(false)
  }

  const visibleOpts = options.filter((opt) => opt.visible)
  const firstAvailable = visibleOpts.find((opt) => opt.visible)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: any) => {
    if (
      anchorRef &&
      anchorRef.current &&
      (anchorRef.current as any).contains(event.target)
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item sm>
          <Typography className={classes.boldText}>Proof</Typography>
          <Box mt={2} className={classes.imgWrapper}>
            {isImagePresent ? (
              <Link
                target="_blank"
                rel="noopener"
                href={`${config.REACT_APP_API_URL}${imgUrl}`}
              >
                <img
                  src={`${config.REACT_APP_API_URL}${imgUrl}`}
                  width="200px"
                  onLoad={() => setProofImageLoaded(true)}
                  style={{ display: isProofImageLoaded ? 'block' : 'none' }}
                />
              </Link>
            ) : (
              <img
                src="https://placehold.co/200x200"
                width="200px"
                onLoad={() => setProofImageLoaded(true)}
              />
            )}
          </Box>
        </Grid>
        <Grid item sm>
          <Typography className={classes.boldText}>Description</Typography>
          <Box mt={2}>
            <Typography variant="h6">{productName}</Typography>
            <Box>
              {modifiers.map((modifier, index) => {
                return (
                  <Box key={index}>
                    <Typography component="span" className={classes.boldText}>
                      {modifier.title}
                    </Typography>
                    : <Typography component="span">{modifier.value}</Typography>
                  </Box>
                )
              })}
              <Box mt={2}>
                <Typography component="span" className={classes.boldText}>
                  Design Code
                </Typography>
                :{' '}
                <Typography component="span">
                  <i>
                    {orderNumber}-{orderLineNumber}
                  </i>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item sm>
          <Typography className={classes.boldText}>Status</Typography>
          <Box mt={2} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography>
              {status === 'StatusProofUserReviewRejected'
                ? 'Changes needed'
                : getNicenameFromStatus(status)}
            </Typography>
            {status === 'StatusProofUserReviewRejected' ? (
              <Box ml={1}>
                <CancelIcon color="secondary" />
              </Box>
            ) : null}
          </Box>
        </Grid>
        <Grid item sm>
          <Typography className={classes.boldText}>Comments</Typography>
          <Box mt={2}>
            <Typography>{comment}</Typography>
          </Box>
        </Grid>
        <Grid item sm>
          <Typography className={classes.boldText}>Next action</Typography>

          {firstAvailable ? (
            <Fragment>
              <ButtonGroup
                variant="contained"
                color="primary"
                ref={anchorRef}
                aria-label="split button"
              >
                <Button
                  data-testid="upload-proof-btn"
                  onClick={() => onClick(firstAvailable.action)}
                >
                  {firstAvailable.name}
                </Button>
                {visibleOpts.length > 1 ? (
                  <Button
                    color="primary"
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                ) : null}
              </ButtonGroup>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu">
                          {visibleOpts.map((option, index) => (
                            <MenuItem
                              key={option.action}
                              onClick={() => {
                                onClick(visibleOpts[index].action)
                                setOpen(false)
                              }}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Fragment>
          ) : null}
        </Grid>
      </Grid>
      <Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            onClick={() => {
              toggleHistory(!isHistoryOpen)
            }}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Typography>Design history</Typography>
            {isHistoryOpen ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </Box>
        </Box>
        <Box style={{ maxWidth: '90%', margin: '0 auto' }}>
          {isHistoryOpen ? (
            <Box>
              <Typography>History</Typography>
              <Box>
                {isLoading ? 'Loading history' : null}
                {!isLoading && history.length === 0 ? (
                  <Box>No history items found</Box>
                ) : null}
                {!isLoading && (
                  <Suspense fallback={<div>Loading...</div>}>
                    {history
                      .sort((a, b) => {
                        if (!a.createdAt || !b.createdAt) {
                          return 0
                        }

                        if (a.createdAt < b.createdAt) {
                          return 1
                        }
                        if (a.createdAt > b.createdAt) {
                          return -1
                        }
                        return 0
                      })
                      .map((historyItem: IOrderLineItemHistory) => {
                        return (
                          <HistoryItem
                            orderNumber={orderNumber}
                            lineItemNumber={orderLineNumber}
                            key={historyItem.id}
                            historyItem={historyItem}
                            apiUrl={config.REACT_APP_API_URL}
                            usage="internal"
                          >
                            {historyItem.proofId ? (
                              <HistoryItemProofInfo
                                proofId={historyItem.proofId}
                              />
                            ) : null}

                            {historyItem.changeType === 'print_rejected' ? (
                              <>
                                <Paper style={{ padding: 8 }}>
                                  {historyItem.instructions.replace(
                                    '\n',
                                    ' | '
                                  )}
                                </Paper>
                              </>
                            ) : null}
                          </HistoryItem>
                        )
                      })}
                  </Suspense>
                )}
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}
