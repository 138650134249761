import Bugsnag from '@bugsnag/js'
import {
  Action,
  combineReducers,
  configureStore,
  getDefaultMiddleware,
  isRejected,
  isRejectedWithValue,
  Middleware,
  ThunkAction,
} from '@reduxjs/toolkit'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import appReducer from './app'
import authReducer from './auth'
import uiReducer from './ui'

export const history = createBrowserHistory()

export const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  ui: uiReducer,
  router: connectRouter(history),
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const bugsnagMiddleware: Middleware<{}, RootState> = () => (next) => (
  action
) => {
  if (isRejectedWithValue(action)) {
    Bugsnag.notify(action.payload)
  } else if (isRejected(action)) {
    Bugsnag.notify(action.error)
  }
  return next(action)
}

const middleware = [
  bugsnagMiddleware,
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
] as const

export const store = configureStore({
  reducer: persistedReducer,
  middleware,
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof rootReducer>
// export type Thunk = ThunkAction<void, RootState, null, Action<string>>
export type Thunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export type AppDispatch = typeof store.dispatch
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
