import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import React, { FunctionComponent, Suspense, useEffect, useState } from 'react'
import { ICustomer } from '@kartdavid/corkscrew-types/internal'
import { apiService } from '../service/api'
import { useHistory, useLocation } from 'react-router'
import CircularProgress from '@mui/material/CircularProgress'
import uniq from 'lodash/uniq'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import { alpha } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { CUSTOMERS_ADD, ROUTE_CUSTOMER_DETAILS } from '../constants'
import TextField from '@mui/material/TextField'

interface EnhancedTableToolbarProps {
  numSelected: number
  searchValue: string
  onChange: (s: string) => void
}

const EnhancedTableToolbar: FunctionComponent<EnhancedTableToolbarProps> = (
  props: EnhancedTableToolbarProps
) => {
  const { numSelected } = props

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Customers
        </Typography>
      )}
      {/* {numSelected > 0 ? (
          <span></span>
        // <Tooltip title="Delete">
        //   <IconButton>
        //     <DeleteIcon />
        //   </IconButton>
        // </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
      <TextField
        value={props.searchValue}
        onChange={(ev) => {
          props.onChange(ev.target.value)
        }}
        placeholder="Search"
      />
    </Toolbar>
  )
}

interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  numeric: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'First Name',
    numeric: false,
    disablePadding: false,
    label: 'First Name',
  },
  {
    id: 'Last Name',
    numeric: false,
    disablePadding: false,
    label: 'Last Name',
  },
  {
    id: 'Company',
    numeric: false,
    disablePadding: false,
    label: 'Company',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'Phone',
    numeric: false,
    disablePadding: false,
    label: 'Phone',
  },
  {
    id: 'edit',
    numeric: false,
    disablePadding: false,
    label: 'Edit',
  },
]

interface EnhancedTableProps {
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowCount: number
  headCells?: HeadCell[]
}

const EnhancedTableHead: FunctionComponent<EnhancedTableProps> = (
  props: EnhancedTableProps
) => {
  // const { onSelectAllClick, numSelected, rowCount } = props

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox"> */}
        {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          /> */}
        {/* </TableCell> */}
        {(props.headCells || headCells).map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}

            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const CustomerListView: FunctionComponent = () => {
  const history = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const [page, setPage] = useState(parseInt(params.get('page') || '0'))
  const rowsPerPage = parseInt(params.get('perPage') || '50')
  const [orderLines, setOrderLines] = useState<ICustomer[]>([])
  // FILTERS
  // const [checkedOrderLines, setCheckedOrderLines] = useState<ICustomer[]>([])
  const [hasMore, setHasMore] = useState(false)
  const [searchValue, setSearchValue] = useState(params.get('q') || '')
  // const abortController = useRef(new AbortController())

  useEffect(() => {
    refreshOrders()
  }, [page, rowsPerPage, searchValue])

  const refreshOrders = () => {
    apiService.listCustomers(page, rowsPerPage, searchValue).then((resp) => {
      if (page > 0) {
        setPage(0)
      }
      setOrderLines(resp.data)
      setHasMore(resp.meta?.pagination?.hasMore || false)
    })
  }

  return (
    <Suspense fallback={<CircularProgress />}>
      <Container maxWidth={false}>
        <Button component={Link} to={CUSTOMERS_ADD}>
          Add Customer
        </Button>
        <Box>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <EnhancedTableToolbar
              numSelected={0}
              searchValue={searchValue}
              onChange={setSearchValue}
            />

            <TableContainer>
              <Table>
                <EnhancedTableHead
                  numSelected={0}
                  onSelectAllClick={() => {
                    console.log('loaded')
                  }}
                  rowCount={orderLines.length}
                  headCells={undefined}
                />
                <TableBody>
                  {orderLines.map((customer) => {
                    return (
                      <TableRow key={customer.id}>
                        <TableCell>
                          <code>{customer.id}</code>
                        </TableCell>
                        <TableCell>{customer.firstName}</TableCell>
                        <TableCell>{customer.lastName}</TableCell>
                        <TableCell>{customer.companyName}</TableCell>
                        <TableCell>{customer.email}</TableCell>
                        <TableCell>{customer.phone}</TableCell>
                        <TableCell>
                          <Button
                            component={Link}
                            to={ROUTE_CUSTOMER_DETAILS.replace(
                              ':id',
                              customer.id
                            )}
                          >
                            View / Edit
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>

              <TablePagination
                rowsPerPageOptions={uniq([rowsPerPage, 50, 100, 250]).sort(
                  (a, b) => a - b
                )}
                component="div"
                count={
                  page === 0 && !hasMore
                    ? orderLines.length
                    : (page + 1) * rowsPerPage + (hasMore ? 1 : 0)
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event: unknown, newPage) => {
                  const newParams = new URLSearchParams(params.toString())
                  newParams.set('page', newPage.toString())
                  history.push({
                    search: newParams.toString(),
                  })
                }}
                onRowsPerPageChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ) => {
                  const newParams = new URLSearchParams(params.toString())
                  newParams.set('page', '0')
                  newParams.set('perPage', event.target.value)
                  history.replace({
                    search: newParams.toString(),
                  })
                }}
              />
            </TableContainer>
          </Paper>{' '}
        </Box>
      </Container>
    </Suspense>
  )
}

export default CustomerListView
