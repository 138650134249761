import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { FunctionComponent } from 'react'
import { IFile, IFilePurpose } from '@kartdavid/corkscrew-types/internal'
import { purposeFileType, purposeName } from './filenaming'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      lineHeight: 2,
      marginBottom: theme.spacing(1),
    },
    fileNumber: {
      fontFamily: 'monospace',
      backgroundColor: theme.colors.greenLight,
      padding: theme.spacing(0.5),
      marginRight: theme.spacing(1),
      borderRadius: 5,
    },
    fileName: {
      marginRight: theme.spacing(2),
    },
    purposeName: {
      marginRight: theme.spacing(2),
    },
    thumbnailImage: {
      width: 80,
      height: 80,
    },
    withBottomMargin: {
      marginBottom: theme.spacing(2),
    },
  })
)

interface FileLinkProps {
  orderNumber: string
  lineItemNumber: string
  file: IFile
  downloadAs: IFilePurpose
  apiUrl: string
  usage: 'internal' | 'public'
}

export const FileLink: FunctionComponent<FileLinkProps> = ({
  orderNumber,
  lineItemNumber,
  file,
  downloadAs,
  apiUrl,
  usage,
}: FileLinkProps) => {
  const classes = useStyles()
  const fileType = purposeFileType(downloadAs)

  const imgParams = new URLSearchParams()
  imgParams.set('download', 'true')
  imgParams.set(
    'filename',
    `${orderNumber}-${lineItemNumber}-${fileType}-${file.fileNumber}${file.extension}`
  )

  const downloadImageURL = apiUrl + file.path + `&${imgParams}`
  const imageURL = apiUrl + file.path
  const canPreview = ['jpg', 'jpeg', 'png', 'gif', 'webp'].some((ext) =>
    file.extension.includes(ext)
  )

  return (
    <div className={classes.listItem}>
      {usage === 'internal' ? (
        <Box className={classes.withBottomMargin}>
          <Box component="span" className={classes.fileNumber}>
            {file.fileNumber}
          </Box>
          <a target="_blank" rel="noreferrer" href={downloadImageURL}>
            <Box component="span" className={classes.purposeName}>
              {purposeName(downloadAs)}.
            </Box>
          </a>
        </Box>
      ) : null}
      {canPreview ? (
        <Box>
          <img
            className={classes.thumbnailImage}
            src={imageURL}
            alt={purposeName(downloadAs)}
          />
        </Box>
      ) : null}
    </div>
  )
}
