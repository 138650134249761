import React, { useRef, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { jsPDF } from 'jspdf'
import 'svg2pdf.js'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

export const ConvertPage = () => {
  const doc = new jsPDF()

  const svgRef = useRef<HTMLDivElement>(null)
  const [outputUrl, setOutputUrl] = useState('')

  return (
    <>
      <Paper style={{ padding: 16 }}>
        <Typography variant="h3">Instructions</Typography>
        <List>
          <ListItem>
            1. Go into Zund Cut Center and open the order `zcc` file
          </ListItem>
          <ListItem>2. Export the file as a SVG</ListItem>
          <ListItem>
            3. Click on Browse below and upload the SVG file you just exported
          </ListItem>
          <ListItem>
            4. The file will automatically convert and download to your
            Downloads.
          </ListItem>
          <ListItem>
            5. Save it from Downloads to the W:\ drive (the other cutter&apos;s
            hot folder)
          </ListItem>
        </List>
      </Paper>
      <Paper style={{ padding: 16 }}>
        <Typography variant="h3">Upload</Typography>
        <div style={{ display: 'none' }} ref={svgRef}></div>
        <input
          type="file"
          multiple
          onChange={(ev) => {
            console.log(ev.target.files)

            if (!ev.target.files || ev.target.files.length === 0) {
              return
            }

            Array.from(ev.target.files).forEach((file) => {
              const svgUrl = URL.createObjectURL(file)
              const loaded = doc.loadFile(svgUrl)

              if (!svgRef.current) {
                return
              }

              svgRef.current.innerHTML = loaded

              const svgElement = svgRef.current.firstElementChild

              if (!svgElement) {
                return
              }

              svgElement.getBoundingClientRect() // force layout calculation
              // const width = (svgElement as any).width.baseVal.value
              // const height = (svgElement as any).height.baseVal.value

              const width = parseFloat(svgElement.getAttribute('width') || '0')
              const height = parseFloat(
                svgElement.getAttribute('height') || '0'
              )

              const pdf = new jsPDF(width > height ? 'l' : 'p', 'mm', [
                width,
                height,
              ])

              pdf.svg(svgElement, { width, height }).then((pdf) => {
                setOutputUrl(pdf.output('datauristring'))

                const element = document.createElement('a')

                element.setAttribute('href', pdf.output('datauristring'))
                element.setAttribute(
                  'download',
                  file.name
                    .replace('.svg', '.pdf')
                    .replace('.zcc', '.pdf')
                    .replace('.pdf.pdf', '.pdf')
                )

                element.style.display = 'none'
                document.body.appendChild(element)

                element.click()

                document.body.removeChild(element)
              })
            })

            ev.currentTarget.value = ''

            // ev.target.files[0].text().then((txt) => {
            // const blob  = new Blob([txt], { type: 'image/svg+xml' })
            //
            // })
          }}
        />
      </Paper>
      <Paper style={{ padding: 16 }}>
        <Typography variant="h3">Output</Typography>

        <iframe src={outputUrl} width="50%" />
      </Paper>
    </>
  )
}

export default ConvertPage
