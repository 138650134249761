import { format, utcToZonedTime } from 'date-fns-tz'
import { isValid } from 'date-fns'
import { DATE_FORMAT } from '../constants'
import humanizeDuration from 'humanize-duration'

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    },
  },
})

export const formatUtcDate = (
  date: Date | undefined | string,
  formatStr: string = DATE_FORMAT
): string => {
  if (!date) {
    return '-'
  }

  if (!isValid(new Date(date))) {
    return '-'
  }

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return format(utcToZonedTime(date, timeZone), formatStr).trim()
}

export const formatDuration = (secs: number): string => {
  return shortEnglishHumanizer(secs * 1000, {
    spacer: '',
    round: true,
    conjunction: '',
    delimiter: '',
    units: ['h', 'm'],
  })
}
