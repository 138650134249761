import { IFilePurpose } from '@kartdavid/corkscrew-types/internal'

export const purposeFileType = (downloadAs: IFilePurpose) => {
  switch (downloadAs) {
    case IFilePurpose.PurposeCustomerArtwork:
      return 'A'
    case IFilePurpose.PurposeCutFile:
      return 'C'
    case IFilePurpose.PurposeDesignWorkingFile:
      return 'D'
    case IFilePurpose.PurposePrintReadyArtwork:
      return 'R'
    case IFilePurpose.PurposeProof:
      return 'P'
    case IFilePurpose.PurposeProductSpecification:
      return 'S'
  }

  return 'U'
}

export const purposeName = (downloadAs: IFilePurpose) => {
  switch (downloadAs) {
    case IFilePurpose.PurposeCustomerArtwork:
      return 'Customer Artwork'
    case IFilePurpose.PurposeCutFile:
      return 'Cut File'
    case IFilePurpose.PurposeDesignWorkingFile:
      return 'Design Working File'
    case IFilePurpose.PurposePrintReadyArtwork:
      return 'Print Ready Artwork'
    case IFilePurpose.PurposeProof:
      return 'Proof Bitmap'
    case IFilePurpose.PurposeProductSpecification:
      return 'Product Specification'
    case IFilePurpose.PurposeTiliaReadyArtwork:
      return 'Tilia Ready Artwork'
  }

  return IFilePurpose[downloadAs]
}
