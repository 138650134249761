import { RootState } from '..'

export const getAuthState = (state: RootState) => {
  return state.auth
}

export const getIsAuthenticated = (state: RootState) => {
  const expiresAt = state.auth.expiresAt
  if (!expiresAt) {
    return false
  }

  // Check whether the current time is past the
  // access token's expiry time
  return expiresAt > new Date().getTime()
}
