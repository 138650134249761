// Routes
export const ACCOUNT = '/account'
export const CALLBACK = '/callback'
export const PROOFS = '/proofs'
export const PROOFS_DETAIL = '/proofs/:orderNumber'
export const ORDERS = '/orders'
export const CUSTOMERS = '/customers'
export const CUSTOMERS_ADD = '/customers/add'
export const SHIPPING = '/shipping'
export const MANUFACTURING = '/manufacturing'
export const ROUTE_QA = '/manufacturing/:designCode/qa'
export const ROUTE_CUSTOMER_DETAILS = '/customers/:id'
// Steps
export const STEP_PROOF = 'proof'
export const STEP_ORDER = 'order'
export const STEP_MANUFACTURING = 'manufacturing'
export const STEP_SHIPPING = 'shipping'

// Date formats
export const DATE_FORMAT = 'yyyy-MM-dd'
export const NICE_DATE_FORMAT = 'EEE, do MMM yyyy '
export const TIME_FORMAT = 'p '

// Progress statuses
export const ORDER_PLACED = 'OrderPlaced'
export const DESIGN_IN_PROGRESS = 'DesignInProgress'
export const FEEDBACK_REQUIRED = 'FeedbackRequired'
export const PRINTING = 'Printing'
export const CUTTING = 'Cutting'
export const QUALITY_CONTROL = 'QualityControl'

// Pagination
export const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100, 250]
