import { createSlice } from '@reduxjs/toolkit'

interface IInitialState {
  isFiltersDrawerOpen: boolean
}

const initialState: IInitialState = {
  isFiltersDrawerOpen: false,
}

const uiReducer = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setFiltersDrawerOpen: (state) => {
      state.isFiltersDrawerOpen = true
    },
    setFiltersDrawerClose: (state) => {
      state.isFiltersDrawerOpen = false
    },
  },
})

export default uiReducer.reducer

// Actions
export const { setFiltersDrawerOpen, setFiltersDrawerClose } = uiReducer.actions
