import React from 'react'
import { IMaterial, IOrderLineItem } from '@kartdavid/corkscrew-types/internal'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { getNicenameFromStatus } from '../../utils/orders'
import ShipDeadline from './ShipDeadline'
import Checkbox from '@material-ui/core/Checkbox'
import { NavLink } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { ROUTE_QA } from '../../constants'

interface IQARowProps {
  row: IOrderLineItem
  materials: IMaterial[]
  onClick: (event: React.MouseEvent<unknown>, id: string) => void
}

const QARow: React.FC<IQARowProps> = (props: IQARowProps) => {
  const { row, materials, onClick } = props

  const isItemSelected = false

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          disabled
          checked={isItemSelected}
          inputProps={{
            'aria-labelledby': `checkbox-${row.id}`,
          }}
          onClick={(event) => onClick(event, row.id)}
        />
      </TableCell>
      <TableCell component="th" id={`checkbox-${row.id}`} scope="row">
        {getNicenameFromStatus(row.status)}
      </TableCell>
      <TableCell align="left">{row.designCode}</TableCell>
      <TableCell align="left">-</TableCell>
      <TableCell align="left">-</TableCell>
      <TableCell align="left">
        {materials.find((m) => m.sku === row.materialSku)?.name ||
          row.materialSku}
      </TableCell>
      <TableCell>{row.quantity}</TableCell>
      <TableCell align="left">
        <ShipDeadline
          deadline={(row.deadlineShipDate ?? ('-' as unknown)) as string}
        />
      </TableCell>
      <TableCell align="left">
        <NavLink to={`${ROUTE_QA.replace(':designCode', row.designCode)}`}>
          <ChevronRightIcon />
        </NavLink>
      </TableCell>
    </TableRow>
  )
}

export default QARow
