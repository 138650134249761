import React, { FunctionComponent, ReactNode } from 'react'

import { Typography, Box } from '@material-ui/core'

import { IOrderLineItemHistory } from '@kartdavid/corkscrew-types/internal'
import MessageItem from '../MessageItem'
import DetailedMessageItem from './changeType/DetailedMessageItem'
import MessageItemWithFile from './changeType/MessageItemWithFile '
import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  instructions: {
    fontSize: theme.typography.pxToRem(18),
    [theme.breakpoints.down('xs')]: {
      width: '250px',
    },
  },
}))

export interface IHistoryProps {
  orderNumber: string
  lineItemNumber: string
  historyItem: IOrderLineItemHistory
  apiUrl: string
  usage: 'internal' | 'public'
  children?: ReactNode
}

interface IProofing {
  historyItem: IOrderLineItemHistory
}

export const ProofAcceptedOrRejected: React.FunctionComponent<IProofing> = ({
  historyItem,
}: IProofing) => {
  const classes = useStyles()
  return (
    <Box>
      <div className={classes.instructions}>
        {historyItem.instructions.length > 0 ? (
          <Typography className={classes.instructions}>
            {historyItem.instructions}
          </Typography>
        ) : null}
      </div>
    </Box>
  )
}

export const RequestStatus: React.FunctionComponent<IProofing> = ({
  historyItem,
}: IProofing) => {
  const classes = useStyles()
  return (
    <Box className={classes.instructions}>
      {historyItem.instructions.length > 0 ? (
        <Typography className={classes.instructions}>
          {historyItem.instructions}
        </Typography>
      ) : null}
    </Box>
  )
}

export const HistoryItem: FunctionComponent<IHistoryProps> = ({
  orderNumber,
  lineItemNumber,
  historyItem,
  apiUrl,
  usage,
  children,
}: IHistoryProps) => {
  return (
    <MessageItem
      timestamp={historyItem.createdAt}
      createdByName={historyItem.createdByName}
      subtitle={historyItem.changeType}
      avatarUrl={historyItem.createdByAvatarUrl}
      usage={usage}
      files={historyItem.files}
    >
      {['qa_rejected', 'qa_accepted'].includes(historyItem.changeType) ? (
        <DetailedMessageItem
          historyItem={historyItem}
          apiUrl={apiUrl}
          lineItemNumber={lineItemNumber}
          orderNumber={orderNumber}
          usage={usage}
          showProductInfo={false}
        />
      ) : null}
      {['created', 'updated', 'uploaded'].includes(historyItem.changeType) ||
      (historyItem.changeType === 'proof_rejected' && usage === 'internal') ? (
        <DetailedMessageItem
          historyItem={historyItem}
          apiUrl={apiUrl}
          lineItemNumber={lineItemNumber}
          orderNumber={orderNumber}
          usage={usage}
          showProductInfo={true}
        />
      ) : null}
      {historyItem.changeType === 'proof_accepted' ||
      (historyItem.changeType === 'proof_rejected' && usage === 'public') ? (
        <ProofAcceptedOrRejected historyItem={historyItem} />
      ) : null}

      {historyItem.changeType === 'customer-artwork-added' ? (
        <MessageItemWithFile
          historyItem={historyItem}
          apiUrl={apiUrl}
          lineItemNumber={lineItemNumber}
          orderNumber={orderNumber}
          usage={usage}
        />
      ) : null}
      {historyItem.changeType === 'request_new_artwork' ? (
        <RequestStatus historyItem={historyItem} />
      ) : null}
      {historyItem.changeType === 'request_user_proof_approval' ? (
        <RequestStatus historyItem={historyItem} />
      ) : null}
      {children}
    </MessageItem>
  )
}

export default HistoryItem
