import { makeStyles, Theme } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { IOrderLineItemHistory } from '@kartdavid/corkscrew-types/internal'
import FileLink from '../../FileLink'

const useStyles = makeStyles((theme: Theme) => ({
  list: {},
  fileLinkWrapper: {
    marginBottom: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '250px',
    },
  },
  regularFont: {
    fontSize: theme.typography.pxToRem(18),
  },
}))

interface IProps {
  historyItem: IOrderLineItemHistory
  orderNumber: string
  lineItemNumber: string
  apiUrl: string
  usage: 'internal' | 'public'
}

const MessageItemWithFile: React.FunctionComponent<IProps> = ({
  historyItem,
  apiUrl,
  orderNumber,
  lineItemNumber,
  usage,
}: IProps) => {
  const classes = useStyles()
  return (
    <div className={classes.list}>
      {historyItem.files &&
        historyItem.files.map((file) => (
          <div key={file.id} className={classes.fileLinkWrapper}>
            <FileLink
              apiUrl={apiUrl}
              key={file.id}
              orderNumber={orderNumber}
              lineItemNumber={lineItemNumber}
              file={file}
              downloadAs={file.purpose[0]}
              usage={usage}
            />
          </div>
        ))}
      <div className={classes.instructions}>
        {historyItem.instructions.length > 0 ? (
          <Typography className={classes.regularFont}>
            {historyItem.instructions}
          </Typography>
        ) : null}
      </div>
    </div>
  )
}

export default MessageItemWithFile
