import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import auth0 from 'auth0-js'
import { config } from '../../config'
import { IAccountUser } from '../../types'
import { fetchUser } from './thunks'

export const isBrowser = typeof window !== 'undefined'

export const auth = new auth0.WebAuth({
  domain: config.REACT_APP_AUTH0_DOMAIN || '',
  clientID: config.REACT_APP_AUTH0_CLIENT_ID || '',
  redirectUri: config.REACT_APP_AUTH0_CALLBACK || '',
  responseType: 'token id_token',
  scope: 'openid profile email proof:manage another',
  audience: config.REACT_APP_AUTH0_AUDIENCE,
})

export enum EPermissions {
  OrderManage = 'order:manage',
  OrderDelete = 'order:delete',
  ManufacturingManage = 'manufacturing:manage',
  ShippingManage = 'shipping:manage',
  ProofManage = 'proof:manage',
}

interface IInitialState {
  isAuthenticated: boolean
  isLoading: boolean
  user: IAccountUser | null
  accessToken: string | null
  idToken: string | null
  expiresAt: number | null
}

const initialState: IInitialState = {
  isAuthenticated: false,
  isLoading: true,
  user: null,
  accessToken: null,
  idToken: null,
  expiresAt: null,
}

const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.isLoading = true
    },
    setLogin: (state, action: PayloadAction<any>) => {
      state.isAuthenticated = true
      state.isLoading = false
      state.accessToken = action.payload.accessToken
      state.idToken = action.payload.idToken
      state.expiresAt = action.payload.expiresAt
      state.user = action.payload.user
    },
    setLogout: (state) => {
      state.isAuthenticated = false
      state.isLoading = false
      state.accessToken = null
      state.idToken = null
      state.expiresAt = null
      state.user = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      if (state.user) {
        state.user = {
          ...state.user,
          id: action.payload?.id || '',
          permissions: action.payload?.permissions || [],
        }
      }
    })
  },
})

export default authReducer.reducer

// Actions
export const { setLogin, setLogout, loginRequest } = authReducer.actions
