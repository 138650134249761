import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { HeadCell, IProofsProps } from '../../types'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { getNicenameFromStatus } from '../../utils/orders'
import Checkbox from '@material-ui/core/Checkbox'
import { NavLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'

export const defaultHeadCells: HeadCell[] = [
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
  },
  {
    id: 'orderNumber',
    numeric: false,
    disablePadding: false,
    label: 'Order number',
  },
  {
    id: 'storeName',
    numeric: false,
    disablePadding: false,
    label: 'Store name',
  },
  // { id: 'taskName', numeric: false, disablePadding: false, label: 'Task name' },
  { id: 'customer', numeric: false, disablePadding: false, label: 'Customer' },
  { id: 'shipDate', numeric: false, disablePadding: false, label: 'Ship date' },
  { id: 'designer', numeric: false, disablePadding: false, label: 'Designer' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
]

export const defaultRender = (
  row: IProofsProps,
  isItemSelected: boolean,
  handleClick: (event: React.MouseEvent<unknown>, id: string) => void,
  onAssignToMe: (orderNumber: string) => void
) => {
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          disabled
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': `checkbox-${row.id}` }}
          onClick={(event) => handleClick(event, row.id)}
        />
      </TableCell>
      <TableCell
        component="th"
        id={`checkbox-${row.id}`}
        scope="row"
        padding="none"
      >
        {getNicenameFromStatus(row.status)}
      </TableCell>
      <TableCell align="left">{row.orderNumber}</TableCell>
      <TableCell align="left">{row.storeName}</TableCell>
      {/* <TableCell align="left">{row.taskName}</TableCell> */}
      <TableCell align="left">{row.customer}</TableCell>
      <TableCell align="left">{row.shipDate}</TableCell>
      <TableCell align="left">
        {row.designer !== '-' ? (
          row.designer
        ) : (
          <Link
            component="button"
            variant="body2"
            onClick={() => onAssignToMe(row.orderNumber)}
          >
            Assign to me
          </Link>
        )}
      </TableCell>
      <TableCell align="left">
        <NavLink to={`/proofs/${row.orderNumber}`}>
          <ChevronRightIcon />
        </NavLink>
      </TableCell>
    </TableRow>
  )
}
