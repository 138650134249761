import { SnackbarKey, useSnackbar } from 'notistack'
import React from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../store'
import { INotifcation, removeSnack } from '../../store/app'
import { getAppState } from '../../store/app/selectors'

let displayed: SnackbarKey[] = []

export const Notifier = () => {
  const dispatch = useAppDispatch()
  const { notifications } = useSelector(getAppState)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id]
  }

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed.filter((key) => id !== key)]
  }

  React.useEffect(() => {
    notifications.forEach(
      ({ key, message, options = {}, dismissed = false }: INotifcation) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          console.log('closeSnackbar')
          closeSnackbar(key)
          return
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return

        // display snackbar using notistack
        enqueueSnackbar(message, {
          key,
          ...options,
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey)
            }
          },
          onExited: (event, myKey) => {
            console.log('excited', myKey)
            // remove this snackbar from redux store
            dispatch(removeSnack(myKey))
            removeDisplayed(myKey)
          },
        })

        // keep track of snackbars that we've displayed
        storeDisplayed(key)
      }
    )
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

  return null
}

export default Notifier
