import {
  Container,
  createStyles,
  Divider,
  Drawer,
  makeStyles,
} from '@material-ui/core'
import React, { FunctionComponent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FiltersDrawer } from '../../components/filters-drawer'
import { OrderTable } from '../../components/orders-table'
import { defaultRender } from '../../components/orders-table/tableDefaults'
import { SavedFilters } from '../../components/saved-filters'
import { STEP_SHIPPING } from '../../constants'
import { defaultShippingFilters } from '../../factories/filters-factory'
import { useAppDispatch } from '../../store'
import { setFiltersDrawerClose } from '../../store/ui'
import { getUiState } from '../../store/ui/selectors'
import { IFilterEditable, IProofsProps, IStep } from '../../types'

const drawerWidth = 240

const useStyles = makeStyles(() =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
  })
)

interface IProProps {
  orders: IProofsProps[]
  hasMoreOrders: boolean
  filters: IFilterEditable[]
  onFilterCreate: (filter: IFilterEditable) => void
  onFilterClick: (index: number) => void
  onFilterDelete: (filter: IFilterEditable, index: number) => void
  onAssignToMe: (orderNumber: string) => void
  activeFilterIndex: number
  onBootstrap: (step: IStep, filters?: IFilterEditable[]) => void
  isLoading: boolean
}

export const Shipping: FunctionComponent<IProProps> = ({
  orders,
  hasMoreOrders,

  filters,
  onFilterCreate,
  onFilterClick,
  onFilterDelete,
  onAssignToMe,
  activeFilterIndex,
  isLoading,
  onBootstrap,
}: IProProps) => {
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const { isFiltersDrawerOpen } = useSelector(getUiState)

  useEffect(() => {
    onBootstrap(STEP_SHIPPING, defaultShippingFilters)
  }, [])

  return (
    <Container>
      <SavedFilters
        filters={filters}
        onClick={onFilterClick}
        onDelete={onFilterDelete}
        activeFilterIndex={activeFilterIndex}
      />
      <Divider />
      <OrderTable
        name="Shipping"
        rows={orders}
        hasMore={hasMoreOrders}
        isLoading={isLoading}
        onAssignToMe={onAssignToMe}
        renderRow={defaultRender}
      />
      <Drawer
        style={{
          width: 240,
          flexShrink: 0,
        }}
        variant="persistent"
        anchor="right"
        open={isFiltersDrawerOpen}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <FiltersDrawer
          onDrawerClose={() => dispatch(setFiltersDrawerClose())}
          onSaveFilter={onFilterCreate}
        />
      </Drawer>
    </Container>
  )
}
