import { Box } from '@material-ui/core'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { RouteProps, useHistory, useLocation } from 'react-router-dom'
import { IFilter } from '@kartdavid/corkscrew-types/internal'
import {
  MANUFACTURING,
  ORDERS,
  PROOFS,
  SHIPPING,
  STEP_MANUFACTURING,
} from '../../constants'
import { getOrderQueryParams } from '../../factories/filters-factory'
import { useAppDispatch } from '../../store'
import { getAppState } from '../../store/app/selectors'
import {
  assignToMe,
  deleteFilterThunk,
  fetchOrders,
  fetchStatuses,
  saveFilterThunk,
  setFiltersThunk,
} from '../../store/app/thunk'
import { getAuthState } from '../../store/auth/selectors'
import { IFilterEditable, IStep } from '../../types'
import { currentStepFromPathname } from '../../utils/object'
import { toTitleCase } from '../../utils/string'
import { Manufacturing } from '../manufacturing'
import { Orders } from '../orders'
import { Proofs } from '../proofs'
import { Shipping } from '../shipping'
import { config } from '../../config'

interface IProps extends RouteProps {
  children: React.ReactNode
}

export const StepsContainer: FunctionComponent<IProps> = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { pathname, search } = useLocation()
  // Selectors
  const { orders, filters, isLoadingOrders, hasMoreOrders } =
    useSelector(getAppState)
  const { user } = useSelector(getAuthState)
  // State
  const params = new URLSearchParams(search)
  const activeFilterIndex = parseInt(params.get('activeFilterIdx') || '0')
  const perPage = parseInt(params.get('perPage') || '10')
  const page = parseInt(params.get('page') || '0')
  const activeFilter = filters[activeFilterIndex]
  const [filtersLoaded, setFiltersLoaded] = useState(false)
  const [currentStep, setCurrentStep] = useState(
    currentStepFromPathname(pathname)
  )

  useEffect(() => {
    setFiltersLoaded(false)
    const step = currentStepFromPathname(pathname)
    dispatch(fetchStatuses(step))
    setCurrentStep(step)
  }, [pathname])

  useEffect(() => {
    if (!filtersLoaded) {
      return
    }

    if (currentStep === STEP_MANUFACTURING) {
      return
    }

    dispatch(fetchOrders({ perPage, page, filter: filters[activeFilterIndex] }))
  }, [activeFilterIndex, filters, currentStep, filtersLoaded, page, perPage])

  useEffect(() => {
    if (!activeFilter) {
      return
    }

    if (currentStep === STEP_MANUFACTURING) {
      return
    }

    let timeout: NodeJS.Timeout | null = null
    const params = getOrderQueryParams(activeFilter)
    const events = new EventSource(
      `${config.REACT_APP_API_URL}/v1/events?${params.toString()}`
    )
    events.addEventListener('message', ({ data }) => {
      console.debug('Order update', data)
      // const msg: Record<string, string> = JSON.parse(data)

      if (timeout !== null) {
        return
      }

      timeout = setTimeout(() => {
        dispatch(
          fetchOrders({
            perPage,
            page,
            filter: activeFilter,
          })
        )
        if (timeout) {
          clearTimeout(timeout)
          timeout = null
        }
      }, 2000)
    })
    events.addEventListener('error', (err) => {
      console.error('ev', err)
      events.close()
    })
    events.addEventListener('open', () => {
      console.log('ev', 'connected to event source')
    })

    return () => {
      console.log('closing event source')
      events.close()
    }
  }, [activeFilter, currentStep])

  const onBootstrap = async (step: IStep, defaultFilters: IFilter[] = []) => {
    dispatch(setFiltersThunk({ step, defaultFilters })).then(() => {
      setFiltersLoaded(true)
    })
  }

  const handleUpdateQueryParam = (activeFilterIndex: number) => {
    params.set('activeFilterIdx', activeFilterIndex.toString())
    params.set('page', '0')
    history.push({
      search: `?${params.toString()}`,
    })
  }

  const handleAssignToMe = (orderNumber: string) => {
    dispatch(assignToMe({ orderNumber, userId: user ? user.id : '' }))
  }

  const handleAddNewFilter = async (filterToSave: IFilterEditable) => {
    dispatch(saveFilterThunk(currentStep, filterToSave))
  }

  const handleDeleteFilter = async (filterToDelete: IFilterEditable) => {
    handleUpdateQueryParam(0)
    dispatch(
      deleteFilterThunk({ step: currentStep, filterId: filterToDelete.id })
    )
  }

  const renderStep = (pathname: string) => {
    switch (pathname) {
      case PROOFS:
        return (
          <Proofs
            orders={orders}
            hasMoreOrders={hasMoreOrders}
            filters={filters}
            isLoading={isLoadingOrders}
            onBootstrap={onBootstrap}
            onFilterCreate={handleAddNewFilter}
            onFilterClick={(filterIndex) => handleUpdateQueryParam(filterIndex)}
            onFilterDelete={handleDeleteFilter}
            onAssignToMe={handleAssignToMe}
            activeFilterIndex={activeFilterIndex}
          />
        )
      case ORDERS:
        return (
          <Orders
            orders={orders}
            hasMoreOrders={hasMoreOrders}
            filters={filters}
            isLoading={isLoadingOrders}
            onBootstrap={onBootstrap}
            onFilterCreate={handleAddNewFilter}
            onFilterClick={(filterIndex) => handleUpdateQueryParam(filterIndex)}
            onFilterDelete={handleDeleteFilter}
            onAssignToMe={handleAssignToMe}
            activeFilterIndex={activeFilterIndex}
          />
        )
      case SHIPPING:
        return (
          <Shipping
            orders={orders}
            hasMoreOrders={hasMoreOrders}
            filters={filters}
            isLoading={isLoadingOrders}
            onBootstrap={onBootstrap}
            onFilterCreate={handleAddNewFilter}
            onFilterClick={(filterIndex) => handleUpdateQueryParam(filterIndex)}
            onFilterDelete={handleDeleteFilter}
            onAssignToMe={handleAssignToMe}
            activeFilterIndex={activeFilterIndex}
          />
        )
      case MANUFACTURING:
        return <Manufacturing />
      default:
        return <>No view found for: {pathname}</>
    }
  }

  return (
    <Box>
      <Helmet>
        <title>
          {activeFilter ? activeFilter.name : ''} -{' '}
          {toTitleCase(currentStepFromPathname(pathname))} - Corkscrew
        </title>
      </Helmet>
      {renderStep(pathname)}
    </Box>
  )
}
