import { manufacturingHeadCells } from '@kartdavid/atomic-ui/dist/components/orders-table/tableDefaults'
import { HeadCell } from '@kartdavid/atomic-ui/dist/types'

export const newManufacturingHeadCells = () => {
  const cells = manufacturingHeadCells
  const batchId: HeadCell = {
    id: 'batchId',
    label: 'Batch ID',
    numeric: false,
    disablePadding: false,
  }
  const laminateSku: HeadCell = {
    id: 'laminateSku',
    label: 'Laminate',
    numeric: false,
    disablePadding: false,
  }
  const cutTime: HeadCell = {
    id: 'cutTime',
    label: 'Cut Time',
    numeric: false,
    disablePadding: false,
  }

  return [cells[0], batchId]
    .concat(cells.slice(1, 3))
    .concat([laminateSku])
    .concat(cells.slice(3, 5))
    .concat([cutTime])
    .concat(cells.slice(5))
}
