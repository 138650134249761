import { IOrder } from '@kartdavid/corkscrew-types/internal'
import { EPermissions } from '../store/auth'
import { IProofsProps } from '../types'
import { formatUtcDate } from './date'

export const transformOrderData = (ordersData: IOrder[]): IProofsProps[] => {
  return ordersData.map((order: IOrder) => {
    return {
      id: order.id,
      priority: 2,
      status: order.statusName,
      taskName: '-',
      customer: order.customerName,
      shipDate: formatUtcDate(order.deadlineShipDate),
      designer: order.assignedToName ? order.assignedToName : '-',
      actions: '-',
      orderNumber: order.orderNumber,
      storeName: order.storeName,
    }
  })
}

export enum EViewName {
  Orders = 1,
  Proof = 2,
}

type IStatusDefinition = {
  name: string
  description: string
  permissions: string[]
}

export type IStatuses = Record<string, IStatusDefinition>

const statuses: IStatuses = {
  StatusUndefined: {
    name: 'Undefined',
    description: 'This should never happen. If you see it please tell DRT/BW',
    permissions: [EPermissions.OrderManage],
  },
  StatusNone: {
    name: 'None',
    description:
      'After Stanton imports the order from BigCommerce. Initial Status. If there are items in this Status it is a software bug',
    permissions: [EPermissions.OrderManage],
  },
  StatusNew: {
    name: 'New',
    description:
      'After import Stanton decides if this order is New or not. If there are items in this Status it is a software bug',
    permissions: [EPermissions.OrderManage],
  },
  StatusComplete: {
    name: 'Complete',
    description:
      'The order requires no further action. It may have not been designed, printed or shipped.',
    permissions: [EPermissions.OrderManage],
  },

  StatusRefunded: {
    name: 'Refunded',
    description:
      'The order has been fully refunded and therefore the task requires no further action.',
    permissions: [EPermissions.OrderManage],
  },

  StatusPartiallyRefunded: {
    name: 'Partially Refunded',
    description:
      'The order has been partly refunded. It may need further action.',
    permissions: [EPermissions.OrderManage],
  },

  StatusCancelled: {
    name: 'Cancelled',
    description: '',
    permissions: [EPermissions.OrderManage],
  },

  StatusDeclined: {
    name: 'Declined',
    description: '',
    permissions: [EPermissions.OrderManage],
  },

  StatusDisputed: {
    name: 'Disputed',
    description: '',
    permissions: [EPermissions.OrderManage],
  },

  StatusDiscard: {
    name: 'Discard',
    description: '',
    permissions: [EPermissions.OrderManage],
  },

  StatusBlocked: {
    name: 'Blocked',
    description: 'You are waiting on somebody else in the team',
    permissions: [EPermissions.ProofManage, EPermissions.OrderManage],
  },

  StatusPaymentPending: {
    name: 'Payment Pending',
    description: 'This order is awaiting payment',
    permissions: [EPermissions.OrderManage],
  },

  StatusPaymentVerification: {
    name: 'Payment Verification',
    description:
      'Stripe has not been able to verify the payment yet or is awaiting manual approval',
    permissions: [EPermissions.OrderManage],
  },

  StatusProofPending: {
    name: 'Design: Awaiting Design',
    description: 'Needs a proof to be created.',
    permissions: [EPermissions.OrderManage, EPermissions.ProofManage],
  },

  StatusProofUserArtworkPending: {
    name: 'Design: User Artwork Pending',
    description: 'Needs artwork from the customer.',
    permissions: [EPermissions.OrderManage, EPermissions.ProofManage],
  },

  StatusProofDesignInProgress: {
    name: 'Design: In Progress',
    description: 'A designer is currently working on this task.',
    permissions: [EPermissions.OrderManage, EPermissions.ProofManage],
  },

  StatusProofDesignComplete: {
    name: 'Design: Complete',
    description:
      'All designs are complete, but has not been sent to the customer',
    permissions: [EPermissions.OrderManage, EPermissions.ProofManage],
  },

  StatusProofUserReviewRejected: {
    name: 'Design: User Review Rejected',
    description: 'The customer has rejected one of more designs.',
    permissions: [EPermissions.OrderManage, EPermissions.ProofManage],
  },

  StatusProofUserReviewAccepted: {
    name: 'Design: User Review Accepted',
    description: 'The customer has approved all designs.',
    permissions: [EPermissions.OrderManage, EPermissions.ProofManage],
  },

  StatusProofUserReviewPending: {
    name: 'Design: User Review Pending',
    description:
      'Waiting for 1 or more designs to be approved by the customer.',
    permissions: [EPermissions.OrderManage, EPermissions.ProofManage],
  },

  StatusProofMakePrintReady: {
    name: 'Design: Make Print Ready',
    description: 'Needs 1 or more designs making print ready.',
    permissions: [EPermissions.OrderManage, EPermissions.ProofManage],
  },

  StatusProofInternalReviewPending: {
    name: 'Design: Internal Review Pending',
    description: 'Waiting for internal approval.',
    permissions: [EPermissions.OrderManage, EPermissions.ProofManage],
  },

  StatusProofInternalReviewRejected: {
    name: 'Design: Internal Review Rejected',
    description: 'Rejected following internal review.',
    permissions: [EPermissions.OrderManage, EPermissions.ProofManage],
  },

  StatusProofInternalReviewAccepted: {
    name: 'Design: Internal Review Accepted',
    description: 'Approved following internal review.',
    permissions: [EPermissions.OrderManage, EPermissions.ProofManage],
  },

  StatusProofAutoAccepted: {
    name: 'Auto Accepted',
    description:
      'If the user ordered via Graphic, or Re-ordered using Approved Artwork then we skip the Design',
    permissions: [EPermissions.OrderManage],
  },

  StatusProofAccepted: {
    name: 'Accepted',
    description:
      'This catches all Approved Proofs from the Customer, Internally and that have been Auto Accepted and moves them on to the manufacturing process. If you see an order here for more than 5mins then it is a software bug',

    permissions: [EPermissions.OrderManage],
  },
  StatusPrintPending: {
    name: 'Ready for printing',
    description: 'The order is ready to be printed but has not been scheduled',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusPrintScheduled: {
    name: 'Print Job Scheduled',
    description: 'Job has been prioritised and scheduled on a machine',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusPrintQueued: {
    name: 'Sent to Printer',
    description:
      'The Print Job has been sent to the Printer and is waiting to be printed',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusPrintPrinting: {
    name: 'Printing',
    description: 'The job is printing',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusPrintCompleted: {
    name: 'Printed',
    description: 'The job has been printed',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusPrintError: {
    name: 'Print Error',
    description: 'The Print Job failed',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusPrintRejected: {
    name: 'Print Rejected',
    description:
      'The manufacturing team has rejected this because it has a missing white layer, poor cut line. See messages',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusLaminatePending: {
    name: 'Laminate: Ready for laminating',
    description: 'The order is ready to be printed but has not been scheduled',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusLaminateScheduled: {
    name: 'Laminate: Laminate Job Scheduled',
    description: 'Job has been prioritised and scheduled on a machine',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusLaminateQueued: {
    name: 'Laminate: Sent to Laminator',
    description:
      'The Laminate Job has been sent to the Laminator and is waiting to be laminated',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusLaminateLaminating: {
    name: 'Laminate: Laminating',
    description: 'The job is laminating',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusLaminateCompleted: {
    name: 'Laminate: Laminated',
    description: 'The job has been laminated',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusLaminateError: {
    name: 'Laminate: Laminate Error',
    description: 'The Laminate Job failed',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusCutPending: {
    name: 'Ready for cutting',
    description: 'The order is ready to be cut but has not been scheduled',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusCutScheduled: {
    name: 'Job Scheduled',
    description: 'Job has been prioritised and scheduled on a machine',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusCutQueued: {
    name: 'Sent to Cutter',
    description:
      'The Cut Job has been sent to the Cutter and is waiting to be cut',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusCutCutting: {
    name: 'Cutting',
    description: 'The job is cutting',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusCutCompleted: {
    name: 'Cut complete',
    description: 'The job has been cut',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusCutError: {
    name: 'Cut Error',
    description: 'The Cut Job failed',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusQAPending: {
    name: 'QA: Pending',
    description: 'Quality Control needed',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusQAAccepted: {
    name: 'QA: Accepted',
    description: 'Quality Control Accepted',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusQARejected: {
    name: 'QA: Rejected',
    description: 'Quality Control Rejected',
    permissions: [EPermissions.ManufacturingManage],
  },
  StatusShippingPending: {
    name: 'Shipping: Pending',
    description: 'The order is waiting to be shipped. All designs are printed',
    permissions: [EPermissions.OrderManage, EPermissions.ManufacturingManage],
  },

  StatusShippingWithCourier: {
    name: 'Shipping: With Courier',
    description: 'The order has been shipped and is yet to be delivered.',
    permissions: [EPermissions.OrderManage],
  },

  StatusShippingPendingPickup: {
    name: 'Shipping: Awaiting Pickup',
    description: 'The order has not yet been picked up by the courier',
    permissions: [EPermissions.OrderManage],
  },

  StatusShippingFailure: {
    name: 'Shipping: Failure',
    description:
      'The courier told us of a problem with the Shipment. We need to do something about it',
    permissions: [EPermissions.OrderManage],
  },

  StatusDelivered: {
    name: 'Shipping: Delivered',
    description: 'The order has been delivered.',
    permissions: [EPermissions.OrderManage],
  },
}

export const getNicenameFromStatus = (statusName: string) => {
  const foundStatus = statuses[statusName]

  if (!foundStatus) {
    return 'Status Not Found! Tell BW!'
  }

  return statuses[statusName].name
}

export const AllStatuses = statuses

export const hasPermission = (userPermissions: string[], status: string) => {
  if (!statuses[status]) {
    return true
  }

  return userPermissions.some((up) =>
    statuses[status]?.permissions.includes(up)
  )
}

export const hasExplicitPermission = (
  userPermissions: string[],
  status: string
) => {
  return userPermissions.includes(status)
}

export const hasPermissionRole = (
  userPermissions: string[],
  role: EPermissions
) => {
  return userPermissions.includes(role)
}

export const statusesForCategory = (category: string) => {
  return Object.entries(statuses)
    .filter(([, info]) => {
      return info.name.toLowerCase().startsWith(category.toLowerCase() + ':')
    })
    .map(([status]) => {
      return status
    })
}
