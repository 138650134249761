import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { ThemeProvider } from '@material-ui/core/styles'
import { ConnectedRouter } from 'connected-react-router'
import { SnackbarProvider } from 'notistack'
import React, { FunctionComponent, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router'
import { Redirect, RouteProps } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App'
import { Notifier } from './components/notifier'
import {
  ACCOUNT,
  CALLBACK,
  CUSTOMERS,
  MANUFACTURING,
  ORDERS,
  PROOFS,
  ROUTE_CUSTOMER_DETAILS,
  ROUTE_QA,
  SHIPPING,
} from './constants'
import { apiService } from './service/api'
import { history, persistor, store } from './store'
// import { auth } from './store/auth'
import { getAuthState, getIsAuthenticated } from './store/auth/selectors'
import theme from '@kartdavid/atomic-ui/dist/theme'
import { Account } from './views/account'
import { Callback } from './views/callback'
import { NotFound } from './views/not-found'
import { ProofsItem } from './views/proofs-item'
import { SignIn } from './views/sign-in'
import { StepsContainer } from './views/steps-container'
const HelpStatusPage = React.lazy(() => import('./views/help/status'))
const QualityControl = React.lazy(() => import('./views/QualityControl'))
import ConvertPage from './views/ConvertPage'
import CustomerDetails from './views/CustomerDetails'
import CustomerListView from './views/CustomerListView'
import { config } from './config'
const ManufacturingJobView = React.lazy(
  () => import('./views/manufacturing-job')
)

const PrivateRoute = (routeProps: RouteProps) => {
  const isAuthenticated = useSelector(getIsAuthenticated)
  console.log('PrivateRoute', isAuthenticated)
  if (!isAuthenticated) {
    return <Redirect to={{ pathname: `/` }} />
  }

  return <Route {...routeProps} />
}

interface IAppProvidersProps {
  children: React.ReactNode
}

const AppProviders: FunctionComponent<IAppProvidersProps> = ({
  children,
}: IAppProvidersProps) => {
  const { accessToken } = useSelector(getAuthState)
  const isAuthenticated = useSelector(getIsAuthenticated)

  return (
    <PersistGate
      loading={<div>Loading</div>}
      persistor={persistor}
      onBeforeLift={() => {
        if (isAuthenticated && accessToken) {
          apiService.setApiKey(accessToken)
          return Promise.resolve()
        }
      }}
    >
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={3}
            preventDuplicate={false}
            autoHideDuration={2500}
          >
            {children}
          </SnackbarProvider>
        </ThemeProvider>
      </ConnectedRouter>
    </PersistGate>
  )
}

const Root = () => {
  // Init bugsnag and wrap it in an ErrorBoundry component on production
  Bugsnag.start({
    apiKey: 'a4b08e586d6c92d7bc613933966a80c8',
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['test', 'live'],
    releaseStage: config.REACT_APP_RELEASE_STAGE || 'dev',
  })
  const bugsnagReactPlugin: any = Bugsnag.getPlugin('react')
  const ErrorBoundary = bugsnagReactPlugin.createErrorBoundary(React)

  return (
    <React.StrictMode>
      <ErrorBoundary>
        <Provider store={store}>
          <AppProviders>
            <Notifier />
            <App>
              <Switch>
                <Route exact path="/" component={SignIn} />
                <Route exact path={CALLBACK} component={Callback} />
                <Route exact path={'/convert'} component={ConvertPage} />
                <PrivateRoute exact path={ACCOUNT} component={Account} />
                <PrivateRoute
                  exact
                  path={ROUTE_CUSTOMER_DETAILS}
                  component={CustomerDetails}
                />
                <PrivateRoute
                  exact
                  path={CUSTOMERS}
                  component={CustomerListView}
                />
                <PrivateRoute exact path={PROOFS} component={StepsContainer} />
                <PrivateRoute exact path={ORDERS} component={StepsContainer} />
                <PrivateRoute
                  exact
                  path={SHIPPING}
                  component={StepsContainer}
                />
                <PrivateRoute
                  exact
                  path={MANUFACTURING}
                  component={StepsContainer}
                />
                <PrivateRoute
                  exact
                  path={`${PROOFS}/:orderNumber`}
                  component={ProofsItem}
                />
                <PrivateRoute
                  exact
                  path={`${MANUFACTURING}/:designCode`}
                  component={() => (
                    <Suspense fallback={<div>Loading...</div>}>
                      <ManufacturingJobView />
                    </Suspense>
                  )}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_QA}
                  component={() => (
                    <Suspense fallback={<div>Loading...</div>}>
                      <QualityControl />
                    </Suspense>
                  )}
                />
                <PrivateRoute
                  exact
                  path="/help/status"
                  component={() => (
                    <Suspense fallback={<div>Loading...</div>}>
                      <HelpStatusPage />
                    </Suspense>
                  )}
                />
                <Route path="*" exact={true} component={NotFound} />
              </Switch>
            </App>
          </AppProviders>
        </Provider>
      </ErrorBoundary>
    </React.StrictMode>
  )
}

ReactDOM.render(<Root />, document.getElementById('root'))
