import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { PROOFS } from '../../constants'
import { apiService } from '../../service/api'
import { useAppDispatch } from '../../store'
import { auth, setLogin } from '../../store/auth'
import { fetchUser } from '../../store/auth/thunks'

export const Callback = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (/access_token|id_token|error/.test(window.location.hash)) {
      try {
        auth.parseHash((err, authResult) => {
          if (err) {
            // throw new Error(err.error)
            console.error(err)
            history.push('/')
            return
          }

          if (
            authResult &&
            authResult.accessToken &&
            authResult.idToken &&
            authResult.expiresIn
          ) {
            console.log('authResult', authResult)
            // Set bearer
            apiService.setApiKey(authResult.accessToken)

            console.log(
              'next expires at',
              new Date().getTime() + authResult.expiresIn * 1000
            )

            dispatch(
              setLogin({
                accessToken: authResult.accessToken,
                tokenId: authResult.idToken,
                expiresAt: new Date().getTime() + authResult.expiresIn * 1000,
                user: {
                  name: authResult.idTokenPayload.name,
                  email: authResult.idTokenPayload.email,
                  picture: authResult.idTokenPayload.picture,
                  permissions: authResult.idTokenPayload.permissions,
                },
              })
            )
            // Set the time that the access token will expire at
            dispatch(fetchUser(authResult.accessToken))
            history.push(PROOFS)
          }
        })
      } catch (err) {
        console.error(err)
        history.push('/')
      }
    } else {
      console.error('No access_token|id_token|error found in window.hash')
      history.push('/')
    }
  }, [dispatch, history])

  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      <Box mr={2}>
        <CircularProgress />
      </Box>
      <Typography>Fetching user details...</Typography>
    </Box>
  )
}
