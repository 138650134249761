import { IProof, IThing } from '@kartdavid/corkscrew-types/internal'
import React, { useEffect, useState } from 'react'
import { apiService } from '../../service/api'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import { EBatchStatus } from '@kartdavid/corkscrew-types/internal'
import InView from 'react-intersection-observer'
import CircularProgress from '@mui/material/CircularProgress'
import { config } from '../../config'

export interface HistoryItemProofInfoProps {
  proofId: string
}

const HistoryItemProofInfo: React.FunctionComponent<HistoryItemProofInfoProps> =
  (props: HistoryItemProofInfoProps) => {
    const [proof, setProof] = useState<IProof | undefined>()
    const [estimates, setEstimates] = useState<any[]>()
    const [presses, setPresses] = useState<IThing[]>([])

    useEffect(() => {
      apiService.getProof(props.proofId).then(setProof)
      apiService.listThings('press').then(setPresses)
      apiService.getProofEstimates(props.proofId).then(setEstimates)
    }, [props.proofId])

    // {activeProofId ? (
    //   <Box>
    //     {isEstimateOpen ? (
    //       <BatchEstimate orderLineId={id} activeProofId={activeProofId} />
    //     ) : null}
    //   </Box>
    // ) : null}

    if (!proof) {
      return <div>Proof not found</div>
    }

    return (
      <>
        <Table
          aria-label="simple table"
          style={{ fontSize: '1.125rem', float: 'left', width: 325 }}
        >
          <TableBody>
            <TableRow>
              <TableCell>
                <strong>Laminate SKU</strong>
              </TableCell>
              <TableCell>
                <code>{proof.laminateSku}</code>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Finish SKU</strong>
              </TableCell>
              <TableCell>
                <code>{proof.finishSku}</code>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Shape</strong>
              </TableCell>
              <TableCell>
                <code>{proof.shape}</code>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Print Order</strong>
              </TableCell>
              <TableCell>
                <code>{proof.colorPrintOrder}</code>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>White Layer</strong>
              </TableCell>
              <TableCell>
                <code>{proof.whiteLayer}</code>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Presses</strong>
              </TableCell>
              <TableCell>
                <code>
                  {proof?.pressIds
                    ?.map(
                      (id) =>
                        presses.find((p) => p.id === id)?.name || 'Unknown'
                    )
                    .join(', ')}
                </code>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {estimates?.map((e, idx) => {
          let status = ''
          switch (e.status as EBatchStatus) {
            case EBatchStatus.BatchStatusCompleted:
              status = 'Completed'
              break
            case EBatchStatus.BatchStatusError:
              status = 'Error'
              break
            case EBatchStatus.BatchStatusQueued:
              status = 'Queued'
          }

          return (
            <Table
              key={idx}
              aria-label="simple table"
              style={{ fontSize: '1.125rem', float: 'left', width: 325 }}
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    <b>
                      {presses.find((p) => p.id === e.pressId)?.name ||
                        e.pressId}
                    </b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <strong>Status</strong>
                  </TableCell>
                  <TableCell>{status}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Nesting Report</strong>
                  </TableCell>
                  <TableCell>
                    {e.reportPdfUrl === '' ? (
                      '-'
                    ) : (
                      <a
                        href={`${config.REACT_APP_API_URL}${e.reportPdfUrl}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Open
                      </a>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Full Frame Layout?</strong>
                  </TableCell>
                  <TableCell>{e.isFullFrameNest ? 'Y' : 'N'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Nesting Result</strong>
                  </TableCell>
                  <TableCell>
                    {e.stageOnePdfUrl === '' ? (
                      '-'
                    ) : (
                      <a
                        href={`${config.REACT_APP_API_URL}${e.stageOnePdfUrl}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Open
                      </a>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )
        })}
      </>
    )
  }

const InViewItem: React.FunctionComponent<HistoryItemProofInfoProps> = (
  props: HistoryItemProofInfoProps
) => {
  return (
    <InView threshold={0.75} triggerOnce={true}>
      {({ inView, ref }) => (
        <div ref={ref} style={{ height: 100 }}>
          {inView ? <HistoryItemProofInfo {...props} /> : <CircularProgress />}
        </div>
      )}
    </InView>
  )
}

export default InViewItem
