import React from 'react'
import { EBatchStatus, IBatch } from '@kartdavid/corkscrew-types/internal'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Theme, withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import TickIcon from '@mui/icons-material/DoneOutlined'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined'
import { BatchTable } from '../../components/BatchTable'
import { config } from '../../config'

interface IBatchInfoProps {
  batchInfo?: IBatch
  loading: boolean
}

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 330,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip)

const IBatchInfo = (props: IBatchInfoProps) => {
  const { batchInfo, loading } = props

  if (!batchInfo) {
    return null
  }

  if (loading) {
    return <CircularProgress />
  }

  const avatar =
    batchInfo.status === EBatchStatus.BatchStatusCompleted ? (
      <TickIcon />
    ) : batchInfo.status === EBatchStatus.BatchStatusError ? (
      <ErrorIcon />
    ) : [
        EBatchStatus.BatchStatusQueued,
        EBatchStatus.BatchStatusSoftFail,
      ].includes(batchInfo.status) ? (
      <QueryBuilderOutlinedIcon />
    ) : undefined

  return (
    <div style={{ display: 'flex' }}>
      <HtmlTooltip
        title={
          <>
            <h3>Batch: {batchInfo.shortId}</h3>
            <BatchTable
              batchEstimate={batchInfo}
              links={false}
              runLength={false}
            />
          </>
        }
        placement="right"
      >
        <Chip
          clickable={!!batchInfo.reportPdfUrl}
          avatar={avatar}
          component="a"
          label={
            <code style={{ fontSize: 14, letterSpacing: '1.5px' }}>
              {batchInfo.shortId}
            </code>
          }
          target="_blank"
          rel="noreferrer"
          href={
            batchInfo.reportPdfUrl
              ? config.REACT_APP_API_URL + batchInfo.reportPdfUrl
              : undefined
          }
          variant="outlined"
        />
      </HtmlTooltip>
    </div>
  )
}

export default IBatchInfo
