import React, { FunctionComponent, useMemo, useState } from 'react'

import Chip from '@mui/material/Chip'
import { IThing } from '@kartdavid/corkscrew-types/internal'
import { useEffect } from 'react'
import { apiService } from '../../service/api'
import Box from '@mui/material/Box'
import { useHistory } from 'react-router-dom'

const PressFilters: FunctionComponent = () => {
  const [things, setThings] = useState<IThing[]>([])
  const history = useHistory()

  useEffect(() => {
    apiService.listThings('').then(setThings)

    const interval = setInterval(async () => {
      apiService.listThings('').then(setThings)
    }, 30 * 1000)

    return () => clearInterval(interval)
  }, [])

  const query = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search]
  )

  return (
    <Box mb={2} mt={1}>
      {things.map((press) => {
        const isActive = query.get('thingId') === press.id

        return (
          <Chip
            key={press.id}
            label={press.name}
            sx={{ marginRight: 1 }}
            disabled={!isActive && query.has('thingId')}
            onDelete={
              isActive
                ? () => {
                    query.delete('thingId')
                    history.push({ search: query.toString() })
                  }
                : undefined
            }
            onClick={() => {
              if (isActive) {
                query.delete('thingId')
              } else {
                query.set('thingId', press.id)
              }

              history.push({ search: query.toString() })
            }}
            color={isActive ? 'primary' : 'default'}
          />
        )
      })}
    </Box>
  )
}

export default PressFilters
