import { ApolloClient, InMemoryCache } from '@apollo/client'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { config } from '../config'
import { getAuthState } from '../store/auth/selectors'

const cache = new InMemoryCache()

export const useAppApolloClient = () => {
  const { accessToken } = useSelector(getAuthState)

  const client = useMemo(() => {
    return new ApolloClient({
      uri: config.REACT_APP_GRAPHQL_URL,
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
      cache,
    })
  }, [accessToken])

  return client
}
