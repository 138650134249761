import Bugsnag from '@bugsnag/js'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  assignOrderToMe,
  setFilter,
  setHasMoreOrders,
  setSnack,
  setStatuses,
  setUpdateOrder,
  setUsers,
} from '.'
import { Thunk } from '..'
import {
  IAddOrderLineItemRequest,
  IFilter,
  IUpdateOrderLineItemRequest,
  IUpdateOrderRequest,
} from '@kartdavid/corkscrew-types/internal'
import { apiService } from '../../service/api'
import { IFilterEditable, IStep } from '../../types'
import { transformOrderData } from '../../utils/orders'

interface IFetchOrders {
  perPage: number
  page: number
  filter?: IFilterEditable
}

export const fetchOrders = createAsyncThunk(
  'app/fetchOrders',
  async (
    { perPage, page, filter }: IFetchOrders,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const resp = await apiService.fetchOrders(perPage, page, filter)

      if (!resp.data.data || resp.data.data.length === 0) {
        throw new Error(`No orders found for filter: ${filter?.name}`)
      }

      dispatch(setHasMoreOrders(resp.data.meta?.pagination?.hasMore))

      return transformOrderData(resp.data.data)
    } catch (error) {
      dispatch(
        setSnack({
          message: error.message,
          options: {
            variant: 'error',
          },
        })
      )

      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchManufacturingOrders = createAsyncThunk(
  'app/fetchManufacturingOrders',
  async (
    { perPage, page, filter }: IFetchOrders,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const resp = await apiService.fetchManufacturingOrders(
        perPage,
        page,
        filter
      )

      dispatch(setHasMoreOrders(resp.data.meta?.pagination?.hasMore))

      return resp.data.data
    } catch (error) {
      dispatch(
        setSnack({
          message: error.message,
          options: {
            variant: 'error',
          },
        })
      )

      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchStatuses =
  (step?: IStep): Thunk =>
  async (dispatch) => {
    try {
      const resp = await apiService.fetchStatuses(step)
      if (!resp.data.data) {
        dispatch(setStatuses([]))
        throw new Error(`No status found for: ${step}`)
      }

      const sortedStatuses = resp.data.data.sort((a, b) =>
        a.name.localeCompare(b.name)
      )

      dispatch(setStatuses(sortedStatuses))
    } catch (error) {
      Bugsnag.notify(error)
    }
  }

export const fetchUsers = (): Thunk => async (dispatch) => {
  try {
    const resp = await apiService.fetchUsers()
    const transformUsers = resp.data.data.map((filter) => ({
      ...filter,
      isChecked: false,
      externalId: '',
    }))
    dispatch(setUsers(transformUsers))
  } catch (error) {
    Bugsnag.notify(error)
  }
}

export const saveFilterThunk =
  (step: IStep, filter: IFilterEditable): Thunk =>
  async (dispatch) => {
    try {
      await apiService.createFilter(step, filter)
      dispatch(setFilter(filter))
    } catch (error) {
      Bugsnag.notify(error)
    }
  }

// export const deleteFilterThunk = (
//   step: IStep,
//   filterId: string
// ): Thunk => async (dispatch) => {
//   try {
//     await apiService.deleteFilter(step, filterId)
//     dispatch(setDeleteFilter(filterId))
//   } catch (error) {
//     Bugsnag.notify(error)
//   }
// }

interface IDeleteFilterProps {
  step: IStep
  filterId: string
}

export const deleteFilterThunk = createAsyncThunk(
  'app/deleteFilterThunk',
  async ({ step, filterId }: IDeleteFilterProps, { rejectWithValue }) => {
    try {
      await apiService.deleteFilter(step, filterId)
    } catch (error) {
      console.error('Could not not delete filter')
      return rejectWithValue(error.response.data)
    }
  }
)

interface IAssignToMeProps {
  orderNumber: string
  userId: string
}

export const assignOrderToUserId =
  ({ orderNumber, userId }: IAssignToMeProps): Thunk =>
  async (dispatch) => {
    try {
      const resp = await apiService.asignOrderToUserId(orderNumber, userId)

      dispatch(
        setUpdateOrder({
          assignedToId: userId,
          assignedToName: resp.data.data?.userName,
        })
      )

      let message = `Unassigned order: ${orderNumber}`
      if (userId) {
        message = `Assigned order: ${orderNumber} to ${resp.data.data?.userName}`
      }

      dispatch(
        setSnack({
          message,
          options: {
            variant: 'success',
          },
        })
      )
    } catch (error) {
      Bugsnag.notify(error)
    }
  }

export const assignToMe =
  ({ orderNumber, userId }: IAssignToMeProps): Thunk =>
  async (dispatch) => {
    try {
      const resp = await apiService.asignOrderToUserId(orderNumber, userId)

      dispatch(
        assignOrderToMe({
          orderNumber: orderNumber,
          userName: resp.data.data?.userName,
        })
      )
      dispatch(
        setSnack({
          message: `Assigned order: ${orderNumber} to you`,
          options: {
            variant: 'success',
          },
        })
      )
    } catch (error) {
      Bugsnag.notify(error)
    }
  }

export const updateOrder =
  (req: IUpdateOrderRequest): Thunk =>
  async (dispatch) => {
    try {
      const resp = await apiService.updateOrder(req)

      dispatch(
        setUpdateOrder({
          deadlineShipDate: resp.data?.data?.deadlineShipDate,
        })
      )

      dispatch(
        setSnack({
          message: 'Set a new shipping deadline',
          options: {
            variant: 'success',
          },
        })
      )
    } catch (error) {
      Bugsnag.notify(error)
    }
  }

interface IBootstrapProps {
  step: IStep
  defaultFilters: IFilter[]
}

export const setFiltersThunk = createAsyncThunk(
  'app/setFiltersThunk',
  async ({ step, defaultFilters }: IBootstrapProps, { rejectWithValue }) => {
    try {
      const transformedDefaultFilters = defaultFilters.map(
        (filter: IFilter) => ({
          ...filter,

          isEditable: false,
        })
      )

      const savedFiltersResp = await apiService.fetchFilters(step)

      const transformedSavedFilters: IFilterEditable[] = savedFiltersResp.data
        ? savedFiltersResp.data.map((filter: IFilter) => ({
            ...filter,
            isEditable: true,
          }))
        : []

      const allFilters = transformedDefaultFilters.concat(
        transformedSavedFilters
      )

      return allFilters
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

interface IFetchOrderProps {
  orderNumber: string
}

export const fetchOrderThunk = createAsyncThunk(
  'app/fetchOrderThunk',
  async ({ orderNumber }: IFetchOrderProps, { rejectWithValue }) => {
    try {
      const resp = await apiService.fetchOrder(orderNumber)
      return resp.data.data
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

interface IFetchJobProps {
  designCode: string
}

export const fetchJobThunk = createAsyncThunk(
  'app/fetchJobThunk',
  async ({ designCode }: IFetchJobProps, { rejectWithValue }) => {
    try {
      const resp = await apiService.fetchJob(designCode)
      return resp.data.data
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateLineItemThunk = createAsyncThunk(
  'app/updateLineItemThunk',
  async (lineUpdate: IUpdateOrderLineItemRequest, { rejectWithValue }) => {
    try {
      const resp = await apiService.updateLineItem(lineUpdate)
      return resp.data.data
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const addLineItemThunk = createAsyncThunk(
  'app/addLineItemThunk',
  async (lineToAdd: IAddOrderLineItemRequest, { rejectWithValue }) => {
    try {
      const resp = await apiService.addLineItem(lineToAdd)
      return resp.data.data
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

interface ISetOrderStatus {
  orderNumber: string
  statusName: string
}

export const setOrderStatusThunk = createAsyncThunk(
  'app/setOrderStatusThunk',
  async (
    { orderNumber, statusName }: ISetOrderStatus,
    { dispatch, rejectWithValue }
  ) => {
    try {
      await apiService.setOrderStatus(orderNumber, statusName)

      dispatch(
        setSnack({
          message: `Updated order status to: ${statusName}`,
          options: {
            variant: 'success',
          },
        })
      )

      return {
        statusName,
      }
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export interface IdeleteOrderLineItemProps {
  orderLineId: string
}

export const deleteOrderLineItem = createAsyncThunk<
  unknown,
  IdeleteOrderLineItemProps
>('app/deleteOrderLineItem', async ({ orderLineId }, { rejectWithValue }) => {
  try {
    await apiService.deleteOrderLineItem(orderLineId)
  } catch (error) {
    console.error(error)
    return rejectWithValue(error.response.data)
  }
})
