import cuid from 'cuid'
import {
  IFilter,
  IFilterDesigner,
  IFilterStatus,
} from '@kartdavid/corkscrew-types/internal'
import { IFilterEditable } from '../types'

export const makeBlankStatus = (status: string): IFilterStatus => {
  return {
    id: cuid(),
    name: status,
  }
}

export const makeBlankDesigner = (
  desginerId: string = cuid()
): IFilterDesigner => {
  return {
    id: desginerId,
    name: '',
  }
}

export const makeStatusesArray = (status: string[]): IFilterStatus[] => {
  return status.map(makeBlankStatus)
}

export const makeDesginersArray = (
  designerIds: string[]
): IFilterDesigner[] => {
  return designerIds.map(makeBlankDesigner)
}

export const makeFilter = (
  name: string,
  statuses: string[] = [],
  designers: string[] = [],
  materials: string[] = [],
  isEditable = false
): IFilterEditable => {
  return {
    id: cuid(),
    name,
    isEditable,
    status: makeStatusesArray(statuses),
    designers: makeDesginersArray(designers),
    materials: makeStatusesArray(materials),
    pressIds: [],
  }
}

export const getOrderQueryParams = (activeFilter: IFilter): URLSearchParams => {
  const params = new URLSearchParams('')

  if (activeFilter.status) {
    for (const status of activeFilter.status) {
      params.append('statusName', status.name)
    }
  }

  if (activeFilter.designers) {
    for (const designer of activeFilter.designers) {
      params.append('assignedToId', designer.id)
    }
  }

  if (activeFilter.materials) {
    for (const material of activeFilter.materials) {
      params.append('materialSku', material.name)
    }
  }

  if (activeFilter.pressIds) {
    activeFilter.pressIds.forEach((pressId) => {
      params.append('pressId', pressId)
    })
  }

  return params
}

// Proofs

const awaitingDesignFilters = [
  'StatusProofPending',
  'StatusProofDesignInProgress',
  'StatusProofUserReviewRejected',
  'StatusProofInternalReviewRejected',
]

const printReadyStatuses = [
  'StatusProofUserReviewAccepted',
  'StatusProofInternalReviewAccepted',
  'StatusPrintRejected',
  'StatusMakePrintReady',
]

const shippingStatuses = [
  'StatusShippingWithCourier',
  'StatusShippingPendingPickup',
  'StatusShippingFailure',
]

export const awaitingArtworkFilter = makeFilter('Awaiting Artwork', [
  'StatusProofUserArtworkPending',
])
export const newProofFilter = makeFilter(
  'Awaiting Design',
  awaitingDesignFilters
)
export const printReadyFilter = makeFilter(
  'Make Print ready',
  printReadyStatuses
)

export const toSend = makeFilter(
  'To Send',
  ['StatusShippingPending'],
  [],
  [],
  false
)

export const allShipping = makeFilter(
  'Shipped',
  shippingStatuses,
  [],
  [],
  false
)

export const defaultShippingFilters = [toSend, allShipping]

export const printStatuses = [
  'StatusPrintPending',
  'StatusPrintScheduled',
  'StatusPrintQueued',
  'StatusPrintPrinting',
  'StatusPrintCompleted',
  'StatusPrintError',
]

const laminateStatuses = [
  'StatusLaminatePending',
  'StatusLaminateScheduled',
  'StatusLaminateQueued',
  'StatusLaminateLaminating',
  'StatusLaminateCompleted',
  'StatusLaminateError',
]

const cutStatuses = [
  'StatusCutPending',
  'StatusCutScheduled',
  'StatusCutQueued',
  'StatusCutCutting',
  'StatusCutCompleted',
  'StatusCutError',
]

const qaStatuses = ['StatusQAPending']

const manufacturingStatuses = printStatuses.concat(
  laminateStatuses,
  cutStatuses
)

const printers = [
  {
    id: 'Mimaki UCJV300-160',
    name: 'Mimaki UCJV300-160',
    materialSkus: [
      'vinyl-white',
      'holographic',
      'heavy-duty',
      'eco-friendly',
      'eco-friendly-clear',
      'eco-friendly-front-adhesive',
      'brushed-silver',
    ],
  },
  {
    id: 'Mimaki UCJV300-75',
    name: 'Mimaki UCJV300-75',
    materialSkus: [
      'vinyl-clear',
      'glitter',
      'mirror-silver',
      'mirror-gold',
      'fluorescent-orange',
      'fluorescent-pink',
      'fluorescent-red',
      'fluorescent-yellow',
      'front-adhesive',
      'paper-biodegradable',
      'static-clings',
    ],
  },
]

const cutters = [
  {
    id: 'Cutter',
    name: 'Cutter',
    status: makeStatusesArray(cutStatuses),
  },
]

export const manufacturingFilter = makeFilter(
  'Manufacturing',
  manufacturingStatuses,
  [],
  [],
  false
)

export const allMaterialsFilter = makeFilter(
  'All materials',
  printStatuses,
  [],
  [],
  false
)

export const printingFilters = printers.map((printer): IFilterEditable => {
  return {
    id: cuid(),
    isEditable: false,
    name: printer.name,
    status: makeStatusesArray(printStatuses),
    designers: [],
    materials: printer.materialSkus.map(makeBlankStatus),
    pressIds: [],
  }
})

export const cutterFilters = cutters.map((cutter): IFilterEditable => {
  return {
    id: cuid(),
    isEditable: false,
    name: cutter.name,
    designers: [],
    status: cutter.status,
    materials: [],
    pressIds: [],
  }
})

export const qaFilters: IFilterEditable[] = [
  {
    id: cuid(),
    isEditable: false,
    name: 'Quality Control',
    designers: [],
    status: makeStatusesArray(qaStatuses),
    materials: [],
    pressIds: [],
  },
]
