import { IOrderLineItem } from '@kartdavid/corkscrew-types/internal'
import {
  Box,
  Container,
  createStyles,
  Divider,
  Drawer,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FiltersDrawer } from '../../components/filters-drawer'
import { OrderTable } from '../../components/orders-table'
import OrderTableManufacturing from '@kartdavid/atomic-ui/dist/components/orders-table/OrderTable'
import { defaultRender } from '../../components/orders-table/tableDefaults'
import { SavedFilters } from '../../components/saved-filters'
import { STEP_PROOF } from '../../constants'
import {
  awaitingArtworkFilter,
  makeFilter,
  newProofFilter,
  printReadyFilter,
} from '../../factories/filters-factory'
import { apiService } from '../../service/api'
import { useAppDispatch } from '../../store'
import { getAuthState } from '../../store/auth/selectors'
import { setFiltersDrawerClose } from '../../store/ui'
import { getUiState } from '../../store/ui/selectors'
import { IFilterEditable, IProofsProps, IStep } from '../../types'
import { statusesForCategory } from '../../utils/orders'
import ManufacturingRow from '../manufacturing/ManufacturingRow'
import { newManufacturingHeadCells } from '../manufacturing/headCells'
import { useSnackbar } from 'notistack'

const drawerWidth = 240

const useStyles = makeStyles(() =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
  })
)

interface IProProps {
  orders: IProofsProps[]
  hasMoreOrders: boolean
  filters: IFilterEditable[]
  onFilterCreate: (filter: IFilterEditable) => void
  onFilterClick: (index: number) => void
  onFilterDelete: (filter: IFilterEditable, index: number) => void
  onAssignToMe: (orderNumber: string) => void
  activeFilterIndex: number
  isLoading: boolean
  onBootstrap: (step: IStep, filters?: IFilterEditable[]) => void
}

export const Proofs: FunctionComponent<IProProps> = ({
  orders,
  hasMoreOrders,
  filters,
  isLoading,
  onFilterCreate,
  onFilterClick,
  onFilterDelete,
  onAssignToMe,
  activeFilterIndex,
  onBootstrap,
}: IProProps) => {
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const { isFiltersDrawerOpen } = useSelector(getUiState)
  const { user, accessToken } = useSelector(getAuthState)
  const [orderLines, setOrderLines] = useState<IOrderLineItem[]>([])
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    const defaultFilters = [
      awaitingArtworkFilter,
      newProofFilter,
      printReadyFilter,
    ]

    if (user && user.id && accessToken) {
      const designerFilter = makeFilter(
        'Assigned to me',
        statusesForCategory('design').concat([
          'StatusBlocked',
          'StatusPrintRejected',
        ]),
        [user.id],
        [],
        false
      )
      defaultFilters.unshift(designerFilter)
      // TODO: You could swap out KD filters here
      onBootstrap(STEP_PROOF, defaultFilters)
    }
  }, [user, accessToken])

  useEffect(() => {
    const filter = filters[activeFilterIndex]

    if (!filter) {
      setOrderLines([])
      return
    }

    if (!filter.status.map((st) => st.name).includes('StatusPrintRejected')) {
      setOrderLines([])
      return
    }

    apiService
      .fetchManufacturingOrders(25, 0, {
        id: '',
        name: '',
        designers: [],
        materials: [],
        status: [{ id: '', name: 'StatusPrintRejected' }],
        pressIds: [],
      })
      .then((resp) => {
        setOrderLines(resp.data.data)
      })
  }, [filters, activeFilterIndex])

  return (
    <Box>
      <Container>
        <SavedFilters
          filters={filters}
          onClick={onFilterClick}
          onDelete={onFilterDelete}
          activeFilterIndex={activeFilterIndex}
        />
      </Container>
      <Container>
        {orderLines.length > 0 ? (
          <>
            <Typography variant="h5">Print Rejections</Typography>
            <Typography variant="body2">
              These have been rejected from Manufacturing, see the note on each
              one to resolve
            </Typography>
            <Divider />
            <OrderTableManufacturing
              headCells={newManufacturingHeadCells()}
              rowsPerPage={25}
              page={0}
              name="Rejected"
              rows={orderLines}
              hasMore={hasMoreOrders}
              isLoading={isLoading}
              onAssignToMe={onAssignToMe}
              renderRow={(row) => {
                return <ManufacturingRow row={row} materials={[]} />
              }}
              handleChangePage={() => {
                enqueueSnackbar('not enabled', { variant: 'warning' })
              }}
              handleChangeRowsPerPage={() => {
                enqueueSnackbar('not enabled', { variant: 'warning' })
              }}
              rowsPerPageOptions={[25]}
            />
          </>
        ) : null}

        <Divider />
        <OrderTable
          name="Proofs"
          rows={orders}
          hasMore={hasMoreOrders}
          isLoading={isLoading}
          onAssignToMe={onAssignToMe}
          renderRow={defaultRender}
        />
        <Drawer
          style={{
            width: 240,
            flexShrink: 0,
          }}
          variant="persistent"
          anchor="right"
          open={isFiltersDrawerOpen}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <FiltersDrawer
            onDrawerClose={() => dispatch(setFiltersDrawerClose())}
            onSaveFilter={onFilterCreate}
          />
        </Drawer>
      </Container>
    </Box>
  )
}
