import React, { FC } from 'react'

import englishTrans from './i18n/en-gb.json'
import germanTrans from './i18n/de.json'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CancelIcon from '@mui/icons-material/Cancel'

import { IRequestNewArtworkRequest } from '@kartdavid/corkscrew-types/internal'
import { Alert } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    textField: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      width: '100%',
    },
    accordion: {
      border: '1px solid black',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    alertsSelectBox: {
      marginTop: theme.spacing(4),
    },
    languageSelectBox: {
      marginBottom: theme.spacing(4),
    },
  })
})

interface IProps {
  orderLanguageCode?: string
  designCode: string
  orderLineId: string

  onClose: () => void
  onSubmit: (payload: IRequestNewArtworkRequest) => void
}

interface IAlert {
  id: string
  title: string
  message: string
}

interface ITranslations {
  cannedReplies: IAlert[]
}

export const RequestNewArtworkModal: FC<IProps> = ({
  orderLanguageCode,
  designCode,
  onClose,
  orderLineId,
  onSubmit,
}: IProps) => {
  const translations: ITranslations = englishTrans
  const alerts: IAlert[] = translations.cannedReplies

  const allTranslations: Record<string, ITranslations> = {
    'en-gb': translations,
    de: germanTrans,
  }

  const [fetchedAlerts] = React.useState(alerts)

  const classes = useStyles()
  const [language, setLanguage] = React.useState(orderLanguageCode || 'en-gb')
  const [userAlerts, setUserAlerts] = React.useState<IAlert[]>([])
  const [alertMessage, setAlertMessage] = React.useState('')
  const [currentSelection] = React.useState<string>('')
  const [expanded, setExpanded] = React.useState<string | false>(false)
  const formValid = userAlerts.length > 0 || alertMessage.length > 0

  const filteredAlerts = fetchedAlerts.filter(
    (alert) => !userAlerts.find((userAlert) => userAlert.id === alert.id)
  )
  // METHODS
  const handleOnSubmit = () => {
    const currentLanguage = allTranslations[language]

    if (!currentLanguage) {
      return
    }

    const filterReplies = currentLanguage.cannedReplies.filter((alert) => {
      return userAlerts.find((userAlert) => alert.id === userAlert.id)
    })

    const payload: IRequestNewArtworkRequest = {
      orderLineId: orderLineId,
      message: alertMessage,
      cannedReplies: filterReplies.map((alert) => alert.message),
      languageCode: language,
    }

    onSubmit(payload)
  }

  const handleSelectChange = (event: React.ChangeEvent<{}>) => {
    const targetValue = (event.target as HTMLSelectElement).value
    const value = targetValue

    const found = fetchedAlerts.find((alert) => {
      return alert.id === value
    })

    if (!found) {
      return
    }
    setUserAlerts((prev) => [...prev, found])
  }
  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const handleDeleteAlert = (id: string) => {
    const filteredUserAlerts = userAlerts.filter((alert) => {
      return alert.id !== id
    })

    setUserAlerts(filteredUserAlerts)
  }

  const handleInputChange = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement
    setAlertMessage(target.value)
  }

  const handleLanguageChange = (event: React.ChangeEvent<{}>) => {
    const target = event.target as HTMLInputElement
    setLanguage(target.value)
  }

  return (
    <Container>
      <DialogTitle id="customized-dialog-title">
        Request New Artwork - {designCode}
      </DialogTitle>

      <DialogContent dividers>
        <Grid container>
          <Grid item xs={12}>
            <FormControl variant="filled">
              <InputLabel id="demo-simple-select-filled-label">
                Language
              </InputLabel>
              <Select
                value={language}
                onChange={handleLanguageChange}
                className={classes.languageSelectBox}
              >
                <MenuItem value="en-gb">English</MenuItem>
                <MenuItem value="de">German</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {userAlerts.length > 0 ? (
          userAlerts.map((alert, index: number) => (
            <Grid
              container
              key={index}
              alignItems="center"
              justify="center"
              spacing={2}
            >
              <Grid item xs={12} md={10}>
                <Accordion
                  className={classes.accordion}
                  expanded={expanded === 'panel' + index}
                  onChange={handleChange('panel' + index)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography>{alert.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{alert.message}</Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item>
                <IconButton
                  color="secondary"
                  onClick={() => handleDeleteAlert(alert.id)}
                >
                  <CancelIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))
        ) : (
          <Grid container justify="center">
            <Grid item>
              <Alert severity="info">Add new alert from the list below</Alert>
            </Grid>
          </Grid>
        )}
        <Grid container justify="center">
          <Grid item>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                displayEmpty
                value={currentSelection}
                onChange={handleSelectChange}
                className={classes.alertsSelectBox}
              >
                <MenuItem value="">Choose</MenuItem>
                {filteredAlerts.map((alert) => (
                  <MenuItem key={alert.id} value={alert.id}>
                    {alert.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              value={alertMessage}
              onChange={handleInputChange}
              label="Add Message (optional)"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          autoFocus
          variant="contained"
          color="primary"
          onClick={handleOnSubmit}
          disabled={!formValid}
        >
          Make a request
        </Button>
      </DialogActions>
    </Container>
  )
}
