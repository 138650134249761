import axios from 'axios'
import {
  IAssignOrderResponse,
  IChangeOrderStatusResponse,
  IFilter,
  IMaterialListResponse,
  IOrderGetResponse,
  IOrderListResponse,
  IProductListResponse,
  IProofCreateRequest,
  IProofCreateResponse,
  IStatusListResponse,
  IUserListResponse,
  IInternalMessageListResponse,
  IInternalMessageCreateResponse,
  IInternalMessageCreateRequest,
  IUploadCustomerArtworkResponse,
  IUploadCustomerArtworkRequest,
  IOrderLineHistoryListResponse,
  IUpdateOrderLineItemRequest,
  IUpdateOrderLineItemResponse,
  IAddOrderLineItemRequest,
  IAddOrderLineItemResponse,
  ISearchResponse,
  IChangeOrderLineItemStatusResponse,
  IManufacturingOrderListResponse,
  IGetJobResponse,
  IUpdateOrderRequest,
  IUpdateOrderResponse,
  IListManufacturingFiltersResponse,
  IRequestNewArtworkRequest,
  IOrderHistoryListResponse,
  IOrderLineQAApprovalRequest,
  ILaminate,
  IFinish,
  IProofGetResponse,
  ICreateBatchRequest,
  ICreateBatchResponse,
  IThingListResponse,
  IThing,
  IBatch,
  IBatchGetResponse,
  IBatchProductEstimateResponse,
  EBatchType,
  IRejectDesignRequest,
  IRejectDesignResponse,
  IMyAccountLinkResponse,
  IGetCustomerResponse,
  IUpdateCustomerRequest,
  IUpdateCustomerResponse,
  ICreateBatchItem,
  ICreateCustomerRequest,
  ICreateCustomerResponse,
  IListCustomerResponse,
  IProof,
} from '@kartdavid/corkscrew-types/internal'
import {
  AllocateOrderRequest,
  Order,
} from '@kartdavid/corkscrew-types/corkscrew'
import { getOrderQueryParams, makeFilter } from '../factories/filters-factory'
import { IFilterEditable, IStep } from '../types'
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import { config } from '../config'

const baseURL = config.REACT_APP_API_URL || ''

export const service = () => {
  let apiClient = axios.create({
    baseURL: baseURL,
  })
  return {
    baseUrl: () => baseURL,

    setApiKey: (apiKey: string) => {
      apiClient = axios.create({
        baseURL: baseURL,
        headers: {
          Authorization: 'Bearer ' + apiKey,
        },
      })

      return Promise.resolve()
    },

    fetchOrders: async (perPage: number, page: number, filter?: IFilter) => {
      let queryParams = new URLSearchParams()
      if (filter) {
        queryParams = getOrderQueryParams(filter)
      }

      queryParams.set('perPage', perPage.toString())
      queryParams.set('page', page.toString())

      return apiClient.get<IOrderListResponse>(`/v1/orders?${queryParams}`)
    },

    fetchManufacturingOrders: async (
      perPage: number,
      page: number,
      filter?: IFilter,
      abortSignal?: AbortSignal
    ) => {
      let queryParams = new URLSearchParams()
      if (filter) {
        queryParams = getOrderQueryParams(filter)
      }

      queryParams.set('perPage', perPage.toString())
      queryParams.set('page', page.toString())

      return apiClient.get<IManufacturingOrderListResponse>(
        `/v1/manufacturing/jobs?${queryParams}`,
        { signal: abortSignal }
      )
    },

    fetchStatuses: (step?: IStep) => {
      if (step) {
        return apiClient.get<IStatusListResponse>(`/v1/status?step=${step}`)
      }

      return apiClient.get<IStatusListResponse>(`/v1/status`)
    },

    fetchUsers: () => {
      return apiClient.get<IUserListResponse>(`/v1/users`)
    },

    fetchFilters: (step: IStep) => {
      return apiClient.get<IFilter[]>(`/v1/user/me/filters/${step}`)
    },

    fetchManufacturingFilters: (filter?: IFilterEditable) => {
      let queryParams = new URLSearchParams()
      if (filter) {
        queryParams = getOrderQueryParams(filter)
      }

      return apiClient
        .get<IListManufacturingFiltersResponse>(
          `/v1/manufacturing/filters?${queryParams.toString()}`
        )
        .then((resp) => resp.data.data)
        .then((filters) => {
          return filters.map((filter) => {
            return makeFilter(
              filter.name,
              filter.status.map((s) => s.id),
              [],
              filter.materials.map((m) => m.id),
              false
            )
          })
        })
    },

    createFilter: (step: IStep, filter: IFilter) => {
      return apiClient.put(`/v1/user/me/filters/${step}/${filter.id}`, filter)
    },

    deleteFilter: (step: IStep, filterId: string) => {
      return apiClient.delete(`/v1/user/me/filters/${step}/${filterId}`)
    },

    asignOrderToUserId: (orderNumber: string, userId: string) => {
      return apiClient.post<IAssignOrderResponse>(
        `${config.REACT_APP_API_URL}/v1/orders/${orderNumber}/assign`,
        { orderNumber, userId: userId === 'null' ? null : userId }
      )
    },

    setOrderStatus: (orderNumber: string, status: string) => {
      return apiClient.post<IChangeOrderStatusResponse>(
        `${config.REACT_APP_API_URL}/v1/orders/${orderNumber}/status`,
        { orderNumber, status }
      )
    },

    getOrderHistory: (orderNumber: string) => {
      return apiClient
        .get<IOrderHistoryListResponse>(
          `${config.REACT_APP_API_URL}/v1/orders/${orderNumber}/history`
        )
        .then((resp) => resp.data.data)
    },

    getOrderLineHistory: (historyUrl: string) => {
      return apiClient
        .get<IOrderLineHistoryListResponse>(
          `${config.REACT_APP_API_URL}${historyUrl}`
        )
        .then((resp) => resp.data.data)
    },

    getInternalMessages: (orderNumber: string) => {
      return apiClient
        .get<IInternalMessageListResponse>(
          `${config.REACT_APP_API_URL}/v1/orders/${orderNumber}/internal-message`
        )
        .then((resp) => resp.data)
    },

    createInternalMessage: (orderNumber: string, message: string) => {
      const req: IInternalMessageCreateRequest = { message }

      return apiClient
        .post<IInternalMessageCreateResponse>(
          `${config.REACT_APP_API_URL}/v1/orders/${orderNumber}/internal-message`,
          req
        )
        .then((resp) => resp.data)
    },

    fetchMaterials: () => {
      return apiClient.get<IMaterialListResponse>(
        `${config.REACT_APP_API_URL}/v1/material`
      )
    },

    fetchProducts: (storeId: string) => {
      return apiClient.get<IProductListResponse>(
        `${config.REACT_APP_API_URL}/v1/product?storeHash=${storeId}`
      )
    },

    saveProof: (formData: IProofCreateRequest) => {
      return apiClient.post<IProofCreateResponse>(
        `${config.REACT_APP_API_URL}/v1/proof`,
        IProofCreateRequest.toJSON(formData)
      )
    },

    sendProofs: (orderNumber: string) => {
      return apiClient.post<IProofCreateResponse>(
        `${config.REACT_APP_API_URL}/v1/orders/${orderNumber}/send-proofs`
      )
    },

    uploadCustomerArtwork: (formData: IUploadCustomerArtworkRequest) => {
      return apiClient.post<IUploadCustomerArtworkResponse>(
        `${config.REACT_APP_API_URL}/v1/order-line/${formData.orderLineId}/customer-artwork`,
        formData
      )
    },

    deleteOrderLineItem: (orderLineId: string) => {
      return apiClient.delete<void>(
        `${config.REACT_APP_API_URL}/v1/order-line/${orderLineId}`,
        { validateStatus: (status) => status === 204 }
      )
    },

    fetchOrder: (orderNumber: string) => {
      return apiClient.get<IOrderGetResponse>(
        `${config.REACT_APP_API_URL}/v1/orders/${orderNumber}`
      )
    },

    fetchAddress: (orderNumber: string, addressId: string) => {
      return apiClient.get<IOrderGetResponse>(
        `${config.REACT_APP_API_URL}/v1/orders/${orderNumber}/addresses/${addressId}`
      )
    },

    updateOrder: (updateRequest: IUpdateOrderRequest) => {
      return apiClient.put<IUpdateOrderResponse>(
        `${config.REACT_APP_API_URL}/v1/orders/${updateRequest.orderNumber}`,
        updateRequest
      )
    },

    fetchJob: (designCode: string) => {
      return apiClient.get<IGetJobResponse>(
        `${config.REACT_APP_API_URL}/v1/manufacturing/jobs/${designCode}`
      )
    },

    updateLineItem: (updateRequest: IUpdateOrderLineItemRequest) => {
      return apiClient.put<IUpdateOrderLineItemResponse>(
        `${config.REACT_APP_API_URL}/v1/order-line/${updateRequest.orderLineId}`,
        updateRequest
      )
    },

    addLineItem: (addRequest: IAddOrderLineItemRequest) => {
      return apiClient.post<IAddOrderLineItemResponse>(
        `${config.REACT_APP_API_URL}/v1/order-line`,
        addRequest
      )
    },

    setOrderLineStatus: (orderLineId: string, status: string) => {
      return apiClient.post<IChangeOrderLineItemStatusResponse>(
        `${config.REACT_APP_API_URL}/v1/order-line/${orderLineId}/status`,
        { orderLineId, status }
      )
    },

    getProof: (id: string) => {
      return apiClient
        .get<IProofGetResponse>(`${config.REACT_APP_API_URL}/v1/proof/${id}`)
        .then((resp) => IProof.fromJSON(resp.data.data))
    },

    getProofEstimates: (id: string) => {
      return apiClient
        .get<any>(`${config.REACT_APP_API_URL}/v1/proof/${id}/estimates`)
        .then((resp) => resp.data.data)
    },

    requestNewArtwork: (payload: IRequestNewArtworkRequest) => {
      return apiClient.post<IChangeOrderLineItemStatusResponse>(
        `${config.REACT_APP_API_URL}/v1/order-line/${payload.orderLineId}/request-new-artwork`,
        payload
      )
    },

    qaAcceptReject: (payload: IOrderLineQAApprovalRequest) => {
      return apiClient.post<IChangeOrderLineItemStatusResponse>(
        `${config.REACT_APP_API_URL}/v1/order-line/${payload.orderLineId}/quality-control`,
        payload
      )
    },

    rejectDesign: (payload: IRejectDesignRequest) => {
      return apiClient.post<IRejectDesignResponse>(
        `${config.REACT_APP_API_URL}/v1/order-line/${payload.orderLineId}/reject-design`,
        payload
      )
    },

    search: (searchTerm: string, includeLineItems?: boolean) => {
      const params = new URLSearchParams()
      params.set('q', searchTerm)

      if (includeLineItems) {
        params.set('includeLineItems', 'true')
      }

      return apiClient
        .get<ISearchResponse>(
          `${config.REACT_APP_API_URL}/v1/search?${params.toString()}`
        )
        .then((resp) => resp.data.data)
    },

    listThings: (thingType: string): Promise<IThing[]> => {
      const params = new URLSearchParams()
      params.set('type', thingType)

      return apiClient
        .get<IThingListResponse>(
          `${
            config.REACT_APP_API_URL
          }/v1/manufacturing/things?${params.toString()}`
        )
        .then((resp) => resp.data.data)
    },

    listLaminates: (): Promise<ILaminate[]> => {
      return Promise.resolve([
        {
          id: 'la-ckyo8lesu001e09mggtehd4rc',
          sku: '',
          name: 'No Laminate',
        },
        {
          id: 'la-ckw0jyfdu000009midy6thsl6',
          sku: 'gloss-thin',
          name: 'Thin Gloss',
        },
        {
          id: 'la-ckw0jyomq000109mi0lnuh6aq',
          sku: 'matte-thin',
          name: 'Thin Matte',
        },
        {
          id: 'la-ckw0jyrl2000209mi5jz26hj5',
          sku: 'gloss-thick',
          name: 'Thick Gloss',
        },
        {
          id: 'la-ckw0k8edm000909mif0foafe7',
          sku: 'matte-thick',
          name: 'Thick Matte',
        },
        {
          id: 'la-ckw0k5bl0000509micznu7gwt',
          sku: 'eco-friendly-clear',
          name: 'Eco-friendly',
        },
        { id: 'la-ckw0k637n000609mid04ogj63', sku: 'floor', name: 'Floor' },
        {
          id: 'la-ckw0k68go000709mib96w8u18',
          sku: 'gloss-premium-cast',
          name: 'Premium Cast Gloss',
        },
        {
          id: 'la-ckw0k6usd000809mibxa5dk32',
          sku: 'gloss-cast',
          name: 'Cast Gloss',
        },
      ])
    },

    listFinishes: (): Promise<IFinish[]> => {
      return Promise.resolve([
        {
          id: 'fi-ckw0k3tvw000309mig33c3fzb',
          sku: 'application-tape',
          name: 'Application Tape',
        },
        {
          id: 'fi-ckw0k3xg1000409midfpy3vzm',
          sku: 'application-hand',
          name: 'By Hand',
        },
      ])
    },

    sendToRIP: (
      pressId: string,
      items: ICreateBatchItem[],
      rolls: string[]
    ): Promise<ICreateBatchResponse> => {
      const payload: ICreateBatchRequest = {
        pressId,
        items,
        rolls,
        batchType: EBatchType.BatchTypeStandard,
      }

      return apiClient.post<ICreateBatchResponse>(
        `${config.REACT_APP_API_URL}/v1/manufacturing/batch`,
        ICreateBatchRequest.toJSON(payload)
      )
    },

    getBatchEstimate: (orderLineId: string) => {
      return apiClient
        .get<IBatchProductEstimateResponse>(
          `${config.REACT_APP_API_URL}/v1/order-line/${orderLineId}/estimate`
        )
        .then((resp) => {
          return IBatchProductEstimateResponse.fromJSON(resp.data)
        })
    },

    getBatchInfo: (id: string) => {
      return apiClient
        .get<IBatch | undefined>(
          `${config.REACT_APP_API_URL}/v1/manufacturing/batch/${id}`
        )
        .then((resp) => {
          return IBatchGetResponse.fromJSON(resp.data).data
        })
    },

    getCustomer: (customerId: string) => {
      return apiClient
        .get<IGetCustomerResponse>(
          `${config.REACT_APP_API_URL}/v1/customer/${customerId}`
        )
        .then((resp) => {
          return IGetCustomerResponse.fromJSON(resp.data).data
        })
    },

    listCustomers: (page: number, perPage: number, q: string) => {
      const params = new URLSearchParams()
      params.set('page', page.toString())
      params.set('perPage', perPage.toString())
      params.set('q', q)

      return apiClient
        .get<IListCustomerResponse>(
          `${config.REACT_APP_API_URL}/v1/customer?${params.toString()}`
        )
        .then((resp) => {
          return IListCustomerResponse.fromJSON(resp.data)
        })
    },

    createCustomer: (payload: ICreateCustomerRequest) => {
      return apiClient
        .post<ICreateCustomerResponse>(
          `${config.REACT_APP_API_URL}/v1/customer`,
          ICreateCustomerRequest.toJSON(payload)
        )
        .then((resp) => {
          return ICreateCustomerResponse.fromJSON(resp.data).data
        })
    },

    updateCustomer: (payload: IUpdateCustomerRequest) => {
      return apiClient
        .put<IUpdateCustomerResponse>(
          `${config.REACT_APP_API_URL}/v1/customer/${payload.id}`,
          IUpdateCustomerRequest.toJSON(payload)
        )
        .then((resp) => {
          return IUpdateCustomerResponse.fromJSON(resp.data).data
        })
    },

    getMyAccountLink: (
      customerId: string,
      orderId?: string
    ): Promise<string> => {
      const params = new URLSearchParams()
      if (orderId) {
        params.set('orderId', orderId)
      }
      return apiClient
        .get(
          `${
            config.REACT_APP_API_URL
          }/v1/customer/${customerId}/new-link?${params.toString()}`
        )
        .then((resp) => {
          return IMyAccountLinkResponse.fromJSON(resp.data).link
        })
    },

    listCustomerOrders: (
      cl: ApolloClient<NormalizedCacheObject>,
      customerId: string
    ) => {
      return cl
        .query<{ orders: Order[] }>({
          query: gql`
            query GetCustomerOrders($customerId: String!) {
              orders(
                perPage: 100
                page: 0
                opts: { customerId: [$customerId] }
              ) {
                id
                orderNumber
              }
            }
          `,
          variables: {
            customerId,
          },
        })
        .then((resp) => {
          return resp.data.orders
        })
    },

    allocateOrder(
      cl: ApolloClient<NormalizedCacheObject>,
      payload: AllocateOrderRequest
    ) {
      return cl
        .mutate<{ allocateOrder: Order }>({
          mutation: gql`
            mutation NewOrder($input: AllocateOrderRequest!) {
              allocateOrder(input: $input) {
                id
                orderNumber
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((resp) => {
          if (!resp) {
            console.error(resp)
            throw new Error('no response')
          }

          return resp?.data?.allocateOrder
        })
    },
  }
}

export type IAPIService = ReturnType<typeof service>

export const apiService: IAPIService = service()
