import React, { useState, useEffect, useMemo } from 'react'
import LabelIcon from '@mui/icons-material/Label'
import throttle from 'lodash/throttle'
import { apiService } from '../../service/api'
import { ISearchResponse_Result } from '@kartdavid/corkscrew-types/internal'
import { getNicenameFromStatus } from '../../utils/orders'
import { useHistory } from 'react-router-dom'
import {
  Autocomplete,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import useId from '@mui/material/utils/useId'

export interface ISearchBarProps {
  includeLineItems?: boolean
}

export default function SearchBar(props: ISearchBarProps) {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<ISearchResponse_Result[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedOpt] = useState<ISearchResponse_Result | null>(null)
  const [loading, setLoading] = useState(false)

  const fetch = useMemo(
    () =>
      throttle(
        (
          request: { searchTerm: string },
          callback: (results: ISearchResponse_Result[]) => void
        ) => {
          apiService
            .search(request.searchTerm, props.includeLineItems)
            .then((results) => callback(results))
            .catch((err) => console.log(err))
        },
        200
      ),
    []
  )

  useEffect(() => {
    if (searchTerm === '') {
      return
    }

    setLoading(true)
    fetch({ searchTerm }, (results) => {
      setOptions(results)
      setLoading(false)
    })
  }, [searchTerm])

  const id = useId()

  return (
    <Autocomplete
      id={id}
      sx={{
        width: 300,
        padding: 1,
        '& label, & input': {
          color: '#fff',
        },
        '& fieldset': {
          borderColor: '#6b7acb',
        },
        '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: '#b0b8e4 !important',
        },
      }}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      // getOptionSelected={(option, value) => option.name === value.name}
      // getOptionLabel={(option) => option.name}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.name
      }
      options={options}
      filterOptions={(x) => x}
      autoComplete
      includeInputInList
      filterSelectedOptions
      loading={loading}
      value={selectedOpt}
      onChange={(_, newValue) => {
        console.log('on change', newValue)
        switch (newValue?.objType) {
          case 'order': {
            history.push(`/proofs/${newValue.name}`)
            break
          }
          case 'order-line-item': {
            history.push(`/manufacturing/${newValue.name}`)
            break
          }
        }
      }}
      onInputChange={(_, newInputValue) => {
        console.log('newInputValu', newInputValue)
        setSearchTerm(newInputValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search"
          sx={{
            color: '#fff',
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <LabelIcon />
              </Grid>
              <Grid item xs>
                <span style={{ fontWeight: 400 }}>{option.name}</span>
                {option.statusName.length > 0 ? (
                  <Typography variant="body2" color="textSecondary">
                    {getNicenameFromStatus(option.statusName)}
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}
