import React, { FunctionComponent } from 'react'

import { Box, makeStyles, createStyles } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import FilterListIcon from '@mui/icons-material/FilterList'

import { IFilterEditable } from '../../types'

const useStyles = makeStyles(() =>
  createStyles({
    chip: {
      marginRight: 5,
      marginBottom: 3,
      marginTop: 3,
    },
  })
)

interface IProps {
  filters: IFilterEditable[]
  activeFilterIndex: number
  onClick: (index: number) => void
  onDelete: (filter: IFilterEditable, index: number) => void
}

export const SavedFilters: FunctionComponent<IProps> = ({
  filters,
  onClick,
  onDelete,
  activeFilterIndex,
}: IProps) => {
  const classes = useStyles()
  return (
    <Box mb={2} mt={1}>
      {filters.length === 0 ? (
        <Box>
          No filters found. To add one please click the filter icon (
          <FilterListIcon />) in the top right.
        </Box>
      ) : null}
      {filters.map((filter, index) => {
        const chipProps = {
          onDelete: filter.isEditable
            ? () => onDelete(filter, index)
            : undefined,
        }

        return (
          <Chip
            {...chipProps}
            className={classes.chip}
            key={index}
            label={filter.name}
            onClick={() => onClick(index)}
            color={activeFilterIndex === index ? 'primary' : 'default'}
          />
        )
      })}
    </Box>
  )
}
