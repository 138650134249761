import React, { FunctionComponent } from 'react'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'

interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  numeric: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'batch',
    numeric: false,
    disablePadding: false,
    label: 'Batch',
  },
  {
    id: 'designCode',
    numeric: false,
    disablePadding: false,
    label: 'Design Code',
  },
  {
    id: 'materialSku',
    numeric: false,
    disablePadding: false,
    label: 'Material',
  },
  {
    id: 'laminate',
    numeric: false,
    disablePadding: false,
    label: 'Laminate',
  },
  {
    id: 'shipDate',
    numeric: false,
    disablePadding: false,
    label: 'Ship Date',
  },
  {
    id: 'printTime',
    numeric: false,
    disablePadding: false,
    label: 'Press Time',
  },
  {
    id: 'cutTime',
    numeric: false,
    disablePadding: false,
    label: 'Cut Time',
  },
  {
    id: 'materialUsed',
    numeric: false,
    disablePadding: false,
    label: 'Material (m)',
  },
  {
    id: 'product',
    numeric: false,
    disablePadding: false,
    label: 'Product',
  },
]

export interface EnhancedTableProps {
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowCount: number
  headCells?: HeadCell[]
}

const EnhancedTableHead: FunctionComponent<EnhancedTableProps> = (
  props: EnhancedTableProps
) => {
  const { onSelectAllClick, numSelected, rowCount } = props

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {(props.headCells || headCells).map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}

            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default EnhancedTableHead
